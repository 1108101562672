let airports = [
  { code: "HIR", name: "Honiara International Airport" },
  { code: "MUA", name: "Munda Airport" },
  { code: "KGE", name: "Kaghau Airport" },
  { code: "INU", name: "Nauru International Airport" },
  { code: "QET", name: "Troll Airfield" },
  { code: "BUA", name: "Buka Airport" },
  { code: "DAU", name: "Daru Airport" },
  { code: "GKA", name: "Goroka Airport" },
  { code: "GUR", name: "Gurney Airport" },
  { code: "PNP", name: "Popondetta Girua Airport" },
  { code: "HKN", name: "Hoskins" },
  { code: "KMA", name: "Kerema Airport" },
  { code: "KVG", name: "Kavieng Airport" },
  { code: "LNV", name: "Lihir Island Kunaye Airport" },
  { code: "MAG", name: "Madang Airport" },
  { code: "HGU", name: "Mount Hagen Airport" },
  { code: "MAS", name: "Lorengau Momote Airport" },
  { code: "MXH", name: "Moro Airport" },
  { code: "LAE", name: "Lae Nadzab Airport" },
  { code: "KCJ", name: "Komaio Airport" },
  { code: "POM", name: "Port Moresby Jacksons International Airport" },
  { code: "KMR", name: "Karimui Airport" },
  { code: "RAB", name: "Rabaul Airport" },
  { code: "VAI", name: "Vanimo Airport" },
  { code: "WWK", name: "Wewak Airport" },
  { code: "KOM", name: "Komo Airport" },
  { code: "JEG", name: "Aasiaat Airport" },
  { code: "UAK", name: "Narsarsuaq Airport" },
  { code: "CNP", name: "Nerlerit Inaat Airport" },
  { code: "GOH", name: "Nuuk Airport" },
  { code: "JAV", name: "Ilulissat Airport" },
  { code: "KUS", name: "Kulusuk Airport" },
  { code: "JSU", name: "Maniitsoq Airport" },
  { code: "JFR", name: "Paamiut Airport" },
  { code: "NAQ", name: "Qaanaaq Airport" },
  { code: "SFJ", name: "Kangerlussuaq Airport" },
  { code: "JHS", name: "Sisimiut Airport" },
  { code: "THU", name: "Pituffik Airport" },
  { code: "JUV", name: "Upernavik Airport" },
  { code: "JQA", name: "Qaarsut Airport" },
  { code: "AEY", name: "Akureyri Airport" },
  { code: "BIU", name: "Bildudalur Airport" },
  { code: "EGS", name: "Egilsstadir Airport" },
  { code: "GRY", name: "Grimsey Airport" },
  { code: "HFN", name: "Hofn Hornafjordur Airport" },
  { code: "HZK", name: "Husavik Airport" },
  { code: "IFJ", name: "Isafjordur Airport" },
  { code: "KEF", name: "Keflavik International Airport" },
  { code: "NOR", name: "Nordfjordur Airport" },
  { code: "RKV", name: "Reykjavik Airport" },
  { code: "THO", name: "Thorshofn Airport" },
  { code: "VEY", name: "Vestmannaeyjar Airport" },
  { code: "VPN", name: "Vopnafjordur Airport" },
  { code: "PRN", name: "Pristina Adem Jashari International Airport" },
  { code: "ZNA", name: "Nanaimo Harbour Water Airport" },
  { code: "YZZ", name: "Trail Airport" },
  { code: "JHL", name: "Fort MacKay Albian Aerodrome" },
  { code: "XQU", name: "Qualicum Beach Airport" },
  { code: "YWM", name: "Williams Harbour Airport" },
  { code: "YSO", name: "Postville Airport" },
  { code: "YBI", name: "Black Tickle Airport" },
  { code: "YHG", name: "Charlottetown Airport" },
  { code: "YHA", name: "Port Hope Simpson Airport" },
  { code: "YRG", name: "Rigolet Airport" },
  { code: "SUR", name: "Summer Beaver Airport" },
  { code: "YAX", name: "Wapekeka Angling Lake Airport" },
  { code: "WNN", name: "Wunnummin Lake Airport" },
  { code: "YNO", name: "North Spirit Lake Airport" },
  { code: "XBE", name: "Bearskin Lake Airport" },
  { code: "YNP", name: "Natuashish Airport" },
  { code: "KIF", name: "Kingfisher Lake Airport" },
  { code: "KEW", name: "Keewaywin Airport" },
  { code: "YKU", name: "Chisasibi Airport" },
  { code: "YAU", name: "Kattiniq Donaldson Airport" },
  { code: "YAB", name: "Arctic Bay Airport" },
  { code: "YAG", name: "Fort Frances Municipal Airport" },
  { code: "YAH", name: "La Grande-4 Airport" },
  { code: "YAM", name: "Sault Ste. Marie Airport" },
  { code: "XKS", name: "Kasabonika Airport" },
  { code: "YKG", name: "Kangirsuk Airport" },
  { code: "YAT", name: "Attawapiskat Airport" },
  { code: "YAY", name: "St. Anthony Airport" },
  { code: "YAZ", name: "Tofino Long Beach Airport" },
  { code: "YBB", name: "Kugaaruk Airport" },
  { code: "YBC", name: "Baie Comeau Airport" },
  { code: "QBC", name: "Bella Coola Airport" },
  { code: "YBE", name: "Uranium City Airport" },
  { code: "YBY", name: "Bonnyville Airport" },
  { code: "YBG", name: "Saguenay Bagotville Airport" },
  { code: "YBK", name: "Baker Lake Airport" },
  { code: "YBL", name: "Campbell River Airport" },
  { code: "XTL", name: "Tadoule Lake Airport" },
  { code: "YBR", name: "Brandon Municipal Airport" },
  { code: "YBT", name: "Brochet Airport" },
  { code: "YBV", name: "Berens River Airport" },
  { code: "YBX", name: "Lourdes-de-Blanc-Sablon Airport" },
  { code: "YRF", name: "Cartwright Airport" },
  { code: "YCB", name: "Cambridge Bay Airport" },
  { code: "YCD", name: "Nanaimo Airport" },
  { code: "YCG", name: "Castlegar West Kootenay Regional Airport" },
  { code: "YCH", name: "Miramichi Airport" },
  { code: "YCL", name: "Charlo Airport" },
  { code: "YCO", name: "Kugluktuk Airport" },
  { code: "YCR", name: "Cross Lake Airport" },
  { code: "YCY", name: "Clyde River Airport" },
  { code: "YDF", name: "Deer Lake Regional Airport" },
  { code: "YDL", name: "Dease Lake Airport" },
  { code: "YDP", name: "Nain Airport" },
  { code: "YDQ", name: "Dawson Creek Airport" },
  { code: "YEG", name: "Edmonton International Airport" },
  { code: "YEK", name: "Arviat Airport" },
  { code: "YER", name: "Fort Severn Airport" },
  { code: "YEU", name: "Eureka Airport" },
  { code: "YEV", name: "Inuvik Airport" },
  { code: "YFA", name: "Fort Albany Airport" },
  { code: "YFB", name: "Iqaluit Airport" },
  { code: "YFC", name: "Fredericton International Airport" },
  { code: "YFH", name: "Fort Hope Airport" },
  { code: "YFI", name: "Fort MacKay Firebag Aerodrome" },
  { code: "YTM", name: "Mont Tremblant International Airport" },
  { code: "YFO", name: "Flin Flon Airport" },
  { code: "YMN", name: "Makkovik Airport" },
  { code: "YGB", name: "Gillies Bay Airport" },
  { code: "YGH", name: "Fort Good Hope Airport" },
  { code: "YGK", name: "Kingston Norman Rogers Airport" },
  { code: "YGL", name: "La Grande Riviere Airport" },
  { code: "YGO", name: "Gods Lake Narrows Airport" },
  { code: "YGP", name: "Gaspe Airport" },
  { code: "YGR", name: "Iles-de-la-Madeleine Airport" },
  { code: "YGT", name: "Igloolik Airport" },
  { code: "YGV", name: "Havre-Saint-Pierre Airport" },
  { code: "YGW", name: "Kuujjuarapik Airport" },
  { code: "YGX", name: "Gillam Airport" },
  { code: "CXH", name: "Vancouver Harbour Water Airport" },
  { code: "YHD", name: "Dryden Regional Airport" },
  { code: "YNS", name: "Nemiscau Airport" },
  { code: "YHK", name: "Gjoa Haven Airport" },
  { code: "YHM", name: "Hamilton John C. Munro International Airport" },
  { code: "YHO", name: "Hopedale Airport" },
  { code: "YHP", name: "Poplar Hill Airport" },
  { code: "YHR", name: "Chevery Airport" },
  { code: "YHU", name: "Montreal Saint-Hubert Airport" },
  { code: "YHY", name: "Hay River Merlyn Carter Airport" },
  { code: "YHZ", name: "Halifax Stanfield International Airport" },
  { code: "YIF", name: "Saint-Augustin Airport" },
  { code: "YIK", name: "Ivujivik Airport" },
  { code: "YIO", name: "Pond Inlet Airport" },
  { code: "YIV", name: "Island Lake Airport" },
  { code: "YJT", name: "Stephenville International Airport" },
  { code: "YKA", name: "Kamloops Airport" },
  { code: "YKC", name: "Collins Bay Airport" },
  { code: "YKF", name: "Waterloo International Airport" },
  { code: "YWB", name: "Kangiqsujuaq Wakeham Bay Airport" },
  { code: "YKJ", name: "Key Lake Airport" },
  { code: "YKL", name: "Schefferville Airport" },
  { code: "YKQ", name: "Waskaganish Airport" },
  { code: "YKZ", name: "Toronto Buttonville Municipal Airport" },
  { code: "YPJ", name: "Aupaluk Airport" },
  { code: "YLB", name: "Lac La Biche Airport" },
  { code: "YLH", name: "Lansdowne House Airport" },
  { code: "YLL", name: "Lloydminster Airport" },
  { code: "XGR", name: "Kangiqsualujjuaq Georges River Airport" },
  { code: "YLW", name: "Kelowna International Airport" },
  { code: "YMH", name: "Mary's Harbour Airport" },
  { code: "YMM", name: "Fort McMurray Airport" },
  { code: "YMO", name: "Moosonee Airport" },
  { code: "YMT", name: "Chibougamau Chapais Airport" },
  { code: "YUD", name: "Umiujaq Airport" },
  { code: "YMX", name: "Montreal Mirabel International Airport" },
  { code: "YND", name: "Gatineau Ottawa Executive Airport" },
  { code: "YNE", name: "Norway House Airport" },
  { code: "YLY", name: "Langley Regional Airport" },
  { code: "YNL", name: "Points North Landing Airport" },
  { code: "HZP", name: "Fort MacKay Horizon Airport" },
  { code: "YOA", name: "Ekati Airport" },
  { code: "YOH", name: "Oxford House Airport" },
  { code: "YOJ", name: "High Level Airport" },
  { code: "YOO", name: "Oshawa Executive Airport" },
  { code: "YOW", name: "Ottawa Macdonald-Cartier International Airport" },
  { code: "YPA", name: "Prince Albert Glass Field Airport" },
  { code: "YPS", name: "Port Hawkesbury Airport" },
  { code: "YPE", name: "Peace River Airport" },
  { code: "YPH", name: "Inukjuak Airport" },
  { code: "YPL", name: "Pickle Lake Airport" },
  { code: "YPM", name: "Pikangikum Airport" },
  { code: "YPN", name: "Port-Menier Airport" },
  { code: "YPQ", name: "Peterborough Airport" },
  { code: "YPR", name: "Prince Rupert Airport" },
  { code: "YPW", name: "Powell River Airport" },
  { code: "YPX", name: "Puvirnituq Airport" },
  { code: "YPY", name: "Fort Chipewyan Airport" },
  { code: "YQA", name: "Muskoka Airport" },
  { code: "YQB", name: "Quebec City International Airport" },
  { code: "YQD", name: "The Pas Airport" },
  { code: "YQF", name: "Red Deer Regional Airport" },
  { code: "YQG", name: "Windsor International Airport" },
  { code: "YQI", name: "Yarmouth Airport" },
  { code: "YQK", name: "Kenora Airport" },
  { code: "YQL", name: "Lethbridge Airport" },
  { code: "YQM", name: "Moncton International Airport" },
  { code: "YQQ", name: "Comox Valley Airport" },
  { code: "YQR", name: "Regina International Airport" },
  { code: "YQT", name: "Thunder Bay International Airport" },
  { code: "YQU", name: "Grande Prairie Airport" },
  { code: "YQX", name: "Gander International Airport" },
  { code: "YQY", name: "Sydney J.A. Douglas McCurdy Airport" },
  { code: "YQZ", name: "Quesnel Airport" },
  { code: "YRB", name: "Resolute Bay Airport" },
  { code: "YRL", name: "Red Lake Airport" },
  { code: "YRQ", name: "Trois-Rivieres Airport" },
  { code: "YRS", name: "Red Sucker Lake Airport" },
  { code: "YRT", name: "Rankin Inlet Airport" },
  { code: "YRV", name: "Revelstoke Airport" },
  { code: "YSB", name: "Sudbury Airport" },
  { code: "YSC", name: "Sherbrooke" },
  { code: "YSF", name: "Stony Rapids Airport" },
  { code: "YSJ", name: "Saint John Airport" },
  { code: "YSK", name: "Sanikiluaq Airport" },
  { code: "YSM", name: "Fort Smith Airport" },
  { code: "YCM", name: "St. Catharines Niagara District Airport" },
  { code: "YST", name: "St. Theresa Point Airport" },
  { code: "YTE", name: "Cape Dorset Airport" },
  { code: "YTH", name: "Thompson Airport" },
  { code: "YTL", name: "Big Trout Lake Airport" },
  { code: "YTQ", name: "Tasiujaq Airport" },
  { code: "YTS", name: "Timmins Airport" },
  { code: "YTZ", name: "Toronto City Billy Bishop Airport" },
  { code: "YUL", name: "Montreal Pierre Elliott Trudeau Airport" },
  { code: "YUT", name: "Naujaat Airport" },
  { code: "YUX", name: "Hall Beach Airport" },
  { code: "YUY", name: "Rouyn Noranda Airport" },
  { code: "YVB", name: "Bonaventure Airport" },
  { code: "YVC", name: "La Ronge Barber Field Airport" },
  { code: "YVM", name: "Qikiqtarjuaq Airport" },
  { code: "YVO", name: "Val-d'Or Airport" },
  { code: "YVP", name: "Kuujjuaq Airport" },
  { code: "YVQ", name: "Norman Wells Airport" },
  { code: "YVR", name: "Vancouver International Airport" },
  { code: "YVT", name: "Buffalo Narrows Airport" },
  { code: "YVZ", name: "Deer Lake Airport" },
  { code: "YWG", name: "Winnipeg International Airport" },
  { code: "YWH", name: "Victoria Inner Harbour Airport" },
  { code: "YWK", name: "Wabush Airport" },
  { code: "YWL", name: "Williams Lake Airport" },
  { code: "YWP", name: "Webequie Airport" },
  { code: "YXC", name: "Cranbrook Canadian Rockies International Airport" },
  { code: "YXE", name: "Saskatoon John G. Diefenbaker International Airport" },
  { code: "YXH", name: "Medicine Hat Airport" },
  { code: "YXJ", name: "Fort St. John Airport" },
  { code: "YXL", name: "Sioux Lookout Airport" },
  { code: "YXP", name: "Pangnirtung Airport" },
  { code: "YXS", name: "Prince George Airport" },
  { code: "YXT", name: "Terrace Northwest Regional Airport" },
  { code: "YXU", name: "London International Airport" },
  { code: "YXX", name: "Abbotsford International Airport" },
  { code: "YXY", name: "Whitehorse International Airport" },
  { code: "YYB", name: "North Bay Jack Garland Airport" },
  { code: "YYC", name: "Calgary International Airport" },
  { code: "YYD", name: "Smithers Airport" },
  { code: "YYE", name: "Fort Nelson Airport" },
  { code: "YYF", name: "Penticton Regional Airport" },
  { code: "YYG", name: "Charlottetown Airport" },
  { code: "YYH", name: "Taloyoak Airport" },
  { code: "YYJ", name: "Victoria International Airport" },
  { code: "YYL", name: "Lynn Lake Airport" },
  { code: "YYQ", name: "Churchill Airport" },
  { code: "YYR", name: "Goose Bay Airport" },
  { code: "YYT", name: "St. John's International Airport" },
  { code: "YYU", name: "Kapuskasing Airport" },
  { code: "YYY", name: "Mont-Joli Airport" },
  { code: "YYZ", name: "Toronto Pearson International Airport" },
  { code: "YZF", name: "Yellowknife Airport" },
  { code: "YZG", name: "Salluit Airport" },
  { code: "YZH", name: "Slave Lake Airport" },
  { code: "YZP", name: "Sandspit Airport" },
  { code: "YZR", name: "Sarnia Chris Hadfield Airport" },
  { code: "YZS", name: "Coral Harbour Airport" },
  { code: "YZT", name: "Port Hardy Airport" },
  { code: "YZU", name: "Whitecourt Airport" },
  { code: "YZV", name: "Sept-Iles Airport" },
  { code: "YDT", name: "Vancouver Boundary Bay Airport" },
  { code: "ZBF", name: "Bathurst Airport" },
  { code: "ZBM", name: "Bromont Airport" },
  { code: "ZFD", name: "Fond Du Lac Airport" },
  { code: "ZGI", name: "Gods River Airport" },
  { code: "ZKE", name: "Kashechewan Airport" },
  { code: "MSA", name: "Muskrat Dam Airport" },
  { code: "ZMT", name: "Masset Airport" },
  { code: "ZPB", name: "Sachigo Lake Airport" },
  { code: "ZRJ", name: "Round Lake Airport" },
  { code: "ZSJ", name: "Sandy Lake Airport" },
  { code: "ZTM", name: "Shamattawa Airport" },
  { code: "ZUM", name: "Churchill Falls Airport" },
  { code: "XLB", name: "Lac Brochet Airport" },
  { code: "ZWL", name: "Wollaston Lake Airport" },
  { code: "BJA", name: "Bejaia Soummam Airport" },
  { code: "ALG", name: "Algiers Houari Boumediene Airport" },
  { code: "DJG", name: "Djanet Inedbirene Airport" },
  { code: "VVZ", name: "Illizi Takhamalt Airport" },
  { code: "QSF", name: "Setif Ain Arnat Airport" },
  { code: "TMR", name: "Tamanrasset Aguenar Airport" },
  { code: "GJL", name: "Jijel Ferhat Abbas Airport" },
  { code: "AAE", name: "Annaba Rabah Bitat Airport" },
  { code: "CZL", name: "Constantine Mohamed Boudiaf International Airport" },
  { code: "BLJ", name: "Batna International Airport" },
  { code: "TIN", name: "Tindouf Airport" },
  { code: "CFK", name: "Chlef International Airport" },
  { code: "TLM", name: "Tlemcen Zenata Airport" },
  { code: "ORN", name: "Oran Es Senia Airport" },
  { code: "AZR", name: "Adrar Airport" },
  { code: "BSK", name: "Biskra Airport" },
  { code: "GHA", name: "Ghardaia Noumerate Airport" },
  { code: "HME", name: "Hassi Messaoud Oued Irara Airport" },
  { code: "LOO", name: "Laghouat Airport" },
  { code: "ELU", name: "El Oued Guemar Airport" },
  { code: "OGX", name: "Ouargla Ain Beida Airport" },
  { code: "COO", name: "Cotonou Cadjehoun Airport" },
  { code: "OUA", name: "Ouagadougou Airport" },
  { code: "ACC", name: "Accra Kotoka International Airport" },
  { code: "TML", name: "Tamale Airport" },
  { code: "TKD", name: "Takoradi Airport" },
  { code: "ABJ", name: "Abidjan Port Bouet Airport" },
  { code: "BYK", name: "Bouake Airport" },
  { code: "HGO", name: "Korhogo Airport" },
  { code: "SPY", name: "San Pedro Airport" },
  { code: "ABV", name: "Abuja Nnamdi Azikiwe International Airport" },
  { code: "QUO", name: "Uyo Akwa Ibom Airport" },
  { code: "ABB", name: "Asaba International Airport" },
  { code: "BNI", name: "Benin Airport" },
  { code: "CBQ", name: "Calabar Margaret Ekpo International Airport" },
  { code: "ENU", name: "Enugu Akanu Ibiam International Airport" },
  { code: "IBA", name: "Ibadan Airport" },
  { code: "QOW", name: "Owerri Sam Mbakwe Airport" },
  { code: "JOS", name: "Jos Yakubu Gowon Airport" },
  { code: "KAD", name: "Kaduna Airport" },
  { code: "KAN", name: "Kano Mallam Aminu International Airport" },
  { code: "LOS", name: "Lagos Murtala Mohammed Airport" },
  { code: "PHC", name: "Port Harcourt International Airport" },
  { code: "QRW", name: "Warri Osubi Airport" },
  { code: "YOL", name: "Yola Airport" },
  { code: "NIM", name: "Niamey Diori Hamani International Airport" },
  { code: "KMS", name: "Kumasi Airport" },
  { code: "TBJ", name: "Tabarka Ain Draham Airport" },
  { code: "MIR", name: "Monastir Habib Bourguiba International Airport" },
  { code: "NBE", name: "Enfidha – Hammamet International Airport" },
  { code: "TUN", name: "Tunis Carthage International Airport" },
  { code: "GAE", name: "Gabes Matmata International Airport" },
  { code: "DJE", name: "Djerba Zarzis International Airport" },
  { code: "SFA", name: "Sfax–Thyna International Airport" },
  { code: "TOE", name: "Tozeur Nefta International Airport" },
  { code: "LFW", name: "Lome Tokoin Airport" },
  { code: "ANR", name: "Antwerp International Airport" },
  { code: "BRU", name: "Brussels Airport" },
  { code: "CRL", name: "Brussels South Charleroi Airport" },
  { code: "KJK", name: "Kortrijk Wevelgem International Airport" },
  { code: "LGG", name: "Liege Airport" },
  { code: "OST", name: "Ostend Bruges International Airport" },
  { code: "AOC", name: "Leipzig Altenburg Airport" },
  { code: "HDF", name: "Heringsdorf Airport" },
  { code: "CSO", name: "Magdeburg Cochstedt Airport" },
  { code: "GTI", name: "Rugen Airport" },
  { code: "SXF", name: "Berlin Schonefeld Airport" },
  { code: "DRS", name: "Dresden Airport" },
  { code: "ERF", name: "Erfurt Weimar Airport" },
  { code: "FRA", name: "Frankfurt Airport" },
  { code: "FMO", name: "Munster Osnabruck International  Airport" },
  { code: "HAM", name: "Hamburg Airport" },
  { code: "CGN", name: "Cologne Bonn Airport" },
  { code: "DUS", name: "Dusseldorf International Airport" },
  { code: "MUC", name: "Munich Airport" },
  { code: "NUE", name: "Nuremberg Airport" },
  { code: "LEJ", name: "Leipzig Halle Airport" },
  { code: "SCN", name: "Saarbrucken Airport" },
  { code: "STR", name: "Stuttgart Airport" },
  { code: "TXL", name: "Berlin Tegel Airport" },
  { code: "HAJ", name: "Hannover Langenhagen Airport" },
  { code: "BRE", name: "Bremen Airport" },
  { code: "QEF", name: "Frankfurt Egelsbach Airport" },
  { code: "HHN", name: "Frankfurt Hahn Airport" },
  { code: "MHG", name: "Mannheim City Airport" },
  { code: "SGE", name: "Siegen Siegerland Airport" },
  { code: "XFW", name: "Hamburg Finkenwerder Airport" },
  { code: "KEL", name: "Kiel Airport" },
  { code: "LBC", name: "Lubeck Airport" },
  { code: "EUM", name: "Neumunster Airport" },
  { code: "FMM", name: "Memmingen Airport" },
  { code: "AAH", name: "Aachen Merzbruck Airport" },
  { code: "ESS", name: "Essen Mulheim Airport" },
  { code: "BFE", name: "Bielefeld Airport" },
  { code: "MGL", name: "Dusseldorf Monchengladbach Airport" },
  { code: "PAD", name: "Paderborn Lippstadt Airport" },
  { code: "NRN", name: "Weeze Airport" },
  { code: "DTM", name: "Dortmund Airport" },
  { code: "AGB", name: "Augsburg Airport" },
  { code: "OBF", name: "Oberpfaffenhofen Airport" },
  { code: "RBM", name: "Straubing Wallmuehle Airport" },
  { code: "FDH", name: "Friedrichshafen Airport" },
  { code: "SZW", name: "Schwerin Parchim International Airport" },
  { code: "GHF", name: "Giebelstadt Airport" },
  { code: "HOQ", name: "Hof Plauen Airport" },
  { code: "ZQW", name: "Zweibrucken Airport" },
  { code: "FKB", name: "Karlsruhe/Baden-Baden Airport" },
  { code: "LHA", name: "Lahr Black Forest Airport" },
  { code: "BWE", name: "Braunschweig Airport" },
  { code: "KSF", name: "Kassel Calden Airport" },
  { code: "ZNO", name: "Hildesheim Airport" },
  { code: "EME", name: "Emden Airport" },
  { code: "AGE", name: "Wangerooge Airport" },
  { code: "WVN", name: "Wilhelmshaven JadeWeser Airport" },
  { code: "JUI", name: "Juist Airport" },
  { code: "LGO", name: "Langeoog Airport" },
  { code: "BMK", name: "Borkum Airport" },
  { code: "NOD", name: "Norden Norddeich Airport" },
  { code: "VAC", name: "Varrelbusch Airport" },
  { code: "NRD", name: "Norderney Airport" },
  { code: "BMR", name: "Baltrum Airport" },
  { code: "HEI", name: "Heide Buesum Airport" },
  { code: "FLF", name: "Flensburg Schaferhaus Airport" },
  { code: "HGL", name: "Heligoland Airport" },
  { code: "QHU", name: "Husum Schwesing Airport" },
  { code: "NDZ", name: "Cuxhaven Nordholz Sea Airport" },
  { code: "PSH", name: "Sankt Peter-Ording Airport" },
  { code: "GWT", name: "Sylt Airport" },
  { code: "KDL", name: "Kardla Airport" },
  { code: "URE", name: "Kuressaare Airport" },
  { code: "EPU", name: "Parnu Airport" },
  { code: "TLL", name: "Tallinn Lennart Meri Airport " },
  { code: "TAY", name: "Tartu Airport" },
  { code: "ENF", name: "Enontekio Airport" },
  { code: "HEM", name: "Helsinki Malmi Airport" },
  { code: "HEL", name: "Helsinki Vantaa Airport" },
  { code: "HYV", name: "Hyvinkaa Airport" },
  { code: "IVL", name: "Ivalo Airport" },
  { code: "JOE", name: "Joensuu Airport" },
  { code: "JYV", name: "Jyvaskyla Airport" },
  { code: "KEM", name: "Kemi Tornio Airport" },
  { code: "KAJ", name: "Kajaani Airport" },
  { code: "KOK", name: "Kokkola-Pietarsaari Airport" },
  { code: "KAO", name: "Kuusamo Airport" },
  { code: "KTT", name: "Kittila Airport" },
  { code: "KUO", name: "Kuopio Airport" },
  { code: "LPP", name: "Lappeenranta Airport" },
  { code: "MHQ", name: "Mariehamn Airport" },
  { code: "OUL", name: "Oulu Airport" },
  { code: "POR", name: "Pori Airport" },
  { code: "RVN", name: "Rovaniemi Airport" },
  { code: "SVL", name: "Savonlinna Airport" },
  { code: "SJY", name: "Seinajoki Airport" },
  { code: "TMP", name: "Tampere Pirkkala Airport" },
  { code: "TKU", name: "Turku Airport" },
  { code: "VAA", name: "Vaasa Airport" },
  { code: "BFS", name: "Belfast International Airport" },
  { code: "BHD", name: "Belfast City George Best  Airport" },
  { code: "LDY", name: "City of Derry Airport" },
  { code: "BHX", name: "Birmingham Airport" },
  { code: "CVT", name: "Coventry Airport" },
  { code: "GLO", name: "Gloucester Gloucestershire Airport" },
  { code: "ORM", name: "Northampton Sywell Airport" },
  { code: "NQT", name: "Nottingham Airport" },
  { code: "GBA", name: "Kemble Cotswold Airport" },
  { code: "MAN", name: "Manchester Airport" },
  { code: "DSA", name: "Doncaster Sheffield Airport" },
  { code: "CAL", name: "Campbeltown Airport" },
  { code: "EOI", name: "Eday Airport" },
  { code: "FIE", name: "Fair Isle Airport" },
  { code: "NRL", name: "North Ronaldsay Airport" },
  { code: "OBN", name: "North Connel Oban Airport" },
  { code: "PPW", name: "Papa Westray Airport" },
  { code: "SOY", name: "Stronsay Airport" },
  { code: "NDY", name: "Sanday Airport" },
  { code: "LWK", name: "Lerwick Tingwall Airport" },
  { code: "WRY", name: "Westray Airport" },
  { code: "CWL", name: "Cardiff Airport" },
  { code: "SWS", name: "Swansea Airport" },
  { code: "BRS", name: "Bristol Airport" },
  { code: "LPL", name: "Liverpool John Lennon Airport" },
  { code: "LTN", name: "London Luton Airport" },
  { code: "LEQ", name: "Lands End Airport" },
  { code: "ISC", name: "Isles of Scilly St Mary's Airport" },
  { code: "BOH", name: "Bournemouth Airport" },
  { code: "SOU", name: "Southampton Airport" },
  { code: "QLA", name: "Lasham Airport" },
  { code: "NQY", name: "Newquay Cornwall Airport" },
  { code: "QUG", name: "Chichester Goodwood Airport" },
  { code: "ACI", name: "Alderney Airport" },
  { code: "GCI", name: "Guernsey Airport" },
  { code: "JER", name: "Jersey Airport" },
  { code: "ESH", name: "Brighton City Shoreham Airport" },
  { code: "BQH", name: "London Biggin Hill Airport" },
  { code: "LGW", name: "London Gatwick Airport" },
  { code: "LCY", name: "London City Airport" },
  { code: "FAB", name: "Farnborough Airport" },
  { code: "BBS", name: "Blackbushe Airport" },
  { code: "LHR", name: "London Heathrow Airport" },
  { code: "SEN", name: "London Southend Airport" },
  { code: "LYX", name: "Lydd Ashford Airport" },
  { code: "CAX", name: "Carlisle Lake District Airport" },
  { code: "BLK", name: "Blackpool International Airport" },
  { code: "HUY", name: "Humberside Airport" },
  { code: "BWF", name: "Barrow-in-Furness Walney Island Airport" },
  { code: "LBA", name: "Leeds Bradford Airport" },
  { code: "WRT", name: "Warton Airport" },
  { code: "CEG", name: "Chester Hawarden Airport" },
  { code: "IOM", name: "Isle of Man Airport" },
  { code: "NCL", name: "Newcastle Airport" },
  { code: "MME", name: "Darlington Durham Tees Valley Airport" },
  { code: "EMA", name: "East Midlands Airport" },
  { code: "VLY", name: "Anglesey Airport" },
  { code: "KOI", name: "Kirkwall Airport" },
  { code: "LSI", name: "Sumburgh Airport" },
  { code: "WIC", name: "Wick Airport" },
  { code: "ABZ", name: "Aberdeen International Airport" },
  { code: "INV", name: "Inverness Airport" },
  { code: "GLA", name: "Glasgow International Airport" },
  { code: "EDI", name: "Edinburgh Airport" },
  { code: "ILY", name: "Islay Airport" },
  { code: "PIK", name: "Glasgow Prestwick Airport" },
  { code: "BEB", name: "Benbecula Airport" },
  { code: "SCS", name: "Scatsta Airport" },
  { code: "DND", name: "Dundee Airport" },
  { code: "SYY", name: "Stornoway Airport" },
  { code: "BRR", name: "Barra Airport" },
  { code: "PSL", name: "Perth Scone Airport" },
  { code: "TRE", name: "Tiree Airport" },
  { code: "CBG", name: "Cambridge Airport" },
  { code: "NWI", name: "Norwich International Airport" },
  { code: "STN", name: "London Stansted Airport" },
  { code: "DGX", name: "St. Athan Airport" },
  { code: "HYC", name: "High Wycombe Booker Airfield" },
  { code: "EXT", name: "Exeter Airport" },
  { code: "OXF", name: "London Oxford Airport" },
  { code: "RCS", name: "Rochester Airport" },
  { code: "BZZ", name: "RAF Brize Norton" },
  { code: "MPN", name: "Mount Pleasant Airport" },
  { code: "AMS", name: "Amsterdam Schiphol Airport" },
  { code: "MST", name: "Maastricht Aachen Airport" },
  { code: "EIN", name: "Eindhoven Airport" },
  { code: "GRQ", name: "Groningen Airport Eelde" },
  { code: "DHR", name: "Den Helder Airport" },
  { code: "LEY", name: "Lelystad Airport" },
  { code: "RTM", name: "Rotterdam The Hague Airport" },
  { code: "ENS", name: "Enschede Twenthe Airport" },
  { code: "WOE", name: "Bergen op Zoom Woensdrecht Air Base Airport" },
  { code: "ORK", name: "Cork Airport" },
  { code: "CFN", name: "Donegal Airport" },
  { code: "DUB", name: "Dublin Airport" },
  { code: "NOC", name: "Knock Ireland West Airport" },
  { code: "KIR", name: "Kerry Airport" },
  { code: "SNN", name: "Shannon Airport" },
  { code: "SXL", name: "Sligo Airport" },
  { code: "WAT", name: "Waterford Airport" },
  { code: "QEA", name: "Aarhus Sea Airport" },
  { code: "AAR", name: "Aarhus Airport" },
  { code: "BLL", name: "Billund Airport" },
  { code: "QEC", name: "Copenhagen Sea Airport" },
  { code: "CPH", name: "Copenhagen Airport" },
  { code: "EBJ", name: "Esbjerg Airport" },
  { code: "KRP", name: "Karup Midtjyllands Airport" },
  { code: "MRW", name: "Lolland Falster Airport" },
  { code: "ODE", name: "Odense Hans Christian Andersen Airport" },
  { code: "RKE", name: "Roskilde Airport" },
  { code: "RNN", name: "Bornholm Airport" },
  { code: "SGD", name: "Sonderborg Airport" },
  { code: "SKS", name: "Vojens Skrydstrup Airport" },
  { code: "SQW", name: "Skive Airport" },
  { code: "TED", name: "Thisted Airport" },
  { code: "FAE", name: "Vagar Airport" },
  { code: "STA", name: "Stauning Airport" },
  { code: "AAL", name: "Aalborg Airport" },
  { code: "LUX", name: "Luxembourg Findel Airport" },
  { code: "AES", name: "Alesund Vigra Airport" },
  { code: "ANX", name: "Andenes Andoya Airport" },
  { code: "QEN", name: "Ny-Alesund Hamnerabben Airport" },
  { code: "ALF", name: "Alta Airport" },
  { code: "FDE", name: "Forde Airport" },
  { code: "BNN", name: "Bronnoysund Airport" },
  { code: "BOO", name: "Bodo Airport" },
  { code: "BGO", name: "Bergen Flesland Airport" },
  { code: "BJF", name: "Batsfjord Airport" },
  { code: "BVG", name: "Berlevag Airport" },
  { code: "KRS", name: "Kristiansand Kjevik Airport" },
  { code: "BDU", name: "Bardufoss Airport" },
  { code: "EVE", name: "Evenes Harstad Narvik Airport" },
  { code: "VDB", name: "Fagernes Airport" },
  { code: "FRO", name: "Floro Airport" },
  { code: "OSL", name: "Oslo Gardermoen Airport" },
  { code: "HMR", name: "Hamar Stafsberg Airport" },
  { code: "HAU", name: "Haugesund Karmoy Airport" },
  { code: "HFT", name: "Hammerfest Airport" },
  { code: "HAA", name: "Hasvik Airport" },
  { code: "HVG", name: "Honningsvag Valan Airport" },
  { code: "KSU", name: "Kristiansund Kvernberget Airport" },
  { code: "KKN", name: "Kirkenes Hoybuktmoen Airport" },
  { code: "LKN", name: "Leknes Airport" },
  { code: "MEH", name: "Mehamn Airport" },
  { code: "MOL", name: "Molde Aro Airport" },
  { code: "MJF", name: "Mosjoen Airport" },
  { code: "LKL", name: "Lakselv Banak Airport" },
  { code: "OSY", name: "Namsos Airport" },
  { code: "NTB", name: "Notodden Airport" },
  { code: "OLA", name: "Brekstad Orland Airport" },
  { code: "HOV", name: "Orsta Volda Airport" },
  { code: "MQN", name: "Mo i Rana Airport" },
  { code: "RVK", name: "Rorvik Airport" },
  { code: "RRS", name: "Roros Airport" },
  { code: "RET", name: "Rost Airport" },
  { code: "RYG", name: "Oslo Moss Rygge Airport" },
  { code: "QES", name: "Svea Airport" },
  { code: "LYR", name: "Longyearbyen Svalbard Airport" },
  { code: "SDN", name: "Sandane Airport" },
  { code: "SOG", name: "Sogndal Airport" },
  { code: "SVJ", name: "Svolvaer Helle Airport" },
  { code: "SKN", name: "Stokmarknes Skagen Airport" },
  { code: "SKE", name: "Skien Airport" },
  { code: "SRP", name: "Stord Sorstokken Airport" },
  { code: "SOJ", name: "Sorkjosen Airport" },
  { code: "VAW", name: "Vardoe Svartnes Airport" },
  { code: "SSJ", name: "Sandnessjoen Airport" },
  { code: "TOS", name: "Tromso Airport" },
  { code: "TRF", name: "Oslo Sandefjord Airport" },
  { code: "TRD", name: "Trondheim Vaernes Airport" },
  { code: "VDS", name: "Vadso Airport" },
  { code: "SVG", name: "Stavanger Sola Airport" },
  { code: "BXP", name: "Biala Podlaska Airport" },
  { code: "BZG", name: "Bydgoszcz Ignacy Jan Paderewski Airport" },
  { code: "GDN", name: "Gdansk Lech Walesa Airport" },
  { code: "KRK", name: "Krakow John Paul II International Airport" },
  { code: "OSZ", name: "Koszalin Zegrze Pomorskie Airport" },
  { code: "KTW", name: "Katowice International Airport" },
  { code: "LUZ", name: "Lublin Airport" },
  { code: "LCJ", name: "Lodz Wladyslaw Reymont Airport" },
  { code: "WMI", name: "Warsaw Modlin Airport" },
  { code: "POZ", name: "Poznan Lawica Airport" },
  { code: "RDO", name: "Radom Airport" },
  { code: "CZW", name: "Czestochowa Rudniki Airport" },
  { code: "RZE", name: "Rzeszow Jasionka Airport" },
  { code: "SZZ", name: "Szczecin Goleniow Airport" },
  { code: "SZY", name: "Olsztyn Mazury Airport" },
  { code: "WAW", name: "Warsaw Chopin Airport" },
  { code: "WRO", name: "Wroclaw Copernicus Airport" },
  { code: "IEG", name: "Zielona Gora Babimost Airport" },
  { code: "RNB", name: "Ronneby Airport" },
  { code: "GOT", name: "Gothenburg Landvetter Airport" },
  { code: "JKG", name: "Jonkoping Airport" },
  { code: "LDK", name: "Lidkoping Hovby Airport" },
  { code: "GSE", name: "Gothenburg City Airport" },
  { code: "KVB", name: "Skovde Airport" },
  { code: "THN", name: "Trollhattan Vanersborg Airport" },
  { code: "KSK", name: "Karlskoga Airport" },
  { code: "MXX", name: "Mora Siljan Airport" },
  { code: "NYO", name: "Stockholm Skavsta Airport" },
  { code: "KID", name: "Kristianstad Osterlen Airport" },
  { code: "KLR", name: "Kalmar Airport" },
  { code: "MMX", name: "Malmo Airport" },
  { code: "HAD", name: "Halmstad Airport" },
  { code: "VXO", name: "Vaxjo Smaland Airport" },
  { code: "EVG", name: "Sveg Harjedalen Airport" },
  { code: "GEV", name: "Gallivare Airport" },
  { code: "KRF", name: "Kramfors Hoga Kusten Airport " },
  { code: "LYC", name: "Lycksele Airport" },
  { code: "SDL", name: "Sundsvall Timra Airport" },
  { code: "OER", name: "Ornskoldsvik Airport" },
  { code: "KRN", name: "Kiruna Airport" },
  { code: "SFT", name: "Skelleftea Airport" },
  { code: "UME", name: "Umea Airport" },
  { code: "VHM", name: "Vilhelmina Airport" },
  { code: "AJR", name: "Arvidsjaur Airport" },
  { code: "OSD", name: "Ostersund Are Airport" },
  { code: "ORB", name: "Orebro Airport" },
  { code: "HFS", name: "Hagfors Airport" },
  { code: "KSD", name: "Karlstad Airport" },
  { code: "VST", name: "Stockholm Vasteras Hasslo Airport" },
  { code: "LLA", name: "Lulea Airport" },
  { code: "ARN", name: "Stockholm Arlanda Airport" },
  { code: "BMA", name: "Stockholm Bromma Airport" },
  { code: "BLE", name: "Borlange Dala Airport" },
  { code: "HLF", name: "Hultsfred Airport" },
  { code: "GVX", name: "Gavle Airport" },
  { code: "LPI", name: "Linkoping City Airport" },
  { code: "NRK", name: "Norrkoping Airport" },
  { code: "TYF", name: "Torsby Airport" },
  { code: "EKT", name: "Eskilstuna Airport" },
  { code: "VBY", name: "Visby Airport" },
  { code: "VVK", name: "Vastervik Airport" },
  { code: "AGH", name: "Angelholm Helsingborg Airport" },
  { code: "QEL", name: "Ljungbyhed Airport" },
  { code: "PJA", name: "Pajala Airport" },
  { code: "HMV", name: "Hemavan Airport" },
  { code: "RMS", name: "Ramstein Air Base" },
  { code: "RLG", name: "Rostock Laage Airport" },
  { code: "FNB", name: "Neubrandenburg Airport" },
  { code: "IGS", name: "Ingolstadt Manching Airport" },
  { code: "LPX", name: "Liepaja Airport" },
  { code: "RIX", name: "Riga International Airport" },
  { code: "VNT", name: "Ventspils International Airport" },
  { code: "KUN", name: "Kaunas Airport" },
  { code: "PLQ", name: "Palanga International Airport" },
  { code: "SQQ", name: "Siauliai International Airport" },
  { code: "VNO", name: "Vilnius International Airport" },
  { code: "BFN", name: "Bloemfontein Airport" },
  { code: "CPT", name: "Cape Town International Airport" },
  { code: "ELS", name: "East London Airport" },
  { code: "GRJ", name: "George Airport" },
  { code: "HDS", name: "Hoedspruit Airport" },
  { code: "KIM", name: "Kimberley Airport" },
  { code: "MQP", name: "Nelspruit Kruger Mpumalanga International Airport" },
  { code: "HLA", name: "Johannesburg Lanseria International Airport" },
  { code: "DUR", name: "Durban King Shaka International Airport" },
  { code: "LUD", name: "Luderitz Airport" },
  { code: "MGH", name: "Margate Airport" },
  { code: "MBD", name: "Mahikeng Mmabatho Airport" },
  { code: "OND", name: "Ondangwa Airport" },
  { code: "JNB", name: "Johannesburg OR Tambo International Airport" },
  { code: "PLZ", name: "Port Elizabeth Airport" },
  { code: "PHW", name: "Phalaborwa Airport" },
  { code: "PTG", name: "Polokwane International Airport" },
  { code: "PZB", name: "Pietermaritzburg Airport" },
  { code: "NTY", name: "Sun City Pilanesberg International Airport" },
  { code: "RCB", name: "Richards Bay Airport" },
  { code: "SIS", name: "Sishen Airport" },
  { code: "SZK", name: "Skukuza Airport" },
  { code: "UTN", name: "Upington Airport" },
  { code: "UTT", name: "Mthatha Airport" },
  { code: "PRY", name: "Pretoria Wonderboom Airport" },
  { code: "BBK", name: "Kasane Airport" },
  { code: "MUB", name: "Maun Airport" },
  { code: "GBE", name: "Gaborone Sir Seretse Khama International Airport" },
  { code: "BZV", name: "Brazzaville Maya Maya Airport" },
  { code: "PNR", name: "Pointe Noire Airport" },
  { code: "MTS", name: "Manzini Matsapha Airport" },
  { code: "SHO", name: "Manzini King Mswati III International Airport" },
  { code: "BGF", name: "Bangui Airport" },
  { code: "BSG", name: "Bata Airport" },
  { code: "SSG", name: "Malabo International Airport" },
  { code: "ASI", name: "Ascension Island Airport" },
  { code: "HLE", name: "Saint Helena Airport" },
  { code: "MRU", name: "Port Louis Sir Seewoosagur Ramgoolam Airport" },
  { code: "RRG", name: "Rodrigues Island Plaine Corail Airport" },
  { code: "KBI", name: "Kribi Airport" },
  { code: "DLA", name: "Douala International Airport" },
  { code: "MVR", name: "Maroua Salak Airport" },
  { code: "NGE", name: "Ngaoundere Airport" },
  { code: "GOU", name: "Garoua International Airport" },
  { code: "BFX", name: "Bafoussam Airport" },
  { code: "BPC", name: "Bamenda Airport" },
  { code: "NSI", name: "Yaounde Airport" },
  { code: "LUN", name: "Lusaka Kenneth Kaunda International Airport" },
  { code: "KAA", name: "Kasama Airport" },
  {
    code: "LVI",
    name: "Livingstone Harry Mwanga Nkumbula International Airport"
  },
  { code: "NLA", name: "Ndola International Airport" },
  { code: "HAH", name: "Moroni Prince Said Ibrahim International Airport" },
  { code: "AJN", name: "Anjouan Ouani Airport" },
  { code: "DZA", name: "Dzaoudzi Pamandzi International Airport" },
  { code: "RUN", name: "Saint-Denis Roland Garros Airport" },
  { code: "ZSE", name: "Saint-Pierre Pierrefonds Airport" },
  { code: "TNR", name: "Antananarivo Ivato International Airport" },
  { code: "SMS", name: "Sainte Marie Airport" },
  { code: "TMM", name: "Toamasina Airport" },
  { code: "MOQ", name: "Morondava Airport" },
  { code: "DIE", name: "Antsiranana Arrachart Airport" },
  { code: "MJN", name: "Mahajanga Amborovy Airport" },
  { code: "NOS", name: "Nosy Be Fascene Airport" },
  { code: "WMN", name: "Maroantsetra Airport" },
  { code: "SVB", name: "Sambava Airport" },
  { code: "TTS", name: "Tsaratanana Airport" },
  { code: "FTU", name: "Tolanaro Airport" },
  { code: "TLE", name: "Toliara Airport" },
  { code: "CAB", name: "Cabinda Airport" },
  { code: "CBT", name: "Catumbela Airport" },
  { code: "VPE", name: "Ondjiva Pereira Airport" },
  { code: "NOV", name: "Huambo Albano Machado Airport" },
  { code: "SVP", name: "Kuito Airport" },
  { code: "LAD", name: "Luanda Quatro de Fevereiro Airport" },
  { code: "SPP", name: "Menongue Airport" },
  { code: "VHC", name: "Saurimo Airport" },
  { code: "SZA", name: "Soyo Airport" },
  { code: "SDD", name: "Lubango Airport" },
  { code: "LBV", name: "Libreville International Airport" },
  { code: "TMS", name: "Sao Tome International Airport" },
  { code: "BEW", name: "Beira Airport" },
  { code: "VPY", name: "Chimoio Airport" },
  { code: "INH", name: "Inhambane Airport" },
  { code: "VXC", name: "Lichinga Airport" },
  { code: "MPM", name: "Maputo International Airport" },
  { code: "MNC", name: "Nacala Airport" },
  { code: "APL", name: "Nampula Airport" },
  { code: "POL", name: "Pemba Airport" },
  { code: "UEL", name: "Quelimane Airport" },
  { code: "TET", name: "Tete Chingozi Airport" },
  { code: "VNX", name: "Vilankulo Airport" },
  { code: "SEZ", name: "Victoria Seychelles International Airport" },
  { code: "PRI", name: "\tPraslin Island Airport" },
  { code: "NDJ", name: "N'Djamena International Airport" },
  { code: "BUQ", name: "Bulawayo Joshua Mqabuko Nkomo International Airport" },
  { code: "VFA", name: "Victoria Falls Airport" },
  { code: "HRE", name: "Harare Robert Gabriel Mugabe International Airport" },
  { code: "BLZ", name: "Blantyre Chileka International Airport" },
  { code: "LLW", name: "Lilongwe International Airport" },
  { code: "MSU", name: "Maseru Moshoeshoe I International Airport" },
  { code: "MPA", name: "Katima Mulilo Mpacha Airport" },
  { code: "OMD", name: "Oranjemund Airport" },
  { code: "WVB", name: "Walvis Bay Airport" },
  { code: "ERS", name: "Windhoek Eros Airport" },
  { code: "WDH", name: "Windhoek Hosea Kutako International Airport" },
  { code: "FIH", name: "Kinshasa N'Djili Airport" },
  { code: "KRZ", name: "Kiri Basango Mboliasa Airport" },
  { code: "FKI", name: "Kisangani Bangoka International Airport" },
  { code: "BUX", name: "Bunia Airport" },
  { code: "BKY", name: "Kuvumu Airport" },
  { code: "GOM", name: "Goma International Airport" },
  { code: "KND", name: "Kindu Airport" },
  { code: "KGN", name: "Kasongo Lunda Airport" },
  { code: "FBM", name: "Lubumbashi International Airport" },
  { code: "FMI", name: "Kalemie Airport" },
  { code: "KMN", name: "Kamina Airport" },
  { code: "KGA", name: "Kananga Airport" },
  { code: "MJM", name: "Mbuji Mayi Airport" },
  { code: "BKO", name: "Bamako Senou International Airport" },
  { code: "BJL", name: "Banjul International Airport" },
  { code: "FUE", name: "Fuerteventura Airport" },
  { code: "GMZ", name: "La Gomera Airport" },
  { code: "VDE", name: "El Hierro Airport" },
  { code: "SPC", name: "La Palma Airport" },
  { code: "LPA", name: "Gran Canaria Airport" },
  { code: "ACE", name: "Lanzarote Airport" },
  { code: "TFS", name: "Tenerife South Airport" },
  { code: "TFN", name: "Tenerife North Airport" },
  { code: "MLN", name: "Melilla Airport" },
  { code: "KBS", name: "Bo Airport" },
  { code: "FNA", name: "Freetown Lungi International Airport" },
  { code: "OXB", name: "Bissau Osvaldo Vieira International Airport" },
  { code: "ROB", name: "Monrovia Roberts International Airport" },
  { code: "AGA", name: "Agadir Al Massira Airport" },
  { code: "GLN", name: "Guelmim Airport" },
  { code: "TTA", name: "Tan Tan Airport" },
  { code: "OZG", name: "Zagora Airport" },
  { code: "FEZ", name: "Fes Saiss Airport" },
  { code: "ERH", name: "Errachidia Moulay Ali Cherif Airport" },
  { code: "OUD", name: "Oujda Angads Airport" },
  { code: "BEM", name: "Beni Mellal Airport" },
  { code: "RBA", name: "Rabat-Sale Airport" },
  { code: "VIL", name: "Dakhla Airport" },
  { code: "ESU", name: "Essaouira Airport" },
  { code: "EUN", name: "El Aaiun Hassan Airport" },
  { code: "CMN", name: "Casablanca Mohammed V International Airport" },
  { code: "NDR", name: "Nador International Airport" },
  { code: "RAK", name: "Marrakesh Menara Airport" },
  { code: "OZZ", name: "Ouarzazate Airport" },
  { code: "AHU", name: "Al Hoceima Cherif Al Idrissi Airport" },
  { code: "TTU", name: "Tetuan Sania Ramel Airport" },
  { code: "TNG", name: "Tangier Ibn Battouta Airport" },
  { code: "DSS", name: "Dakar Blaise Diagne International Airport" },
  { code: "ZIG", name: "Ziguinchor Airport" },
  { code: "DKR", name: "Dakar International Airport" },
  { code: "NKC", name: "Nouakchott Oumtounsy International Airport" },
  { code: "NDB", name: "Nouadhibou International Airport" },
  { code: "OUZ", name: "Zouerate Tazadit Airport" },
  { code: "CKY", name: "Conakry International Airport" },
  { code: "SID", name: "Espargos Amilcar Cabral International Airport" },
  { code: "BVC", name: "Boa Vista Rabil Airport" },
  { code: "RAI", name: "Praia Nelson Mandela International Airport" },
  { code: "VXE", name: "Sao Vicente Cesaria Evora Airport" },
  { code: "ADD", name: "Addis Ababa Bole Airport" },
  { code: "AMH", name: "Arba Minch Airport" },
  { code: "AXU", name: "Axum Airport" },
  { code: "BJR", name: "Bahir Dar Airport" },
  { code: "DIR", name: "Dire Dawa International Airport" },
  { code: "GMB", name: "Gambela Airport" },
  { code: "GDQ", name: "Gondar Airport" },
  { code: "JIJ", name: "Jijiga Airport" },
  { code: "JIM", name: "Jimma Aba Segud Airport" },
  { code: "AWA", name: "Awasa Airport" },
  { code: "LLI", name: "Lalibela Airport" },
  { code: "MQX", name: "Mekele Alula Aba Nega Airport" },
  { code: "ASO", name: "Asosa Airport" },
  { code: "BJM", name: "Bujumbura International Airport" },
  { code: "BSA", name: "Bosaso Airport" },
  { code: "HGA", name: "Hargeisa Airport" },
  { code: "BBO", name: "Berbera Airport" },
  { code: "KMU", name: "Kisimayu Airport" },
  { code: "MGQ", name: "Mogadishu Aden Adde International Airport" },
  { code: "GLK", name: "Galcaio Airport" },
  { code: "GGR", name: "Garowe Airport" },
  { code: "JIB", name: "Djibouti Ambouli International Airport" },
  { code: "DBB", name: "El Alamein International Airport" },
  { code: "ATZ", name: "Assiut Airport" },
  { code: "HBE", name: "Alexandria Borg El Arab Airport" },
  { code: "ABS", name: "Abu Simbel Airport" },
  { code: "CAI", name: "Cairo International Airport" },
  { code: "HRG", name: "Hurghada International Airport" },
  { code: "LXR", name: "Luxor International Airport" },
  { code: "RMF", name: "Marsa Alam International Airport" },
  { code: "HMB", name: "Sohag International Airport" },
  { code: "MUH", name: "Marsa Matruh International Airport" },
  { code: "PSD", name: "Port Said Airport" },
  { code: "SSH", name: "Sharm el-Sheikh International Airport" },
  { code: "ASW", name: "Aswan International Airport" },
  { code: "SPX", name: "Giza Sphinx International Airport" },
  { code: "TCP", name: "Taba International Airport" },
  { code: "ASM", name: "Asmara International Airport" },
  { code: "ASV", name: "Amboseli Airport" },
  { code: "EDL", name: "Eldoret International Airport" },
  { code: "GAS", name: "Garissa Airport" },
  { code: "NBO", name: "Nairobi Jomo Kenyatta International Airport" },
  { code: "KIS", name: "Kisumu International Airport" },
  { code: "KEY", name: "Kericho Airport" },
  { code: "KTL", name: "Kitale Airport" },
  { code: "LOK", name: "Lodwar Airport" },
  { code: "LAU", name: "Lamu Manda Airport" },
  { code: "JJM", name: "Meru Mulika Lodge Airport" },
  { code: "MYD", name: "Malindi Airport" },
  { code: "MBA", name: "Mombasa Moi International Airport" },
  { code: "MRE", name: "Mara Serena Airport" },
  { code: "WIL", name: "Nairobi Wilson Airport" },
  { code: "NYK", name: "Nanyuki Airport" },
  { code: "UAS", name: "Samburu Buffalo Spring Airport" },
  { code: "UKA", name: "Ukunda Airport" },
  { code: "WJR", name: "Wajir Airport" },
  { code: "TOB", name: "Tobruk Airport" },
  { code: "GHT", name: "Ghat Airport" },
  { code: "BEN", name: "Benghazi Benina International Airport" },
  { code: "MJI", name: "Tripoli Mitiga International Airport" },
  { code: "LAQ", name: "Beida Al Abraq International Airport" },
  { code: "SEB", name: "Sabha Airport" },
  { code: "TIP", name: "Tripoli International Airport" },
  { code: "MRA", name: "Misrata International Airport" },
  { code: "ZIS", name: "Alzintan Airport" },
  { code: "WAX", name: "Zwara Airport" },
  { code: "KGL", name: "Kigali Airport" },
  { code: "EGN", name: "Geneina Airport" },
  { code: "ELF", name: "El Fasher Airport" },
  { code: "KSL", name: "Kassala Airport" },
  { code: "UYL", name: "Nyala Airport" },
  { code: "EBD", name: "El Obeid Airport" },
  { code: "PZU", name: "Port Sudan New International Airport" },
  { code: "JUB", name: "Juba Airport" },
  { code: "KRT", name: "Khartoum International Airport" },
  { code: "ARK", name: "Arusha Airport" },
  { code: "BKZ", name: "Bukoba Airport" },
  { code: "DAR", name: "Dar-es-Salaam Julius Nyerere International Airport" },
  { code: "DOD", name: "Dodoma Airport" },
  { code: "IRI", name: "Iringa Airport" },
  { code: "TKQ", name: "Kigoma Airport" },
  { code: "KIY", name: "Kilwa Masoko Airport" },
  { code: "JRO", name: "Kilimanjaro International Airport" },
  { code: "LKY", name: "Lake Manyara Airport" },
  { code: "MFA", name: "Mafia Airport" },
  { code: "MBI", name: "Mbeya Airport" },
  { code: "MYW", name: "Mtwara Airport" },
  { code: "MUZ", name: "Musoma Airport" },
  { code: "MWZ", name: "Mwanza Airport" },
  { code: "PMA", name: "Pemba Airport" },
  { code: "SEU", name: "Seronera Airstrip" },
  { code: "SGX", name: "Songea" },
  { code: "TBO", name: "Tabora Airport" },
  { code: "TGT", name: "Tanga Airport" },
  { code: "ZNZ", name: "Zanzibar Abeid Amani Karume International Airport" },
  { code: "EBB", name: "Entebbe International Airport" },
  { code: "AAF", name: "Apalachicola Regional Airport" },
  { code: "ABE", name: "Allentown Lehigh Valley International Airport" },
  { code: "ABI", name: "Abilene Regional Airport" },
  { code: "ABQ", name: "Albuquerque International Airport" },
  { code: "ABR", name: "Aberdeen Regional Airport" },
  { code: "ABY", name: "Albany Southwest Georgia Regional Airport" },
  { code: "ACK", name: "Nantucket Memorial Airport" },
  { code: "ACT", name: "Waco Regional Airport" },
  { code: "ACV", name: "Eureka Arcata Airport" },
  { code: "ACY", name: "Atlantic City International Airport" },
  { code: "ADT", name: "Ada Municipal Airport" },
  { code: "ADM", name: "Ardmore Municipal Airport" },
  { code: "ADS", name: "Dallas Addison Airport" },
  { code: "AEX", name: "Alexandria International Airport" },
  { code: "WSG", name: "Washington County Airport" },
  { code: "AFW", name: "Fort Worth Alliance Airport" },
  { code: "AGC", name: "Pittsburgh Allegheny County Airport" },
  { code: "AGS", name: "Augusta Regional Airport" },
  { code: "AHN", name: "Athens Ben Epps Airport" },
  { code: "AIA", name: "Alliance Municipal Airport" },
  { code: "AIK", name: "Aiken Municipal Airport" },
  { code: "AIZ", name: "Lake Ozark Lee C Fine Memorial Airport" },
  { code: "AKC", name: "Akron Fulton International Airport" },
  { code: "ALB", name: "Albany International Airport" },
  { code: "ALM", name: "Alamogordo White Sands Regional Airport" },
  { code: "ALN", name: "Alton St. Louis Regional Airport" },
  { code: "ALO", name: "Waterloo Regional Airport" },
  { code: "ALS", name: "Alamosa San Luis Valley Regional Airport" },
  { code: "ALW", name: "Walla Walla Airport" },
  { code: "AMA", name: "Amarillo Rick Husband  International Airport" },
  { code: "AMW", name: "Ames Municipal Airport" },
  { code: "ANB", name: "Anniston Regional" },
  { code: "AND", name: "Anderson Regional Airport" },
  { code: "QQA", name: "Minneapolis Anoka County Blaine Airport" },
  { code: "SLT", name: "Salida Harriet Alexander Field Airport" },
  { code: "ANP", name: "Annapolis Lee Airport" },
  { code: "ANQ", name: "Angola Tri-State Steuben County Airport" },
  { code: "AOO", name: "Altoona Blair County Airport" },
  { code: "APA", name: "Denver Centennial Airport" },
  { code: "APC", name: "Napa County Airport" },
  { code: "APF", name: "Naples Municipal Airport" },
  { code: "APN", name: "Alpena County Regional Airport" },
  { code: "APV", name: "Apple Valley Airport" },
  { code: "ARA", name: "New Iberia Acadiana Regional Airport" },
  { code: "ARB", name: "Ann Arbor Municipal Airport" },
  { code: "AUZ", name: "Chicago Aurora Municipal Airport" },
  { code: "ART", name: "Watertown International Airport" },
  { code: "ARV", name: "Minocqua Woodruff Lakeland Airport" },
  { code: "BFT", name: "Beaufort County Airport" },
  { code: "ASE", name: "Aspen Pitkin County Airport" },
  { code: "SPZ", name: "Springdale Municipal Airport" },
  { code: "ASH", name: "Nashua Airport" },
  { code: "ASL", name: "Marshall Harrison County Airport" },
  { code: "ASN", name: "Talladega Municipal Airport" },
  { code: "AST", name: "Astoria Regional Airport" },
  { code: "ATL", name: "Atlanta Hartsfield-Jackson International Airport" },
  { code: "ATS", name: "Artesia Municipal Airport" },
  { code: "ATW", name: "Appleton International Airport" },
  { code: "ATY", name: "Watertown Regional Airport" },
  { code: "AUG", name: "Augusta State Airport" },
  { code: "AUN", name: "Auburn Municipal Airport" },
  { code: "AUO", name: "Auburn University Regional Airport" },
  { code: "AUS", name: "Austin Bergstrom International Airport" },
  { code: "AVL", name: "Asheville Regional Airport" },
  { code: "AVP", name: "Avoca Wilkes-Barre/Scranton International Airport" },
  { code: "AVW", name: "Tucson Marana Regional Airport" },
  { code: "AVX", name: "Avalon Catalina Airport" },
  { code: "AWM", name: "West Memphis Municipal Airport" },
  { code: "QQW", name: "Arlington Municipal Airport" },
  { code: "AXN", name: "Alexandria Municipal Airport" },
  { code: "AYS", name: "Waycross Ware County Airport" },
  { code: "AZO", name: "Kalamazoo Battle Creek International Airport" },
  { code: "BAF", name: "Westfield Barnes Regional Airport" },
  { code: "CLU", name: "Columbus Municipal Airport" },
  { code: "BAM", name: "Battle Mountain Airport" },
  { code: "QQZ", name: "New Braunfels Regional Airport" },
  { code: "BKG", name: "Branson Airport" },
  {
    code: "BCB",
    name: "Blacksburg Virginia Tech Montgomery Executive Airport"
  },
  { code: "BCT", name: "Boca Raton Airport" },
  { code: "BDL", name: "Windsor Locks Bradley International Airport" },
  { code: "BDR", name: "Stratford Sikorsky Memorial Airport" },
  { code: "WBU", name: "Boulder Municipal Airport" },
  { code: "BEC", name: "Wichita Beech Factory Airport" },
  { code: "BED", name: "Bedford Hanscom Field" },
  { code: "BEH", name: "Benton Harbor Southwest Michigan Regional Airport" },
  { code: "BFD", name: "Bradford Regional Airport" },
  { code: "BFF", name: "Scottsbluff Western Nebraska Regional Airport" },
  { code: "BFI", name: "Seattle Boeing Field" },
  { code: "BFL", name: "Bakersfield Meadows Field Airport" },
  { code: "BFM", name: "Mobile Downtown Airport" },
  { code: "BGD", name: "Borger Hutchinson County Airport" },
  { code: "BGM", name: "Greater Binghamton Airport" },
  { code: "BGR", name: "Bangor International Airport" },
  { code: "BHB", name: "Hancock County Bar Harbor Airport" },
  { code: "BHM", name: "Birmingham Shuttlesworth International Airport" },
  { code: "BID", name: "Block Island Airport" },
  { code: "BIE", name: "Beatrice Municipal Airport" },
  { code: "BIH", name: "Bishop Eastern Sierra Regional Airport" },
  { code: "BIL", name: "Billings Logan International Airport" },
  { code: "BIS", name: "Bismarck Municipal Airport" },
  { code: "BJC", name: "Denver Rocky Mountain Metropolitan Airport" },
  { code: "BJI", name: "Bemidji Regional Airport" },
  { code: "BJJ", name: "Wooster Wayne County Airport" },
  { code: "BKE", name: "Baker City Municipal Airport" },
  { code: "BKL", name: "Cleveland Burke Lakefront Airport" },
  { code: "BKW", name: "Beckley Raleigh County Memorial Airport" },
  { code: "BKX", name: "Brookings Airport" },
  { code: "BLI", name: "Bellingham International Airport" },
  { code: "BLM", name: "Atlantic City Monmouth Executive Airport" },
  { code: "BLV", name: "Belleville MidAmerica St. Louis Airport" },
  { code: "BMG", name: "Bloomington Monroe County Airport" },
  { code: "BMI", name: "Bloomington Central Illinois Regional Airport" },
  { code: "BNA", name: "Nashville International Airport" },
  { code: "BOI", name: "Boise Airport" },
  { code: "BOS", name: "Boston Logan International Airport" },
  { code: "BOW", name: "Bartow Municipal Airport" },
  { code: "WMH", name: "Mountain Home Ozark Regional Airport" },
  { code: "BPT", name: "Beaumont Jack Brooks Regional Airport" },
  { code: "BQK", name: "Brunswick Golden Isles Airport" },
  { code: "BRD", name: "Brainerd Lakes Regional Airport" },
  { code: "BRL", name: "Burlington Southeast Iowa Regional Airport" },
  { code: "BRO", name: "Brownsville South Padre Island International Airport" },
  { code: "BTF", name: "Bountiful Skypark Airport" },
  { code: "BTL", name: "Battle Creek Kalamazoo International Airport" },
  { code: "BTM", name: "Butte Bert Mooney Airport" },
  { code: "BTP", name: "Butler Regional Airport" },
  { code: "BTR", name: "Baton Rouge Metropolitan Airport" },
  { code: "BTV", name: "Burlington International Airport" },
  { code: "BUF", name: "Buffalo Niagara International Airport" },
  { code: "BUR", name: "Burbank Bob Hope Airport" },
  { code: "QQU", name: "Burlington Municipal Airport" },
  { code: "QQY", name: "Burlington Alamance Regional Airport" },
  { code: "BFP", name: "Beaver County Airport" },
  { code: "BVO", name: "Bartlesville Municipal Airport" },
  { code: "MVW", name: "Burlington/Mount Vernon Skagit Regional Airport" },
  { code: "BVX", name: "Batesville Regional Airport" },
  { code: "BVY", name: "Beverly Municipal Airport" },
  { code: "BWD", name: "Brownwood Regional Airport" },
  { code: "BWG", name: "Bowling Green Warren County Regional Airport" },
  { code: "BWI", name: "Baltimore Washington International Airport" },
  { code: "BZN", name: "Bozeman Yellowstone International Airport" },
  { code: "CAE", name: "Columbia Metropolitan Airport" },
  { code: "CAK", name: "Akron Canton Airport" },
  { code: "CBF", name: "Council Bluffs Municipal Airport" },
  { code: "CCB", name: "Upland Cable Airport" },
  { code: "CCR", name: "Concord Buchanan Field Airport" },
  { code: "CDC", name: "Cedar City Regional Airport" },
  { code: "CDR", name: "Chadron Municipal Airport" },
  { code: "CDW", name: "Caldwell Essex County Airport" },
  { code: "CEC", name: "Crescent City Del Norte County Airport" },
  { code: "CEF", name: "Springfield Westover Metropolitan Airport" },
  { code: "CEU", name: "Clemson Oconee County Regional Airport" },
  { code: "CEW", name: "Crestview Bob Sikes Airport" },
  { code: "CEZ", name: "Cortez Municipal Airport" },
  { code: "CGE", name: "Cambridge Dorchester Airport" },
  { code: "CGF", name: "Cleveland Cuyahoga County Airport" },
  { code: "CGI", name: "Cape Girardeau Regional Airport" },
  { code: "CGS", name: "College Park Airport" },
  { code: "CGZ", name: "Casa Grande Municipal Airport" },
  { code: "CHA", name: "Chattanooga Metropolitan Airport" },
  { code: "CHO", name: "Charlottesville Albemarle Airport" },
  { code: "CHS", name: "Charleston International Airport" },
  { code: "CIC", name: "Chico Municipal Airport" },
  { code: "CID", name: "Cedar Rapids Eastern Iowa Airport" },
  {
    code: "CIU",
    name: "Sault Sainte Marie Chippewa County International Airport"
  },
  { code: "CKB", name: "Clarksburg North Central West Virginia Airport" },
  { code: "GRM", name: "Grand Marais Cook County Airport" },
  { code: "CKV", name: "Clarksville Montgomery County Regional Airport" },
  { code: "CLE", name: "Cleveland Hopkins International Airport" },
  { code: "CLL", name: "College Station Easterwood Airport" },
  { code: "CLS", name: "Chehalis Centralia Airport" },
  { code: "CLT", name: "Charlotte Douglas International Airport" },
  { code: "CLW", name: "Tampa Clearwater Air Park Airport" },
  { code: "CMH", name: "Columbus John Glenn International Airport" },
  { code: "CMI", name: "Champaign University of Illinois Willard Airport" },
  { code: "CMX", name: "Hancock Houghton County Memorial Airport" },
  { code: "QQC", name: "Canton Cherokee County Regional Airport" },
  { code: "CNM", name: "Carlsbad Cavern City Air Terminal Airport" },
  { code: "CNO", name: "Chino Airport" },
  { code: "CNW", name: "Waco Texas State Technical College Airport" },
  { code: "CNY", name: "Moab Canyonlands Field" },
  { code: "COD", name: "Cody Yellowstone Regional Airport" },
  { code: "COE", name: "Coeur d'Alene Airport" },
  { code: "COI", name: "Merritt Island Airport" },
  { code: "CON", name: "Concord Municipal Airport" },
  { code: "COS", name: "Colorado Springs Airport" },
  { code: "COU", name: "Columbia Regional Airport" },
  { code: "QQP", name: "Chesapeake Regional Airport" },
  { code: "CPM", name: "Compton Woodley Airport" },
  { code: "CPR", name: "Casper Natrona County International Airport" },
  { code: "CPS", name: "St. Louis Downtown Airport" },
  { code: "CRE", name: "North Myrtle Beach Grand Strand Airport" },
  { code: "CRG", name: "Jacksonville Executive at Craig Airport" },
  { code: "CRP", name: "Corpus Christi International Airport" },
  { code: "CLD", name: "San Diego McClellan Palomar Airport" },
  { code: "CRW", name: "Charleston Yeager Airport" },
  { code: "CSG", name: "Columbus Metropolitan Airport" },
  { code: "CSV", name: "Crossville Memorial Whitson Field Airport" },
  { code: "CTY", name: "Cross City Airport" },
  { code: "CUB", name: "Columbia Jim Hamilton Owens Airport" },
  { code: "CVG", name: "Cincinnati Northern Kentucky International Airport" },
  { code: "CVN", name: "Clovis Municipal Airport" },
  { code: "CVO", name: "Corvallis Municipal Airport" },
  { code: "CWA", name: "Wausau Central Wisconsin Airport" },
  { code: "KIP", name: "Wichita Falls Kickapoo Downtown Airport" },
  { code: "CXL", name: "Calexico International Airport" },
  { code: "CXO", name: "Conroe Lone Star Executive Airport" },
  { code: "CSN", name: "Carson Airport" },
  { code: "HAR", name: "Harrisburg Capital City Airport" },
  { code: "CYS", name: "Cheyenne Regional Airport" },
  { code: "DAB", name: "Daytona Beach International Airport" },
  { code: "DAL", name: "Dallas Love Field Airport" },
  { code: "DAN", name: "Danville Regional Airport" },
  { code: "DAY", name: "Dayton International Airport" },
  { code: "DBQ", name: "Dubuque Regional Airport" },
  { code: "DCA", name: "Washington Ronald Reagan National Airport" },
  { code: "DCU", name: "Decatur Pryor Field Regional Airport" },
  { code: "DDC", name: "Dodge City Regional Airport" },
  { code: "DEC", name: "Decatur Airport" },
  { code: "DEN", name: "Denver International Airport" },
  { code: "DET", name: "Detroit Coleman A. Young International Airport" },
  { code: "DFW", name: "Dallas Fort Worth International Airport" },
  { code: "DHN", name: "Dothan Regional Airport" },
  { code: "DHT", name: "Dalhart Municipal Airport" },
  { code: "DIK", name: "Dickinson Theodore Roosevelt Regional Airport" },
  { code: "DLH", name: "Duluth International Airport" },
  { code: "DLN", name: "Dillon Airport" },
  { code: "DLS", name: "The Dalles Columbia Gorge Regional Airport" },
  { code: "DNL", name: "Augusta Daniel Field Airport" },
  { code: "DNN", name: "Dalton Municipal Airport" },
  { code: "DPA", name: "Chicago DuPage Airport" },
  { code: "DRO", name: "Durango La Plata County Airport" },
  { code: "DRT", name: "Del Rio International Airport" },
  { code: "DSM", name: "Des Moines International Airport" },
  { code: "DTN", name: "Shreveport Downtown Airport" },
  { code: "QQD", name: "Denton Enterprise Airport" },
  { code: "DSI", name: "Destin Fort Walton Beach Airport" },
  { code: "DTW", name: "Detroit Metropolitan Wayne County Airport" },
  { code: "DUA", name: "Durant Regional Airport" },
  { code: "DUC", name: "Duncan Halliburton Airport" },
  { code: "DUJ", name: "DuBois Regional Airport" },
  { code: "DVL", name: "Devils Lake Regional Airport" },
  { code: "DVN", name: "Davenport Municipal Airport" },
  { code: "NOT", name: "Novato Marin County Airport" },
  { code: "DVT", name: "Phoenix Deer Valley Airport" },
  { code: "DWH", name: "Houston David Wayne Hooks Memorial Airport" },
  { code: "DXR", name: "Danbury Municipal Airport" },
  { code: "DYL", name: "Doylestown Airport" },
  { code: "EAR", name: "Kearney Regional Airport" },
  { code: "EAT", name: "Wenatchee Pangborn Memorial Airport" },
  { code: "EAU", name: "Eau Claire Chippewa Valley Regional Airport" },
  { code: "ECP", name: "Panama City International Airport" },
  { code: "EDC", name: "Austin Executive Airport" },
  { code: "EDE", name: "Northeastern Regional Airport" },
  { code: "ETS", name: "Enterprise Municipal Airport" },
  { code: "EEN", name: "Keene Dillant Hopkins Airport" },
  { code: "EFD", name: "Houston Ellington Airport" },
  { code: "EGE", name: "Eagle County Regional Airport" },
  { code: "EGV", name: "Eagle River Airport" },
  { code: "QQE", name: "Erie Municipal Airport" },
  { code: "EKA", name: "Eureka Murray Field Airport" },
  { code: "EKI", name: "Elkhart Municipal Airport" },
  { code: "EKO", name: "Elko Regional Airport" },
  { code: "EKX", name: "Elizabethtown Regional Airport" },
  { code: "ELD", name: "El Dorado South Arkansas Regional Airport" },
  { code: "ELM", name: "Elmira Corning Regional Airport" },
  { code: "ELN", name: "Ellensburg Bowers Airport" },
  { code: "ELP", name: "El Paso International Airport" },
  { code: "ELY", name: "Ely Airport" },
  { code: "EMT", name: "El Monte San Gabriel Valley Airport" },
  { code: "ENV", name: "Wendover Airport" },
  { code: "ENW", name: "Kenosha Regional Airport" },
  { code: "ERI", name: "Erie International Airport" },
  { code: "ERV", name: "Kerrville Municipal Airport" },
  { code: "ESC", name: "Escanaba Delta County Airport" },
  { code: "ESN", name: "Easton Airport" },
  { code: "ETB", name: "West Bend Municipal Airport" },
  { code: "EUG", name: "Eugene Airport" },
  { code: "QQB", name: "New Smyrna Beach Municipal Airport" },
  { code: "EVM", name: "Eveleth Virginia Municipal Airport" },
  { code: "EVV", name: "Evansville Regional Airport" },
  { code: "EWB", name: "New Bedford Regional Airport" },
  { code: "EWK", name: "Newton City County Airport" },
  { code: "EWN", name: "New Bern Coastal Carolina Regional Airport" },
  { code: "EWR", name: "New York Newark Liberty International Airport" },
  { code: "EYW", name: "Key West International Airport" },
  { code: "FAR", name: "Fargo Hector International Airport" },
  { code: "FAT", name: "Fresno Yosemite International Airport" },
  { code: "FAY", name: "Fayetteville Regional Airport" },
  { code: "FBL", name: "Faribault Municipal Airport" },
  { code: "FCM", name: "Minneapolis Flying Cloud Airport" },
  { code: "FDK", name: "Frederick Municipal Airport" },
  { code: "FDY", name: "Findlay Airport" },
  { code: "FET", name: "Fremont Municipal Airport" },
  { code: "FFM", name: "Fergus Falls Municipal Airport" },
  { code: "FFT", name: "Frankfort Capital City Airport" },
  { code: "MSC", name: "Mesa Falcon Field Airport" },
  { code: "FRD", name: "Friday Harbor Airport" },
  { code: "FHU", name: "Fort Huachuca Sierra Vista Municipal Airport" },
  { code: "QQF", name: "Bunnell Flagler County Airport" },
  { code: "FKL", name: "Franklin Venango Regional Airport" },
  { code: "FLD", name: "Fond du Lac County Airport" },
  { code: "FLG", name: "Flagstaff Pulliam Airport" },
  { code: "FLL", name: "Fort Lauderdale Hollywood International Airport" },
  { code: "FLO", name: "Florence Regional Airport" },
  { code: "FME", name: "Fort Meade Tipton Airport" },
  { code: "FMN", name: "Farmington Four Corners Regional Airport" },
  { code: "FMY", name: "Fort Myers Page Field Airport" },
  { code: "FNL", name: "Fort Collins Northern Colorado Regional Airport" },
  { code: "FNT", name: "Flint Bishop International Airport" },
  { code: "FOD", name: "Fort Dodge Regional Airport" },
  { code: "FOE", name: "Topeka Regional Airport" },
  { code: "FOK", name: "Westhampton Beach Francis S Gabresk Airport" },
  { code: "FPR", name: "Fort Pierce St. Lucie County International Airport" },
  { code: "FRG", name: "Farmingdale Republic Airport" },
  { code: "FRM", name: "Fairmont Municipal Airport" },
  { code: "FSD", name: "Sioux Falls Regional Airport" },
  { code: "FSM", name: "Fort Smith Regional Airport" },
  { code: "QQH", name: "Denver Front Range Airport" },
  { code: "FTW", name: "Fort Worth Meacham International Airport" },
  { code: "FTY", name: "Atlanta Fulton County Airport" },
  { code: "FUL", name: "Fullerton Municipal Airport" },
  { code: "FWA", name: "Fort Wayne International Airport" },
  { code: "FXE", name: "Fort Lauderdale Executive Airport" },
  { code: "FYV", name: "Fayetteville Drake Field Airport" },
  { code: "GAD", name: "Gadsden Northeast Alabama Regional Airport" },
  { code: "GAI", name: "Gaithersburg Montgomery County Airpark" },
  { code: "GBD", name: "Great Bend Airport" },
  { code: "GCC", name: "Gillette Campbell County Airport" },
  { code: "GCK", name: "Garden City Regional Airport" },
  { code: "GCN", name: "Grand Canyon National Park Airport" },
  { code: "GCY", name: "Greeneville Greene County Municipal Airport" },
  { code: "GDV", name: "Dawson Community Airport" },
  { code: "GED", name: "Georgetown Delaware Coastal Airport" },
  { code: "GEG", name: "Spokane International Airport" },
  { code: "GFK", name: "Grand Forks International Airport" },
  { code: "GFL", name: "Glens Falls Floyd Bennett Memorial Airport" },
  { code: "GGE", name: "Georgetown County Airport" },
  { code: "GGG", name: "Longview East Texas Regional Airport" },
  { code: "GGW", name: "Glasgow Airport" },
  { code: "GIF", name: "Winter Haven's Gilbert Airport" },
  { code: "GJT", name: "Grand Junction Regional Airport" },
  { code: "GKT", name: "Sevierville Gatlinburg Airport" },
  { code: "QQG", name: "Arlington Municipal Airport" },
  { code: "GLD", name: "Goodland Municipal Airport" },
  { code: "GLE", name: "Gainesville Municipal Airport" },
  { code: "GLH", name: "Greenville Mid Delta Regional Airport" },
  { code: "GLR", name: "Gaylord Regional Airport" },
  { code: "GLS", name: "Galveston Scholes International Airport" },
  { code: "GMU", name: "Greenville Downtown Airport" },
  { code: "GNV", name: "Gainesville Regional Airport" },
  { code: "GON", name: "New London Groton Airport" },
  { code: "FCA", name: "Kalispell Glacier Park International Airport" },
  { code: "GPT", name: "Gulfport Biloxi International Airport" },
  { code: "GPZ", name: "Grand Rapids Itsaca County Airport" },
  { code: "GRB", name: "Green Bay Austin Straubel International Airport" },
  { code: "GRD", name: "Greenwood County Airport" },
  { code: "GRI", name: "Grand Island Central Nebraska Regional Airport" },
  { code: "GRK", name: "Killeen Fort Hood Regional Airport" },
  { code: "GRR", name: "Grand Rapids Gerald R. Ford International Airport" },
  { code: "GSH", name: "Goshen Municipal Airport" },
  { code: "GSO", name: "Greensboro Piedmont Triad International Airport" },
  { code: "GSP", name: "Greenville-Spartanburg International Airport" },
  { code: "GTF", name: "Great Falls International Airport" },
  { code: "GTR", name: "Golden Triangle Regional Airport" },
  { code: "GUC", name: "Gunnison Crested Butte Regional Airport" },
  { code: "GUP", name: "Gallup Municipal Airport" },
  { code: "GVL", name: "Gainesville Lee Gilmer Memorial Airport" },
  { code: "GVT", name: "Greenville Majors Airport" },
  { code: "GWO", name: "Greenwood Leflore Airport" },
  { code: "GXY", name: "Greeley Weld County Airport" },
  { code: "GDC", name: "Greenville Donaldson Center Airport" },
  { code: "PNX", name: "Sherman North Texas Regional Airport" },
  { code: "GYR", name: "Phoenix Goodyear Airport" },
  { code: "GYY", name: "Chicago Gary International" },
  { code: "HAF", name: "Half Moon Bay Airport" },
  { code: "HAO", name: "Hamilton Butler County Regional Airport" },
  { code: "HBG", name: "Hattiesburg Bobby L. Chain Municipal Airport" },
  { code: "HDN", name: "Hayden Yampa Valley Airport" },
  { code: "MNZ", name: "Manassas Regional Airport" },
  { code: "HEZ", name: "Natchez Adams County Airport" },
  { code: "HFD", name: "Hartford Brainard Airport" },
  { code: "HGR", name: "Hagerstown Regional Airport" },
  { code: "HHR", name: "Hawthorne Municipal Airport" },
  { code: "HIB", name: "Hibbing Range Regional Airport" },
  { code: "HII", name: "Lake Havasu City Airport" },
  { code: "HIO", name: "Portland Hillsboro Airport" },
  { code: "HKS", name: "Jackson Hawkins Field Airport" },
  { code: "HKY", name: "Hickory Regional" },
  { code: "HLG", name: "Wheeling Ohio County Airport" },
  { code: "HLN", name: "Helena Regional Airport" },
  { code: "HNB", name: "Huntingburg Airport" },
  { code: "HSH", name: "Las Vegas Henderson Executive Airport" },
  { code: "HOB", name: "Hobbs Lea County Regional Airport" },
  { code: "HON", name: "Huron Regional Airport" },
  { code: "HOT", name: "Hot Springs Memorial Field Airport" },
  { code: "HOU", name: "Houston William P. Hobby Airport" },
  { code: "HPN", name: "White Plains Westchester County Airport" },
  { code: "HQM", name: "Hoquiam Bowerman Airport" },
  { code: "HRL", name: "Harlingen Valley International Airport" },
  { code: "HRO", name: "Harrison Boone County Airport" },
  { code: "HSA", name: "Bay St. Louis Stennis International Airport" },
  { code: "HSI", name: "Hastings Municipal Airport" },
  { code: "HSV", name: "Huntsville International Airport" },
  { code: "HTO", name: "East Hampton Airport" },
  { code: "HTS", name: "Huntington Tri State Airport" },
  { code: "HUF", name: "Terre Haute Regional Airport" },
  { code: "HUM", name: "Houma Terrebonne Airport" },
  { code: "HUT", name: "Hutchinson Airport" },
  { code: "HVN", name: "New Haven Tweed Airport" },
  { code: "HVR", name: "Havre City County Airport" },
  { code: "HWD", name: "Hayward Executive Airport" },
  { code: "HWO", name: "Hollywood North Perry Airport" },
  { code: "HHH", name: "Hilton Head Island Airport" },
  { code: "HYA", name: "Hyannis Barnstable Municipal Airport" },
  { code: "HYI", name: "San Marcos Municipal Airport" },
  { code: "HYR", name: "Hayward Sawyer County Airport" },
  { code: "HYS", name: "Hays Regional Airport" },
  { code: "IAD", name: "Washington Dulles International Airport" },
  { code: "IAG", name: "Niagara Falls International Airport" },
  { code: "IAH", name: "Houston George Bush Intercontinental Airport" },
  { code: "ICT", name: "Wichita Dwight D. Eisenhower National Airport" },
  { code: "IDA", name: "Idaho Falls Regional Airport" },
  { code: "IDP", name: "Independence Municipal Airport" },
  { code: "IFP", name: "Bullhead International Airport" },
  { code: "IGM", name: "Kingman Airport" },
  { code: "QQI", name: "Chicago Lansing Municipal Airport" },
  { code: "IKK", name: "Greater Kankakee Airport" },
  { code: "QQK", name: "Ankeny Regional Airport" },
  { code: "ILG", name: "Wilmington New Castle Airport" },
  { code: "ILM", name: "Wilmington International Airport" },
  { code: "ILN", name: "Wilmington Air Park Airport" },
  { code: "IML", name: "Imperial Municipal Airport" },
  { code: "IMM", name: "Immokalee Regional Airport" },
  { code: "IMT", name: "Iron Mountain Ford Airport" },
  { code: "IND", name: "Indianapolis International Airport" },
  { code: "INL", name: "Falls International Airport" },
  { code: "INT", name: "Winston-Salem Smith Reynolds Airport" },
  { code: "IOW", name: "Iowa City Municipal Airport" },
  { code: "IPL", name: "Imperial County Airport" },
  { code: "IPT", name: "Williamsport Regional Airport" },
  { code: "IRK", name: "Kirksville Municipal Airport" },
  { code: "ISM", name: "Kissimmee Gateway Airport" },
  { code: "ISN", name: "Williston Sloulin Field International Airport" },
  { code: "ISO", name: "Kinston Regional Jetport" },
  { code: "ISP", name: "Islip Long Island MacArthur Airport" },
  { code: "ITH", name: "Ithaca Tompkins Regional Airport" },
  { code: "AZA", name: "Phoenix Mesa Gateway Airport" },
  { code: "IWD", name: "Ironwood Gogebic Iron County Airport" },
  { code: "ISS", name: "Wiscasset Airport" },
  { code: "IWS", name: "Houston West Airport" },
  { code: "JCI", name: "Kansas City New Century Aircenter Airport" },
  { code: "IYK", name: "Inyokern Airport" },
  { code: "SQA", name: "Santa Ynez Airport" },
  { code: "JAC", name: "Jackson Hole Airport" },
  { code: "JAN", name: "Jackson Evers International Airport" },
  { code: "JAX", name: "Jacksonville International Airport" },
  { code: "JBR", name: "Jonesboro Municipal Airport" },
  { code: "JEF", name: "Jefferson City Memorial Airport" },
  { code: "JFK", name: "New York John F. Kennedy International Airport" },
  { code: "JHW", name: "Jamestown Chautauqua County Airport" },
  { code: "GUF", name: "Gulf Shores Jack Edwards Airport" },
  { code: "JLN", name: "Joplin Regional Airport" },
  { code: "JMS", name: "Jamestown Regional Airport" },
  { code: "JOT", name: "Joliet Regional Airport" },
  { code: "USA", name: "Concord Regional Airport" },
  { code: "JRB", name: "New York Downtown Manhattan Heliport" },
  { code: "JST", name: "Johnstown Cambria County Airport" },
  { code: "JVL", name: "Janesville Southern Wisconsin Regional Airport" },
  { code: "JXN", name: "Jackson County Airport" },
  { code: "JZI", name: "Charleston Executive Airport" },
  { code: "KLS", name: "Kelso Southwest Washington Regional Airport" },
  { code: "LAA", name: "Lamar Municipal Airport" },
  { code: "LAF", name: "Lafayette Purdue University Airport" },
  { code: "LAL", name: "Lakeland Linder Regional Airport" },
  { code: "LAN", name: "Lansing Capital Region International Airport" },
  { code: "LAR", name: "Laramie Regional Airport" },
  { code: "LAS", name: "Las Vegas McCarran International Airport" },
  { code: "LAW", name: "Lawton Fort Sill Regional Airport" },
  { code: "LAX", name: "Los Angeles International Airport" },
  { code: "LBB", name: "Lubbock Preston Smith International Airport" },
  { code: "LBE", name: "Latrobe Arnold Palmer Regional Airport" },
  { code: "LBF", name: "North Platte Regional Airport" },
  { code: "LBL", name: "Liberal Mid-America Regional Airport" },
  { code: "LBT", name: "Lumberton Municipal Airport" },
  { code: "LJN", name: "Lake Jackson Texas Gulf Coast Regional Airport" },
  { code: "LCH", name: "Lake Charles Regional Airport" },
  { code: "LCI", name: "Laconia Municipal Airport" },
  { code: "LCK", name: "Columbus Rickenbacker International Airport" },
  { code: "CLM", name: "Port Angeles Fairchild International Airport" },
  { code: "LCQ", name: "Lake City Gateway Airport" },
  { code: "LDJ", name: "Linden Airport" },
  { code: "LEB", name: "Lebanon Municipal Airport" },
  { code: "LEE", name: "Leesburg International Airport" },
  { code: "LEW", name: "Auburn Airport" },
  { code: "LEX", name: "Lexington Blue Grass Airport" },
  { code: "LFK", name: "Lufkin Angelina County Airport" },
  { code: "LFT", name: "Lafayette Regional Airport" },
  { code: "LGA", name: "New York LaGuardia Airport" },
  { code: "LGB", name: "Long Beach Airport" },
  { code: "LGC", name: "LaGrange Callaway Airport" },
  { code: "LGD", name: "La Grande Union County Airport" },
  { code: "LGU", name: "Logan Cache Airport" },
  { code: "LFN", name: "Louisburg Triangle North Executive Airport" },
  { code: "LIC", name: "Limon Municipal Airport" },
  { code: "LIT", name: "Little Rock Clinton National Airport" },
  { code: "LMT", name: "Klamath Falls Airport" },
  { code: "LNA", name: "Palm Beach County Park Airport" },
  { code: "LNK", name: "Lincoln Airport" },
  { code: "LNN", name: "Willoughby Lost Nation Municipal Airport" },
  { code: "LNS", name: "Lancaster Airport" },
  { code: "BBX", name: "Philadelphia Wings Field Airport" },
  { code: "LOT", name: "Romeoville Lewis University Airport" },
  { code: "LOU", name: "Louisville Bowman Field Airport" },
  { code: "LOZ", name: "London Corbin Airport" },
  { code: "LQK", name: "Pickens County Airport" },
  { code: "LRD", name: "Laredo International Airport" },
  { code: "LRU", name: "Las Cruces International Airport" },
  { code: "LSE", name: "La Crosse Regional Airport" },
  { code: "LUK", name: "Cincinnati Municipal Lunken Airport" },
  { code: "LUL", name: "Laurel Hesler-Noble Field Airport" },
  { code: "LVK", name: "Livermore Municipal Airport" },
  { code: "LVS", name: "Las Vegas Municipal Airport" },
  { code: "LWB", name: "Lewisburg Greenbrier Valley Airport" },
  { code: "LWC", name: "Lawrence Municipal Airport" },
  { code: "LWM", name: "Lawrence Municipal Airport" },
  { code: "LWS", name: "Lewiston Nez Perce County Airport" },
  { code: "LWT", name: "Lewistown Municipal Airport" },
  { code: "QQL", name: "Lee's Summit Municipal Airport" },
  { code: "LYH", name: "Lynchburg Regional Airport" },
  { code: "LZU", name: "Lawrenceville Gwinnett County Airport" },
  { code: "MAE", name: "Madera Municipal Airport" },
  { code: "MAF", name: "Midland International Airport" },
  { code: "MBL", name: "Manistee County Blacker Airport" },
  { code: "DXE", name: "Madison Bruce Campbell Field Airport" },
  { code: "MBS", name: "Saginaw MBS International Airport" },
  { code: "MBY", name: "Moberly Airport" },
  { code: "MCC", name: "Sacramento McClellan Airport" },
  { code: "MCE", name: "Merced Municipal Airport" },
  { code: "MCI", name: "Kansas City International Airport" },
  { code: "MCK", name: "McCook Ben Nelson Regional Airport" },
  { code: "MCN", name: "Macon Middle Georgia Regional Airport" },
  { code: "MCO", name: "Orlando International Airport" },
  { code: "MCW", name: "Mason City Municipal Airport" },
  { code: "MDD", name: "Midland Airpark" },
  { code: "MDH", name: "Carbondale Murphysboro Southern Illinois Airport" },
  { code: "MDT", name: "Harrisburg International Airport" },
  { code: "MDW", name: "Chicago Midway International Airport" },
  { code: "MEI", name: "Meridian Regional Airport" },
  { code: "MEM", name: "Memphis International Airport" },
  { code: "MER", name: "Atwater Castle Airport" },
  { code: "MEV", name: "Minden Tahoe Airport" },
  { code: "QQM", name: "Mena Intermountain Municipal Airport" },
  { code: "MFD", name: "Mansfield Lahm Regional Airport" },
  { code: "MFE", name: "McAllen Miller International Airport" },
  { code: "MFR", name: "Medford Rogue Valley International Airport" },
  { code: "MGM", name: "Montgomery Regional Airport" },
  { code: "MGR", name: "Moultrie Municipal Airport" },
  { code: "MGW", name: "Morgantown Municipal Airport" },
  { code: "MGY", name: "Dayton Wright Brothers Airport" },
  { code: "MHE", name: "Mitchell Municipal Airport" },
  { code: "MHK", name: "Manhattan Regional Airport" },
  { code: "MHR", name: "Sacramento Mather Airport" },
  { code: "MHT", name: "Manchester Boston Regional Airport" },
  { code: "MHV", name: "Mojave Air and Space Port" },
  { code: "MIA", name: "Miami International Airport" },
  { code: "MIC", name: "Minneapolis Crystal Airport" },
  { code: "MIE", name: "Muncie Delaware County Airport" },
  { code: "MIO", name: "Miami Municipal Airport" },
  { code: "MIV", name: "Millville Executive Airport" },
  { code: "MJQ", name: "Jackson Municipal Airport" },
  { code: "MKC", name: "Kansas City Downtown Airport" },
  { code: "MKE", name: "Milwaukee General Mitchell International Airport" },
  { code: "MKG", name: "Muskegon County Airport" },
  { code: "MKL", name: "Jackson McKellar Sipes Regional Airport" },
  { code: "MKT", name: "Mankato Regional Airport" },
  { code: "MRK", name: "Marco Island Airport" },
  { code: "MLB", name: "Melbourne International Airport" },
  { code: "MLC", name: "Mc Alester Regional" },
  { code: "MIQ", name: "Omaha Millard Airport" },
  { code: "MLI", name: "Moline Quad City International Airport" },
  { code: "MLS", name: "Miles City Municipal Airport" },
  { code: "MLU", name: "Monroe Regional Airport" },
  { code: "MMH", name: "Mammoth Yosemite Airport" },
  { code: "MMI", name: "Athens McMinn County Airport" },
  { code: "MML", name: "Marshall Southwest Minnesota Regional Airport" },
  { code: "MMU", name: "Morristown Municipal Airport" },
  { code: "MMV", name: "McMinnville Municipal Airport" },
  { code: "MOB", name: "Mobile Regional Airport" },
  { code: "MOD", name: "Modesto City County Airport" },
  { code: "MOR", name: "Morristown Regional Airport" },
  { code: "MOT", name: "Minot International Airport" },
  { code: "MPO", name: "Mount Pocono Municipal Airport" },
  { code: "MEO", name: "Manteo Dare County Regional Airport" },
  { code: "QQQ", name: "Indianapolis Regional Airport" },
  { code: "CTH", name: "Coatesville Chester County Area Airport" },
  { code: "MQY", name: "Smyrna Airport" },
  { code: "MRB", name: "Martinsburg Eastern WV Regional Airport" },
  { code: "MRF", name: "Marfa Municipal Airport" },
  { code: "QQJ", name: "Mineral Point Iowa County Airport" },
  { code: "MRY", name: "Monterey Regional Airport" },
  { code: "MSL", name: "Muscle Shoals Northwest Alabama Regional Airport" },
  { code: "MSN", name: "Madison Dane County Regional Airport" },
  { code: "MSO", name: "Missoula International Airport" },
  { code: "MSP", name: "Minneapolis Saint Paul International Airport" },
  { code: "MSS", name: "Massena International Airport" },
  { code: "MSY", name: "New Orleans Louis Armstrong International Airport" },
  { code: "MTH", name: "Marathon Florida Keys Airport" },
  { code: "MTJ", name: "Montrose Regional Airport" },
  { code: "MTN", name: "Baltimore Martin State Airport" },
  { code: "MTW", name: "Manitowoc County Airport" },
  { code: "MUT", name: "Muscatine Municipal Airport" },
  { code: "MVL", name: "Morrisville Stowe State Airport" },
  { code: "MVY", name: "Martha's Vineyard Airport" },
  { code: "MWA", name: "Marion Williamson County Regional Airport" },
  { code: "MWC", name: "Milwaukee Lawrence J. Timmerman Airport" },
  { code: "MWH", name: "Moses Lake Grant County International Airport" },
  { code: "MWL", name: "Mineral Wells Airport" },
  { code: "MWO", name: "Middletown Regional Airport" },
  { code: "MYF", name: "San Diego Montgomery Field Airport" },
  { code: "MYR", name: "Myrtle Beach International Airport" },
  { code: "MYV", name: "Marysville Yuba County Airport" },
  { code: "MZJ", name: "Marana Pinal Airport" },
  { code: "NEW", name: "New Orleans Lakefront Airport" },
  { code: "NQA", name: "Millington Regional Jetport" },
  { code: "NUQ", name: "Mountain View Moffett Federal Airfield" },
  { code: "OAJ", name: "Jacksonville Albert J. Ellis Airport" },
  { code: "OAK", name: "Oakland International Airport" },
  { code: "OAR", name: "Marina Municipal Airport" },
  { code: "OBE", name: "Okeechobee County Airport" },
  { code: "OCF", name: "Ocala International Airport" },
  { code: "OFK", name: "Norfolk Regional Airport" },
  { code: "OGB", name: "Orangeburg Municipal Airport" },
  { code: "OGD", name: "Ogden Hinckley Airport" },
  { code: "OGS", name: "Ogdensburg International Airport" },
  { code: "OJC", name: "Kansas City Johnson County Executive Airport" },
  { code: "OKC", name: "Oklahoma City Will Rogers World Airport" },
  { code: "WGO", name: "Winchester Regional Airport" },
  { code: "OLF", name: "Wolf Point L. M. Clayton Airport" },
  { code: "OLM", name: "Olympia Regional Airport" },
  { code: "OLS", name: "Nogales International Airport" },
  { code: "OLU", name: "Columbus Municipal Airport" },
  { code: "OLV", name: "Olive Branch Airport" },
  { code: "OMA", name: "Omaha Eppley Airfield" },
  { code: "OMK", name: "Omak Municipal Airport" },
  { code: "ONO", name: "Ontario Municipal Airport" },
  { code: "ONP", name: "Newport Municipal Airport" },
  { code: "ONT", name: "Ontario International Airport" },
  { code: "OPF", name: "Miami Opa Locka Executive Airport" },
  { code: "ORD", name: "Chicago O'Hare International Airport" },
  { code: "ORF", name: "Norfolk International Airport" },
  { code: "ORH", name: "Worcester Regional Airport" },
  { code: "ORL", name: "Orlando Executive Airport" },
  { code: "ESD", name: "Eastsound Orcas Island Airport" },
  { code: "OSC", name: "Oscoda Wurtsmith Airport" },
  { code: "OSH", name: "Oshkosh Wittman Regional Airport" },
  { code: "OSU", name: "Columbus Ohio State University Airport" },
  { code: "OTH", name: "North Bend Southwest Oregon Regional Airport" },
  { code: "OTM", name: "Ottumwa Regional Airport" },
  { code: "OUN", name: "Norman University of Oklahoma Westheimer Airport" },
  { code: "OWB", name: "Owensboro Daviess County Regional Airport" },
  { code: "OWD", name: "Norwood Memorial Airport" },
  { code: "OCE", name: "Ocean City Municipal Airport" },
  { code: "OXC", name: "Oxford Waterbury Airport" },
  { code: "OXR", name: "Oxnard Airport" },
  { code: "PAE", name: "Everett Paine Field" },
  { code: "PAH", name: "Paducah Barkley Regional Airport" },
  { code: "PAO", name: "Palo Alto Airport" },
  { code: "PBG", name: "Plattsburgh International Airport" },
  { code: "PBI", name: "West Palm Beach International Airport" },
  { code: "PBZ", name: "Plettenberg Bay Airport" },
  { code: "PDK", name: "Atlanta DeKalb Peachtree Airport" },
  { code: "PDT", name: "Pendleton Eastern Oregon Regional Airport" },
  { code: "PDX", name: "Portland International Airport" },
  { code: "PEQ", name: "Pecos Municipal Airport" },
  { code: "PGA", name: "Page Municipal Airport" },
  { code: "PGD", name: "Punta Gorda Airport" },
  { code: "PGV", name: "Greenville Pitt Airport" },
  { code: "PHF", name: "Newport News Williamsburg International Airport" },
  { code: "PHL", name: "Philadelphia International Airport" },
  { code: "PHN", name: "Port Huron St. Clair County International Airport" },
  { code: "PHX", name: "Phoenix Sky Harbor International Airport" },
  { code: "PIA", name: "Peoria International Airport" },
  { code: "PIB", name: "Hattiesburg Laurel Regional Airport" },
  { code: "PIE", name: "St. Petersburg Clearwater International Airport" },
  { code: "PIH", name: "Pocatello Regional Airport" },
  { code: "PIR", name: "Pierre Regional Airport" },
  { code: "PIT", name: "Pittsburgh International Airport" },
  { code: "PKB", name: "Parkersburg Mid-Ohio Valley Regional Airport" },
  { code: "PKD", name: "Park Rapids Airport" },
  { code: "PLN", name: "Pellston Regional Airport" },
  { code: "PMD", name: "Palmdale Regional Airport" },
  { code: "PPM", name: "Pompano Beach Airport" },
  { code: "PNC", name: "Ponca City Regional Airport" },
  { code: "PNE", name: "Philadelphia Northeast Airport" },
  { code: "PNS", name: "Pensacola International Airport" },
  { code: "POC", name: "La Verne Brackett Field Airport" },
  { code: "POF", name: "Poplar Bluff Municipal Airport" },
  { code: "POU", name: "Poughkeepsie Hudson Valley Regional Airport" },
  { code: "PQI", name: "Presque Isle Northern Maine Airport" },
  { code: "PRB", name: "Paso Robles Municipal Airport" },
  { code: "PRC", name: "Prescott Municipal Airport" },
  { code: "PSC", name: "Pasco Tri-Cities Airport" },
  { code: "PSF", name: "Pittsfield Airport" },
  { code: "PSK", name: "Dublin New River Valley Airport" },
  { code: "PSM", name: "Portsmouth International Airport" },
  { code: "PSN", name: "Palestine Municipal Airport" },
  { code: "PSP", name: "Palm Springs International Airport" },
  { code: "PTK", name: "Oakland County International Airport" },
  { code: "PTS", name: "Pittsburg Atkinson Municipal Airport" },
  { code: "PTT", name: "Pratt Regional Airport" },
  { code: "PTV", name: "Porterville Municipal Airport" },
  { code: "PTW", name: "Pottstown Heritage Field Airport" },
  { code: "PUB", name: "Pueblo Memorial Airport" },
  { code: "PUC", name: "Price Carbon County Regional Airport" },
  { code: "PUW", name: "Pullman Moscow Regional Airport" },
  { code: "PVC", name: "Provincetown Municipal Airport" },
  { code: "PVD", name: "Providence Theodore Francis Green Airport" },
  { code: "PVU", name: "Provo Municipal Airport" },
  { code: "PWA", name: "Oklahoma City Wiley Post Airport" },
  { code: "PWK", name: "Chicago Executive Airport" },
  { code: "PWM", name: "Portland International Jetport" },
  { code: "PWT", name: "Bremerton National Airport" },
  { code: "PYM", name: "Plymouth Municipal Airport" },
  { code: "RAC", name: "Racine Batten International Airport" },
  { code: "RAL", name: "Riverside Municipal Airport" },
  { code: "RAP", name: "Rapid City Regional Airport" },
  { code: "RBD", name: "Dallas Executive Airport" },
  { code: "RBG", name: "Roseburg Regional Airport" },
  { code: "RBL", name: "Red Bluff Municipal Airport" },
  { code: "RBW", name: "Walterboro Municipal Airport" },
  { code: "RDD", name: "Redding Municipal Airport" },
  { code: "RDG", name: "Reading Regional Airport" },
  { code: "RDM", name: "Redmond Municipal Airport" },
  { code: "RDU", name: "Raleigh-Durham International Airport" },
  { code: "RFD", name: "Chicago Rockford International Airport" },
  { code: "RHI", name: "Rhinelander Oneida County Airport" },
  { code: "RHV", name: "San Jose Reid Hillview Airport" },
  { code: "RIC", name: "Richmond Airport" },
  { code: "RIL", name: "Garfield County Regional Airport" },
  { code: "RIW", name: "Riverton Regional Airport" },
  { code: "RKD", name: "Rockland Knox County Regional Airport" },
  { code: "RKP", name: "Rockport Aransas County Airport" },
  { code: "RKS", name: "Rock Springs Sweetwater County Airport" },
  { code: "RLD", name: "Richland Airport" },
  { code: "RME", name: "Rome Griffiss International Airport" },
  { code: "RMG", name: "Rome Richard B. Russell Airport" },
  { code: "RNO", name: "Reno Tahoe International Airport" },
  { code: "RNT", name: "Renton Municipal Airport" },
  { code: "ROA", name: "Roanoke Regional Airport" },
  { code: "ROC", name: "Rochester International Airport" },
  { code: "ROG", name: "Rogers Municipal Airport" },
  { code: "ROW", name: "Roswell International Air Center Airport" },
  { code: "RIE", name: "Rice Lake Regional Airport" },
  { code: "RSN", name: "Ruston Airport" },
  { code: "RST", name: "Rochester International Airport" },
  { code: "RSW", name: "Southwest Florida International Airport" },
  { code: "SRW", name: "Salisbury Rowan County Airport" },
  { code: "RUT", name: "Rutland Southern Vermont Regional Airport" },
  { code: "RVS", name: "Tulsa Richard Lloyd Jones Jr. Airport" },
  { code: "RWI", name: "Rocky Mount Wilson Airport" },
  { code: "RWL", name: "Rawlins Municipal Airport" },
  { code: "QQR", name: "Atlanta Cobb County Airport" },
  { code: "SAC", name: "Sacramento Executive Airport" },
  { code: "SAD", name: "Safford Regional Airport" },
  { code: "SAF", name: "Santa Fe Municipal Airport" },
  { code: "SAN", name: "San Diego International Airport" },
  { code: "SAT", name: "San Antonio International Airport" },
  { code: "SAV", name: "Savannah Hilton Head International Airport" },
  { code: "MQT", name: "Marquette Sawyer International Airport" },
  { code: "SBA", name: "Santa Barbara Municipal Airport" },
  { code: "SBD", name: "San Bernardino International Airport" },
  { code: "SBM", name: "Sheboygan County Memorial Airport" },
  { code: "SBN", name: "South Bend Airport" },
  { code: "SBP", name: "San Luis Obispo County Regional Airport" },
  { code: "SBS", name: "Steamboat Springs Airport" },
  { code: "SBY", name: "Salisbury Ocean City Wicomico Regional Airport" },
  { code: "SCH", name: "Schenectady County Airport" },
  { code: "SCK", name: "Stockton Metropolitan Airport" },
  { code: "SDF", name: "Louisville International Airport" },
  { code: "SCF", name: "Scottsdale Airport" },
  { code: "SDM", name: "San Diego Brown Field Municipal Airport" },
  { code: "SDY", name: "Sidney Richland Municipal Airport" },
  { code: "SEA", name: "Seattle Tacoma International Airport" },
  { code: "SEE", name: "San Diego Gillespie Field Airport" },
  { code: "SEF", name: "Sebring Regional Airport" },
  { code: "SEG", name: "Selinsgrove Penn Valley Airport" },
  { code: "SDX", name: "Sedona Airport" },
  { code: "SFB", name: "Orlando Sanford International Airport" },
  { code: "SFF", name: "Spokane Felts Field" },
  { code: "SFM", name: "Sanford Seacoast Regional Airport" },
  { code: "SFO", name: "San Francisco International Airport" },
  { code: "SFZ", name: "Pawtucket North Central State" },
  { code: "SGF", name: "Springfield Branson National Airport" },
  { code: "SGH", name: "Springfield Beckley Municipal Airport" },
  { code: "UST", name: "St. Augustine Northeast Florida Regional Airport" },
  { code: "SGR", name: "Sugar Land Regional Airport" },
  { code: "SGU", name: "St. George Regional Airport" },
  { code: "SHD", name: "Staunton Shenandoah Valley Regional Airport" },
  { code: "SHR", name: "Sheridan County Airport" },
  { code: "SHV", name: "Shreveport Regional Airport" },
  { code: "SJC", name: "San Jose International Airport" },
  { code: "SJT", name: "San Angelo Regional Airport" },
  { code: "TSM", name: "Taos Regional Airport" },
  { code: "SLC", name: "Salt Lake City International Airport" },
  { code: "SLE", name: "Salem Municipal Airport" },
  { code: "SLK", name: "Saranac Lake Adirondack Regional Airport" },
  { code: "SLN", name: "Salina Regional Airport" },
  { code: "SMF", name: "Sacramento International Airport" },
  { code: "SMO", name: "Santa Monica Airport" },
  { code: "SUM", name: "Sumter Airport" },
  { code: "SMX", name: "Santa Maria Public Airport" },
  { code: "SNA", name: "Orange County John Wayne Airport" },
  { code: "SNS", name: "Salinas Municipal Airport" },
  { code: "SNY", name: "Sidney Municipal Airport" },
  { code: "SOP", name: "Southern Pines Moore County Airport" },
  { code: "SOW", name: "Show Low Regional Airport" },
  { code: "SPA", name: "Spartanburg Downtown Memorial Airport" },
  { code: "SPF", name: "Spearfish Black Hills Airport" },
  { code: "SPG", name: "St. Petersburg Albert Whitted Airport" },
  { code: "SPI", name: "Springfield Abraham Lincoln Capital Airport" },
  { code: "SPS", name: "Wichita Falls Municipal Airport" },
  { code: "SPW", name: "Spencer Municipal Airport" },
  { code: "SQI", name: "Sterling Whiteside County Airport" },
  { code: "SQL", name: "San Carlos Airport" },
  { code: "SRC", name: "Searcy Municipal Airport" },
  { code: "SRQ", name: "Sarasota-Bradenton International Airport" },
  { code: "RUI", name: "Ruidoso Sierra Blanca Regional Airport" },
  { code: "SSF", name: "San Antonio Stinson Municipal Airport" },
  { code: "SSI", name: "Brunswick Malcolm McKinnon Airport" },
  { code: "STC", name: "St. Cloud Regional Airport" },
  { code: "STE", name: "Stevens Point Municipal Airport" },
  { code: "STJ", name: "Saint Joseph Rosecrans Memorial Airport" },
  { code: "STL", name: "St. Louis Lambert International Airport" },
  { code: "STP", name: "St. Paul Downtown Airport" },
  { code: "STS", name: "Santa Rosa Sonoma County Airport" },
  { code: "SUA", name: "Stuart Witham Field Airport" },
  { code: "SUE", name: "Sturgeon Bay Door County Cherryland Airport" },
  { code: "SUN", name: "Hailey Friedman Memorial Airport" },
  { code: "SUS", name: "Spirit of St. Louis Airport" },
  { code: "SUW", name: "Superior Richard I Bong Airport" },
  { code: "SUX", name: "Sioux Gateway Airport" },
  { code: "SVC", name: "Silver City Grant County Airport" },
  { code: "SVE", name: "Susanville Municipal Airport" },
  { code: "SVH", name: "Statesville Regional Airport" },
  { code: "SWF", name: "New York Stewart International Airport" },
  { code: "SWO", name: "Stillwater Regional Airport" },
  { code: "SYR", name: "Syracuse Hancock International Airport" },
  { code: "SZP", name: "Santa Paula Airport" },
  { code: "TBN", name: "Waynesville St. Robert Regional Airport" },
  { code: "TBR", name: "Statesboro Bulloch County Airport" },
  { code: "TCL", name: "Tuscaloosa Regional Airport" },
  { code: "TCS", name: "Truth or Consequences Municipal Airport" },
  { code: "TDW", name: "Amarillo Tradewind Airport" },
  { code: "TDZ", name: "Toledo Executive Airport" },
  { code: "TEB", name: "Teterboro Airport" },
  { code: "TEX", name: "Telluride Regional Airport" },
  { code: "THA", name: "Tullahoma Regional Airport" },
  { code: "THV", name: "York Airport" },
  { code: "TIW", name: "Tacoma Narrows Airport" },
  { code: "TIX", name: "Titusville Space Coast Regional Airport" },
  { code: "QQT", name: "McKinney National Airport" },
  { code: "TLH", name: "Tallahassee International Airport" },
  { code: "TMA", name: "Tifton Henry Tift Myers Airport" },
  { code: "TMB", name: "Miami Executive Airport" },
  { code: "OTK", name: "Tillamook Airport" },
  { code: "TNU", name: "Newton Municipal Airport" },
  { code: "XSD", name: "Tonopah Test Range Airport" },
  { code: "TOA", name: "Torrance Zamperini Field Airport" },
  { code: "TOC", name: "Toccoa Airport" },
  { code: "TOI", name: "Troy Municipal Airport" },
  { code: "TOL", name: "Toledo Express Airport" },
  { code: "TOP", name: "Topeka Philip Billard Municipal Airport" },
  { code: "TPA", name: "Tampa International Airport" },
  { code: "TPF", name: "Tampa Peter O'Knight Airport" },
  { code: "TPL", name: "Temple Draughon-Miller Airport" },
  { code: "TRI", name: "Blountville Tri-Cities Regional Airport" },
  { code: "TKF", name: "Truckee Tahoe Airport" },
  { code: "TRM", name: "Thermal Jacqueline Cochran Regional Airport" },
  { code: "TTD", name: "Portland Troutdale Airport" },
  { code: "TTN", name: "Trenton Mercer Airport" },
  { code: "QQS", name: "Shuttle Landing Facility" },
  { code: "TUL", name: "Tulsa International Airport" },
  { code: "TUP", name: "Tupelo Regional Airport" },
  { code: "TUS", name: "Tucson International Airport" },
  { code: "TVC", name: "Traverse City Cherry Capital Airport" },
  { code: "TVF", name: "Thief River Falls Regional Airport" },
  { code: "TVI", name: "Thomasville Regional Airport" },
  { code: "TVL", name: "South Lake Tahoe Airport" },
  { code: "TWF", name: "Twin Falls Magic Valley Regional Airport" },
  { code: "TXK", name: "Texarkana Regional Airport" },
  { code: "TYQ", name: "Indianapolis Executive Airport" },
  { code: "TYR", name: "Tyler Pounds Regional Airport" },
  { code: "TYS", name: "Knoxville McGhee Tyson Airport" },
  { code: "UDD", name: "Palm Springs Bermuda Dunes Airport" },
  { code: "UES", name: "Waukesha County Airport" },
  { code: "UGN", name: "Chicago Waukegan National Airport" },
  { code: "UIN", name: "Quincy Municipal Airport" },
  { code: "IKB", name: "North Wilkesboro Wilkes County Airport" },
  { code: "UKI", name: "Ukiah Municipal Airport" },
  { code: "ATO", name: "Athens Gordon K. Bush Airport" },
  { code: "SCE", name: "State College University Park Airport" },
  { code: "UOX", name: "Oxford University Airport" },
  { code: "UTM", name: "Tunica Municipal Airport" },
  { code: "HTV", name: "Huntsville Regional Airport" },
  { code: "NPT", name: "Newport State Airport" },
  { code: "UVA", name: "Uvalde Garner Field Airport" },
  { code: "RKH", name: "Rock Hill York County Airport" },
  { code: "LLY", name: "Mount Holly South Jersey Regional Airport" },
  { code: "VCB", name: "Vacaville Nut Tree Airport" },
  { code: "VCT", name: "Victoria Regional Airport" },
  { code: "VCV", name: "Victorville Southern California Logistics Airport" },
  { code: "VDF", name: "Tampa Executive Airport" },
  { code: "VEL", name: "Vernal Airport" },
  { code: "VGT", name: "North Las Vegas Airport" },
  { code: "VIS", name: "Visalia Municipal Airport" },
  { code: "VJI", name: "Abingdon Virginia Highlands Airport" },
  { code: "VLD", name: "Valdosta Regional Airport" },
  { code: "VNC", name: "Venice Municipal Airport" },
  { code: "VNY", name: "Van Nuys Airport" },
  { code: "QQV", name: "Cartersville Airport" },
  { code: "VPS", name: "Destin Fort Walton Beach International Airport" },
  { code: "VPZ", name: "Valparaiso Porter County Regional Airport" },
  { code: "VQQ", name: "Jacksonville Cecil Airport" },
  { code: "VRB", name: "Vero Beach Municipal Airport" },
  { code: "VTN", name: "Valentine Miller Field Airport" },
  { code: "VYS", name: "Peru Illinois Valley Regional Airport" },
  { code: "WDG", name: "Enid Woodring Regional Airport" },
  { code: "WDR", name: "Winder Barrow County Airport" },
  { code: "WHP", name: "Los Angeles Whiteman Airport" },
  { code: "WJF", name: "Lancaster General William J Fox Airport" },
  { code: "WLW", name: "Willows Glenn County Airport" },
  { code: "WST", name: "Westerly State Airport" },
  { code: "WVI", name: "Watsonville Municipal Airport" },
  { code: "WVL", name: "Waterville Robert LaFleur Airport" },
  { code: "WWD", name: "Cape May County" },
  { code: "WWR", name: "West Woodward Airport" },
  { code: "WYS", name: "West Yellowstone Airport" },
  { code: "XNA", name: "Bentonville Northwest Arkansas Regional Airport" },
  { code: "YIP", name: "Detroit Willow Run Airport" },
  { code: "YKM", name: "Yakima Airport" },
  { code: "YNG", name: "Youngstown Warren Regional Airport" },
  { code: "YUM", name: "Yuma International Airport" },
  { code: "ZPH", name: "Zephyrhills Municipal Airport" },
  { code: "ZZV", name: "Zanesville Municipal Airport" },
  { code: "TIA", name: "Tirana International Airport" },
  { code: "BOJ", name: "Burgas Airport" },
  { code: "PDV", name: "Plovdiv Airport" },
  { code: "SOF", name: "Sofia Airport" },
  { code: "VAR", name: "Varna Airport" },
  { code: "ECN", name: "Tymvou Ercan International Airport" },
  { code: "LCA", name: "Larnaca International Airport" },
  { code: "PFO", name: "Paphos International Airport" },
  { code: "DBV", name: "Dubrovnik Airport" },
  { code: "LSZ", name: "Losinj Airport" },
  { code: "OSI", name: "Osijek Airport" },
  { code: "PUY", name: "Pula Airport" },
  { code: "RJK", name: "Rijeka Airport" },
  { code: "BWK", name: "Bol Brac Airport" },
  { code: "SPU", name: "Split Airport" },
  { code: "ZAG", name: "Zagreb Franjo Tudman Airport" },
  { code: "ZAD", name: "Zadar Airport" },
  { code: "ABC", name: "Albacete Airport" },
  { code: "ALC", name: "Alicante Airport" },
  { code: "LEI", name: "Almeria Airport" },
  { code: "OVD", name: "Asturias Airport" },
  { code: "ODB", name: "Cordoba Airport" },
  { code: "BIO", name: "Bilbao Airport" },
  { code: "RGS", name: "Burgos Airport" },
  { code: "BCN", name: "Barcelona El Prat Airport" },
  { code: "BJZ", name: "Badajoz Airport" },
  { code: "CDT", name: "Castellon Costa Azahar Airport" },
  { code: "LCG", name: "A Coruna Airport" },
  { code: "ILD", name: "Lleida Alguaire Airport" },
  { code: "GRO", name: "Girona Costa Brava Airport" },
  { code: "GRX", name: "Granada Federico Garcia Lorca Airport" },
  { code: "HSK", name: "Huesca Pirineos Airport" },
  { code: "IBZ", name: "Ibiza Airport" },
  { code: "XRY", name: "Jerez Airport" },
  { code: "LEN", name: "Leon Airport" },
  { code: "MAD", name: "Madrid Barajas Airport" },
  { code: "AGP", name: "Malaga Costa Del Sol Airport" },
  { code: "MAH", name: "Mahon Menorca Airport" },
  { code: "RMU", name: "Murcia Corvera International Airport" },
  { code: "PMI", name: "Palma de Mallorca Airport" },
  { code: "PNA", name: "Pamplona Airport" },
  { code: "RJL", name: "Logrono Agoncillo Airport" },
  { code: "REU", name: "Reus Airport" },
  { code: "SLM", name: "Salamanca Airport" },
  { code: "EAS", name: "San Sebastian Airport" },
  { code: "SCQ", name: "Santiago de Compostela Airport" },
  { code: "LEU", name: "Seo de Urgel Airport" },
  { code: "TEV", name: "Teruel Airport" },
  { code: "TOJ", name: "Madrid Torrejon Airport" },
  { code: "VLC", name: "Valencia Airport" },
  { code: "VLL", name: "Valladolid Airport" },
  { code: "VIT", name: "Vitoria Airport" },
  { code: "VGO", name: "Vigo-Peinador Airport" },
  { code: "SDR", name: "Santander Airport" },
  { code: "ZAZ", name: "Zaragoza Airport" },
  { code: "SVQ", name: "Seville San Pablo Airport" },
  { code: "CQF", name: "Calais Dunkerque Airport" },
  { code: "BYF", name: "Albert Picardie Airport" },
  { code: "LTQ", name: "Le Touquet Cote d'Opale Airport" },
  { code: "AGF", name: "Agen La Garenne Airport" },
  { code: "BOD", name: "Bordeaux Merignac Airport" },
  { code: "EGC", name: "Bergerac Dordogne Perigord Airport" },
  { code: "LRH", name: "La Rochelle Ile de Re Airport" },
  { code: "PIS", name: "Poitiers – Biard Airport" },
  { code: "LIG", name: "Limoges Bellegarde Airport" },
  { code: "NIT", name: "Niort Souche Airport" },
  { code: "TLS", name: "Toulouse Blagnac Airport" },
  { code: "PUF", name: "Pau Pyrenees Airport" },
  { code: "LDE", name: "Tarbes-Lourdes-Pyrenees Airport" },
  { code: "ANG", name: "Angouleme Cognac International Airport" },
  { code: "PGX", name: "Perigueux Bassillac Airport" },
  { code: "BIQ", name: "Biarritz Pays Basque Airport" },
  { code: "XAC", name: "Arcachon La Teste-de-Buch Airport" },
  { code: "LBI", name: "Albi Le Sequestre Airport" },
  { code: "DCM", name: "Castres Mazamet Airport" },
  { code: "RDZ", name: "Rodez Marcillac Airport" },
  { code: "CMR", name: "Colmar Houssen Airport" },
  { code: "DLE", name: "Dole Jura Airport" },
  { code: "LPY", name: "Le Puy Loudes Airport" },
  { code: "XMU", name: "Moulins Montbeugny Airport" },
  { code: "ETZ", name: "Metz-Nancy-Lorraine Airport" },
  { code: "ANE", name: "Angers Loire Airport" },
  { code: "BIA", name: "Bastia Poretta Airport" },
  { code: "CLY", name: "Calvi Sainte-Catherine Airport" },
  { code: "FSC", name: "Figari Sud-Corse Airport" },
  { code: "AJA", name: "Ajaccio Napoleon Bonaparte Airport" },
  { code: "AUF", name: "Auxerre Branches Airport" },
  { code: "CMF", name: "Chambery Airport" },
  { code: "CFE", name: "Clermont-Ferrand Auvergne Airport" },
  { code: "BOU", name: "Bourges Airport" },
  { code: "CVF", name: "Courchevel Altiport" },
  { code: "LYS", name: "Lyon Saint Exupery Airport" },
  { code: "SYT", name: "St-Yan Airport" },
  { code: "NCY", name: "Annecy Mont Blanc Airport" },
  { code: "GNB", name: "Grenoble Isere Airport" },
  { code: "VAF", name: "Valence Chabeuil Airport" },
  { code: "AUR", name: "Aurillac Tronquieres Airport" },
  { code: "CHR", name: "Chateauroux Centre Marcel Dassault Airport" },
  { code: "LYN", name: "Lyon Bron Airport" },
  { code: "QXB", name: "Aix-en-Provence Airport" },
  { code: "CEQ", name: "Cannes Mandelieu Airport" },
  { code: "EBU", name: "Saint Etienne Boutheon Airport" },
  { code: "CCF", name: "Carcassonne Airport" },
  { code: "MRS", name: "Marseille Provence Airport" },
  { code: "NCE", name: "Nice Cote d'Azur Airport" },
  { code: "PGF", name: "Perpignan Rivesaltes Airport" },
  { code: "CTT", name: "Le Castellet Airport" },
  { code: "MPL", name: "Montpellier Mediterranee Airport" },
  { code: "BZR", name: "Beziers Cap d'Agde Airport" },
  { code: "AVN", name: "Avignon Caumont Airport" },
  { code: "MEN", name: "Mende Brenoux Airport" },
  { code: "BVA", name: "Paris Beauvais-Tille Airport" },
  { code: "LEH", name: "Le Havre Octeville Airport" },
  { code: "XCR", name: "Paris Vatry Chalons Airport" },
  { code: "URO", name: "Rouen Airport" },
  { code: "TUF", name: "Tours Val de Loire Airport" },
  { code: "CET", name: "Cholet Le Pontreau Airport" },
  { code: "LVA", name: "Laval Entrammes Airport" },
  { code: "ORE", name: "Orleans Saint-Denis-de-l'Hotel Airport" },
  { code: "LBG", name: "Paris Le Bourget Airport" },
  { code: "CDG", name: "Paris Charles de Gaulle Airport" },
  { code: "TNF", name: "Toussus-Le-Noble Airport" },
  { code: "ORY", name: "Paris Orly Airport" },
  { code: "POX", name: "Pontoise Cormeilles Airport" },
  { code: "NVS", name: "Nevers Fourchambault Airport" },
  { code: "LIL", name: "Lille Airport" },
  { code: "HZB", name: "Merville Calonne Airport" },
  { code: "BES", name: "Brest Bretagne Airport" },
  { code: "CER", name: "Cherbourg Maupertus Airport" },
  { code: "DNR", name: "Dinard Pleurtuit Saint-Malo Airport" },
  { code: "DOL", name: "Deauville Saint Gatien Airport" },
  { code: "LRT", name: "Lorient South Brittany Airport" },
  { code: "EDM", name: "La Roche-sur-Yon Les Ajoncs Airport" },
  { code: "CFR", name: "Caen Carpiquet Airport" },
  { code: "LME", name: "Le Mans Arnage Airport" },
  { code: "RNS", name: "Rennes Saint-Jacques Airport" },
  { code: "LAI", name: "Lannion Airport" },
  { code: "UIP", name: "Quimper Cornouaille Airport" },
  { code: "NTE", name: "Nantes Atlantique Airport" },
  { code: "SBK", name: "Saint-Brieuc Armor Airport" },
  { code: "MXN", name: "Morlaix Ploujean Airport" },
  { code: "VNE", name: "Vannes Meucon Airport" },
  { code: "SNR", name: "Saint Nazaire Montoir Airport" },
  { code: "BSL", name: "Basel Mulhouse-Freiburg EuroAirport" },
  { code: "DIJ", name: "Dijon Longvic Airport" },
  { code: "EPL", name: "Epinal Mirecourt Airport" },
  { code: "BVE", name: "Brive Souillac Airport" },
  { code: "ENC", name: "Nancy Essey Airport" },
  { code: "SXB", name: "Strasbourg Airport" },
  { code: "TLN", name: "Toulon-Hyeres Airport" },
  { code: "FNI", name: "Nimes Ales Camargue Cevennes Airport" },
  { code: "LTT", name: "Saint-Tropez La Mole Airport" },
  { code: "FSP", name: "Saint-Pierre Airport" },
  { code: "AXD", name: "Alexandroupolis International Airport" },
  { code: "ATH", name: "Athens Eleftherios Venizelos" },
  { code: "VOL", name: "Volos Nea Anchialos National Airport" },
  { code: "JKH", name: "Chios Island National Airport" },
  { code: "JIK", name: "Ikaria Island National Airport" },
  { code: "IOA", name: "Ioannina National Airport" },
  { code: "HER", name: "Heraklion International Airport" },
  { code: "KSO", name: "Kastoria National Airport" },
  { code: "KIT", name: "Kithira Island National Airport" },
  { code: "EFL", name: "Kefalonia Cephalonia International Airport" },
  { code: "KZS", name: "Kastellorizo Airport" },
  { code: "KLX", name: "Kalamata International Airport" },
  { code: "KGS", name: "Kos Island International Airport" },
  { code: "AOK", name: "Karpathos Island National Airport" },
  { code: "CFU", name: "Corfu International Airport" },
  { code: "KSJ", name: "Kasos Island Airport" },
  { code: "KVA", name: "Kavala International Airport" },
  { code: "JKL", name: "Kalymnos Island National Airport" },
  { code: "KZI", name: "Kozani National Airport" },
  { code: "LRS", name: "Leros Municipal Airport" },
  { code: "LXS", name: "Lemnos International Airport" },
  { code: "JMK", name: "Mykonos Island National Airport" },
  { code: "MLO", name: "Milos Island National Airport" },
  { code: "MJT", name: "Mytilene International Airport" },
  { code: "JNX", name: "Naxos Island National Airport" },
  { code: "PAS", name: "Paros National Airport" },
  { code: "JTY", name: "Astypalaia Island National Airport" },
  { code: "PVK", name: "Aktion National Airport" },
  { code: "RHO", name: "Rhodes International Airport" },
  { code: "GPA", name: "Patras Araxos Airport" },
  { code: "CHQ", name: "Chania International Airport" },
  { code: "JSI", name: "Skiathos Island National Airport" },
  { code: "SMI", name: "Samos International Airport" },
  { code: "JSY", name: "Syros Island National Airport" },
  { code: "JTR", name: "Santorini Thira National Airport" },
  { code: "JSH", name: "Sitia Public Airport" },
  { code: "SKU", name: "Skyros Island National Airport" },
  { code: "SKG", name: "Thessaloniki International Airport" },
  { code: "ZTH", name: "Zakynthos International Airport" },
  { code: "BUD", name: "Budapest Ferenc Liszt International Airport" },
  { code: "DEB", name: "Debrecen International Airport" },
  { code: "PEV", name: "Pecs Pogany Airport" },
  { code: "QGY", name: "Gyor Per Airport" },
  { code: "SOB", name: "Sarmellek International Airport" },
  { code: "QZD", name: "Szeged Airport" },
  { code: "CRV", name: "Crotone Airport" },
  { code: "BRI", name: "Bari Karol Wojtyla Airport" },
  { code: "FOG", name: "Foggia Gino Lisa Airport" },
  { code: "TAR", name: "Taranto Grottaglie Airport" },
  { code: "PSR", name: "Pescara Abruzzo International Airport" },
  { code: "BDS", name: "Brindisi Airport" },
  { code: "SUF", name: "Lamezia Terme Airport" },
  { code: "CIY", name: "Comiso Airport" },
  { code: "CTA", name: "Catania Fontanarossa Airport" },
  { code: "LMP", name: "Lampedusa Airport" },
  { code: "PNL", name: "Pantelleria Airport" },
  { code: "PMO", name: "Palermo Falcone-Borsellino Airport" },
  { code: "REG", name: "Reggio Calabria Airport" },
  { code: "TPS", name: "Trapani Birgi Airport" },
  { code: "RAN", name: "Ravenna Airport" },
  { code: "AHO", name: "Alghero Fertilia Airport" },
  { code: "CAG", name: "Cagliari Elmas Airport" },
  { code: "OLB", name: "Olbia Costa Smeralda Airport" },
  { code: "FNU", name: "Oristano Fenosu Airport" },
  { code: "MXP", name: "Milan Malpensa Airport" },
  { code: "BGY", name: "Milan Bergamo Il Caravaggio International Airport" },
  { code: "TRN", name: "Turin Caselle Airport" },
  { code: "ALL", name: "Albenga Clemente Panero International Airport" },
  { code: "GOA", name: "Genoa Cristoforo Colombo Airport" },
  { code: "LIN", name: "Milan Linate Airport" },
  { code: "PMF", name: "Parma Airport" },
  { code: "AOT", name: "Aosta Airport" },
  { code: "CUF", name: "Turin Cuneo Levaldigi Airport" },
  { code: "BZO", name: "Bolzano Airport" },
  { code: "BLQ", name: "Bologna Guglielmo Marconi Airport" },
  { code: "TSF", name: "Treviso Airport" },
  { code: "FRL", name: "Forli Airport" },
  { code: "VBS", name: "Brescia Airport" },
  { code: "TRS", name: "Trieste Friuli Venezia Giulia Airport" },
  { code: "RMI", name: "Rimini Federico Fellini Airport" },
  { code: "VRN", name: "Verona Airport" },
  { code: "AOI", name: "Ancona Airport" },
  { code: "VCE", name: "Venice Marco Polo Airport" },
  { code: "LCV", name: "Lucca Tassignano Airport" },
  { code: "CIA", name: "Rome Ciampino Airport" },
  { code: "FCO", name: "Rome Leonardo da Vinci Fiumicino Airport" },
  { code: "QSR", name: "Salerno Costa d'Amalfi Airport" },
  { code: "EBA", name: "Elba Marina di Campo Airport" },
  { code: "NAP", name: "Naples Airport" },
  { code: "PSA", name: "Pisa Galileo Galilei Airport" },
  { code: "FLR", name: "Florence Peretola Airport" },
  { code: "GRS", name: "Grosseto Airport" },
  { code: "PEG", name: "Perugia Umbria International Airport" },
  { code: "LJU", name: "Ljubljana Joze Pucnik Airport" },
  { code: "MBX", name: "Maribor Airport" },
  { code: "UHE", name: "Kunovice Airport" },
  { code: "KLV", name: "Karlovy Vary Airport" },
  { code: "OSR", name: "Ostrava Leos Janacek Airport" },
  { code: "PED", name: "Pardubice Airport" },
  { code: "PRV", name: "Prerov Airport" },
  { code: "PRG", name: "Prague Vaclav Havel Airport" },
  { code: "BRQ", name: "Brno Turany Airport" },
  { code: "VOD", name: "Vodochody Airport" },
  { code: "TLV", name: "Tel Aviv Ben Gurion International Airport" },
  { code: "ETM", name: "Eilat Ramon Airport" },
  { code: "HFA", name: "Haifa Airport" },
  { code: "SDV", name: "Tel Aviv Sde Dov Airport" },
  { code: "MLA", name: "Luqa Malta International Airport" },
  { code: "MCM", name: "Monte Carlo Monaco Heliport" },
  { code: "QEW", name: "Wiener Neustadt East Airport" },
  { code: "HOH", name: "Hohenems Dornbirn Airport" },
  { code: "GRZ", name: "Graz Airport" },
  { code: "INN", name: "Innsbruck Kranebitten Airport" },
  { code: "KLU", name: "Klagenfurt Airport" },
  { code: "LNZ", name: "Linz Airport" },
  { code: "SZG", name: "Salzburg Airport" },
  { code: "VIE", name: "Vienna International Airport" },
  { code: "SMA", name: "Santa Maria Airport" },
  { code: "BGC", name: "Braganca Airport" },
  { code: "BYJ", name: "Beja International Airport" },
  { code: "BGZ", name: "Braga Airport" },
  { code: "CHV", name: "Chaves Airport" },
  { code: "CBP", name: "Coimbra Airport" },
  { code: "CVU", name: "Corvo Airport" },
  { code: "CAT", name: "Cascais Municipal Aerodrome" },
  { code: "FLW", name: "Flores Airport" },
  { code: "FAO", name: "Faro Airport" },
  { code: "GRW", name: "Graciosa Airport" },
  { code: "HOR", name: "Horta Airport" },
  { code: "TER", name: "Lajes Field" },
  { code: "FNC", name: "Funchal Cristiano Ronaldo Airport" },
  { code: "PDL", name: "Ponta Delgada Joao Paulo II Airport" },
  { code: "PIX", name: "Pico Airport" },
  { code: "PRM", name: "Portimao Airport" },
  { code: "OPO", name: "Porto Francisco de Sa Carneiro Airport" },
  { code: "PXO", name: "Porto Santo Airport" },
  { code: "LIS", name: "Lisbon Humberto Delgado Airport" },
  { code: "SJZ", name: "Sao Jorge Airport" },
  { code: "VRL", name: "Vila Real Airport" },
  { code: "VSE", name: "Viseu Airport" },
  { code: "BNX", name: "Banja Luka International Airport" },
  { code: "OMO", name: "Mostar International Airport" },
  { code: "SJJ", name: "Sarajevo International Airport" },
  { code: "TZL", name: "Tuzla International Airport" },
  { code: "ARW", name: "Arad International Airport" },
  { code: "BCM", name: "Bacau International Airport" },
  { code: "BAY", name: "Baia Mare Airport" },
  { code: "BBU", name: "Bucharest Aurel Vlaicu Airport" },
  { code: "CND", name: "Constanta International Airport" },
  { code: "CLJ", name: "Cluj-Napoca International Airport" },
  { code: "CRA", name: "Craiova International Airport" },
  { code: "IAS", name: "Iasi International Airport" },
  { code: "OMR", name: "Oradea International Airport" },
  { code: "OTP", name: "Bucharest Henri Coanda International Airport" },
  { code: "BRV", name: "Brasov Airport" },
  { code: "SBZ", name: "Sibiu International Airport" },
  { code: "SUJ", name: "Satu Mare International Airport" },
  { code: "SCV", name: "Suceava International Airport" },
  { code: "TGM", name: "Targu Mures International Airport" },
  { code: "TSR", name: "Timisoara Traian Vuia International Airport" },
  { code: "GVA", name: "Geneva International Airport" },
  { code: "SIR", name: "Sion Airport" },
  { code: "LUG", name: "Lugano Airport" },
  { code: "BRN", name: "Bern Airport" },
  { code: "BXO", name: "Buochs Airport" },
  { code: "ZRH", name: "Zurich Airport" },
  { code: "ACH", name: "St. Gallen Altenrhein Airport" },
  { code: "SMV", name: "St. Moritz Samedan Airport" },
  { code: "ESB", name: "Ankara Esenboga International Airport" },
  { code: "ADA", name: "Adana Sakirpasa Airport" },
  { code: "AYT", name: "Antalya Airport" },
  { code: "GZT", name: "Gaziantep Oguzeli Airport" },
  { code: "KFS", name: "Kastamonu Airport" },
  { code: "KYA", name: "Konya Airport" },
  { code: "MZH", name: "Merzifon Airport" },
  { code: "VAS", name: "Sivas Nuri Demirag Airport" },
  { code: "ONQ", name: "Zonguldak Airport" },
  { code: "MLX", name: "Malatya Erhac Airport" },
  { code: "ASR", name: "Kayseri Erkilet International Airport" },
  { code: "TJK", name: "Tokat Airport" },
  { code: "DNZ", name: "Denizli Cardak Airport" },
  { code: "NAV", name: "Nevsehir Kapadokya Airport" },
  { code: "ISL", name: "Istanbul Ataturk Airport" },
  { code: "CKZ", name: "Canakkale Airport" },
  { code: "ADB", name: "Izmir Adnan Menderes International Airport" },
  { code: "USQ", name: "Usak Airport" },
  { code: "KCO", name: "Izmit Topel Airport" },
  { code: "YEI", name: "Bursa Yenisehir Airport" },
  { code: "DLM", name: "Dalaman Airport" },
  { code: "TEQ", name: "Tekirdag Corlu Airport" },
  { code: "AOE", name: "Eskisehir Airport" },
  { code: "KZR", name: "Kutahya Zafer Airport" },
  { code: "EZS", name: "Elazig Airport" },
  { code: "OGU", name: "Ordu Giresun Airport" },
  { code: "DIY", name: "Diyarbakır Airport" },
  { code: "ERC", name: "Erzincan Airport" },
  { code: "ERZ", name: "Erzurum Airport" },
  { code: "KSY", name: "Kars Harakani Airport" },
  { code: "TZX", name: "Trabzon Airport" },
  { code: "VAN", name: "Van Airport" },
  { code: "BAL", name: "Batman Airport" },
  { code: "MSR", name: "Mus Airport" },
  { code: "SXZ", name: "Siirt Airport" },
  { code: "NOP", name: "Sinop Airport" },
  { code: "KCM", name: "Kahramanmaras Airport" },
  { code: "AJI", name: "Agri Airport" },
  { code: "ADF", name: "Adiyaman Airport" },
  { code: "MQM", name: "Mardin Airport" },
  { code: "GNY", name: "Sanliurfa GAP Airport" },
  { code: "IGD", name: "Igdir Airport" },
  { code: "BGG", name: "Bingol Airport" },
  { code: "NKT", name: "Sirnak Airport" },
  { code: "YKO", name: "Yuksekova Hakkari Airport" },
  { code: "HTY", name: "Antakya Hatay Airport" },
  { code: "ISE", name: "Isparta Suleyman Demirel Airport" },
  { code: "EDO", name: "Edremit Balikesir Koca Seyit Airport" },
  { code: "BJV", name: "Bodrum Milas Airport" },
  { code: "GZP", name: "Gazipasa Airport" },
  { code: "SZF", name: "Samsun Carsamba Airport" },
  { code: "SAW", name: "Istanbul Sabiha Gokcen International Airport" },
  { code: "IST", name: "Istanbul Airport" },
  { code: "KIV", name: "Chisinau International Airport" },
  { code: "OHD", name: "Ohrid Airport" },
  { code: "SKP", name: "Skopje International Airport" },
  { code: "GIB", name: "Gibraltar Airport" },
  { code: "BEG", name: "Belgrade Nikola Tesla Airport" },
  { code: "INI", name: "Nis Constantine the Great Airport" },
  { code: "TGD", name: "Podgorica Airport" },
  { code: "TIV", name: "Tivat Airport" },
  { code: "BTS", name: "Bratislava M. R. Stefanik Airport" },
  { code: "KSC", name: "Kosice International Airport" },
  { code: "PZY", name: "Piestany Airport" },
  { code: "SLD", name: "Sliac Airport" },
  { code: "TAT", name: "Poprad Tatry Airport" },
  { code: "ILZ", name: "Zilina Airport" },
  { code: "GDT", name: "Grand Turk International Airport" },
  { code: "PLS", name: "Providenciales International Airport" },
  { code: "XSC", name: "South Caicos Airport" },
  { code: "BRX", name: "Barahona Maria Montez International Airport" },
  { code: "AZS", name: "Samana El Catey International Airport" },
  { code: "JBQ", name: "Santo Domingo La Isabela International Airport" },
  { code: "LRM", name: "La Romana International Airport" },
  { code: "PUJ", name: "Punta Cana International Airport" },
  { code: "POP", name: "Puerto Plata Gregorio Luperon International Airport" },
  { code: "SDQ", name: "Santo Domingo Las Americas International Airport" },
  {
    code: "STI",
    name: "Santiago de los Caballeros Cibao International Airport"
  },
  { code: "FRS", name: "Flores Santa Elena Airport" },
  { code: "GUA", name: "Guatemala City La Aurora International Airport" },
  { code: "LCE", name: "La Ceiba Goloson International Airport" },
  { code: "SAP", name: "San Pedro Sula La Mesa International Airport" },
  { code: "RTB", name: "Roatan International Airport" },
  { code: "TGU", name: "Tegucigalpa Toncontin International Airport" },
  { code: "KIN", name: "Kingston Norman Manley International Airport" },
  { code: "MBJ", name: "Montego Bay Sangster International Airport" },
  { code: "ACA", name: "Acapulco International Airport" },
  { code: "NTR", name: "Monterrey Del Norte International Airport" },
  { code: "AGU", name: "Aguascalientes International Airport" },
  { code: "HUX", name: "Huatulco International Airport" },
  { code: "CME", name: "Ciudad del Carmen International Airport" },
  { code: "CUL", name: "Culiacan International Airport" },
  { code: "CTM", name: "Chetumal International Airport" },
  { code: "CEN", name: "Ciudad Obregon International Airport" },
  { code: "CPE", name: "Campeche International Airport" },
  { code: "CJS", name: "Ciudad Juarez International Airport" },
  { code: "CUU", name: "Chihuahua International Airport" },
  { code: "CVM", name: "Ciudad Victoria International Airport" },
  { code: "CZM", name: "Cozumel International Airport" },
  { code: "DGO", name: "Durango International Airport" },
  { code: "TPQ", name: "Tepic Amado Nervo International Airport" },
  { code: "ESE", name: "Ensenada Airport" },
  { code: "GDL", name: "Guadalajara International Airport" },
  { code: "GYM", name: "Guaymas International Airport" },
  { code: "HMO", name: "Hermosillo International Airport" },
  { code: "CLQ", name: "Colima Airport" },
  { code: "SLW", name: "Saltillo Plan de Guadalupe Airport" },
  { code: "IZT", name: "Ixtepec Airport" },
  { code: "JAL", name: "El Lencero Jalapa Airport" },
  { code: "LZC", name: "Lazaro Cardenas Airport" },
  { code: "LMM", name: "Los Mochis International Airport" },
  { code: "BJX", name: "Silao Del Bajio International Airport" },
  { code: "LAP", name: "La Paz International Airport" },
  { code: "LTO", name: "Loreto International Airport" },
  { code: "MAM", name: "Matamoros International Airport" },
  { code: "MID", name: "Merida Rejon Airport" },
  { code: "MXL", name: "Mexicali International Airport" },
  { code: "MLM", name: "Morelia International Airport" },
  { code: "MTT", name: "Minatitlan Coatzacoalcos International Airport" },
  { code: "LOV", name: "Monclova International Airport" },
  { code: "MEX", name: "Mexico City International Airport" },
  { code: "MTY", name: "Monterrey International Airport" },
  { code: "MZT", name: "Mazatlan International Airport" },
  { code: "NLD", name: "Nuevo Laredo Quetzalcoatl International Airport" },
  { code: "OAX", name: "Oaxaca Xoxocotlan International Airport" },
  { code: "PAZ", name: "Poza Rica El Tajin National Airport" },
  { code: "PBC", name: "Puebla Hermanos Serdan International Airport" },
  { code: "PDS", name: "Piedras Negras International Airport" },
  { code: "UPN", name: "Uruapan International Airport" },
  { code: "PVR", name: "Puerto Vallarta International Airport" },
  { code: "PXM", name: "Puerto Escondido International Airport" },
  { code: "QRO", name: "Queretaro International Airport" },
  { code: "REX", name: "Reynosa General Lucio Blanco International Airport" },
  { code: "SJD", name: "San Jose del Cabo Los Cabos International Airport" },
  { code: "CSL", name: "Cabo San Lucas International Airport" },
  {
    code: "SLP",
    name: "San Luis Potosi Ponciano Arriaga International Airport"
  },
  { code: "TRC", name: "Torreon Francisco Sarabia International Airport" },
  { code: "TGZ", name: "Tuxtla Gutierrez International Airport" },
  { code: "TIJ", name: "Tijuana International Airport" },
  { code: "TAM", name: "Tampico International Airport" },
  { code: "TLC", name: "Toluca International Airport" },
  { code: "TAP", name: "Tapachula International Airport" },
  { code: "CUN", name: "Cancun International Airport" },
  { code: "VSA", name: "Villahermosa International Airport" },
  { code: "VER", name: "Veracruz International Airport" },
  { code: "ZCL", name: "Zacatecas International Airport" },
  { code: "ZIH", name: "Zihuatanejo Ixtapa International Airport" },
  { code: "ZLO", name: "Manzanillo Playa de Oro International Airport" },
  { code: "BEF", name: "Bluefields Airport" },
  { code: "MGA", name: "Managua Augusto C. Sandino International Airport" },
  { code: "BOC", name: "Bocas Del Toro International Airport" },
  { code: "CTD", name: "Chitre Alonso Valderrama Airport" },
  { code: "CHX", name: "Changuinola International Airport" },
  { code: "DAV", name: "David Enrique Malek International Airport" },
  { code: "ONX", name: "Colon Enrique Adolfo Jimenez Airport" },
  { code: "PAC", name: "Panama City International Airport" },
  { code: "BLB", name: "Balboa Panama Pacifico International Airport" },
  { code: "RIH", name: "Rio Hato Scarlett Martinez International Airport" },
  { code: "PTY", name: "Panama City Tocumen International Airport" },
  { code: "TTQ", name: "Tortuguero Airport" },
  { code: "DRK", name: "Drake Bay Airport" },
  { code: "GLF", name: "Golfito Airport" },
  { code: "LIR", name: "Liberia International Airport" },
  { code: "LIO", name: "Limon International Airport" },
  { code: "SJO", name: "San Jose Juan Santamaria International Airport" },
  { code: "PJM", name: "Puerto Jimenez Airport" },
  { code: "SYQ", name: "San Jose Tobias Bolanos International Airport" },
  { code: "XQP", name: "Quepos La Managua Airport" },
  { code: "TNO", name: "Tamarindo Airport" },
  { code: "TMU", name: "Tambor Airport" },
  { code: "SAL", name: "San Salvador International Airport" },
  { code: "ILS", name: "San Salvador Ilopango International Airport" },
  { code: "CYA", name: "Les Cayes Airport" },
  { code: "CAP", name: "Cap-Haitien International Airport" },
  {
    code: "PAP",
    name: "Port-au-Prince Toussaint Louverture International Airport"
  },
  { code: "CCC", name: "Cayo Coco Jardines del Rey Airport" },
  { code: "CFG", name: "Cienfuegos Jaime Gonzalez Airport" },
  { code: "CYO", name: "Cayo Largo del Sur Vilo Acuna Airport" },
  { code: "CMW", name: "Camaguey Ignacio Agramonte International Airport" },
  { code: "SCU", name: "Santiago de Cuba Antonio Maceo Airport" },
  { code: "GAO", name: "Guantanamo Mariana Grajales Airport" },
  { code: "HAV", name: "Havana Jose Marti International Airport" },
  { code: "HOG", name: "Holguin Frank Pais Airport" },
  { code: "MZO", name: "Manzanillo Sierra Maestra Airport" },
  { code: "SNU", name: "Santa Clara Abel Santamaria Airport" },
  { code: "VRA", name: "Varadero Juan Gualberto Gomez Airport" },
  {
    code: "CYB",
    name: "Cayman Brac Charles Kirkconnell International Airport"
  },
  { code: "LYB", name: "Little Cayman Airport" },
  { code: "GCM", name: "George Town Owen Roberts International Airport" },
  { code: "ASD", name: "Andros Town International Airport" },
  { code: "MHH", name: "Marsh Harbour Airport" },
  { code: "SAQ", name: "San Andros Airport" },
  { code: "AXP", name: "Spring Point Airport" },
  { code: "TCB", name: "Treasure Cay Airport" },
  { code: "CCZ", name: "Chub Cay International Airport" },
  { code: "GHC", name: "Great Harbour Cay Airport" },
  { code: "BIM", name: "South Bimini Airport" },
  { code: "ATC", name: "Arthur's Town Airport" },
  { code: "TBI", name: "New Bight Airport" },
  { code: "CXY", name: "Cat Cays Airport" },
  { code: "CRI", name: "Crooked Island Colonel Hill Airport" },
  { code: "GGT", name: "George Town Exuma International Airport" },
  { code: "ELH", name: "North Eleuthera Airport" },
  { code: "GHB", name: "Governor's Harbour Airport" },
  { code: "NMC", name: "Norman's Cay Airport" },
  { code: "RSD", name: "Rock Sound International Airport" },
  { code: "TYM", name: "Staniel Cay Airport" },
  { code: "FPO", name: "Freeport Grand Bahama International Airport" },
  { code: "WTD", name: "West End Airport" },
  { code: "IGA", name: "Inagua Matthew Town Airport" },
  { code: "LGI", name: "Deadman's Cay Airport" },
  { code: "SML", name: "Stella Maris Airport" },
  { code: "MYG", name: "Mayaguana Airport" },
  { code: "NAS", name: "Nassau Lynden Pindling International Airport" },
  { code: "ZSA", name: "San Salvador Airport" },
  { code: "TZA", name: "Belize City Sir Barry Bowen Municipal Airport" },
  {
    code: "BZE",
    name: "Belize City Philip S. W. Goldson International Airport"
  },
  { code: "CUK", name: "Caye Caulker Airport" },
  { code: "CZH", name: "Corozal Airport" },
  { code: "DGA", name: "Dangriga Airport" },
  { code: "PND", name: "Punta Gorda Airport" },
  { code: "PLJ", name: "Placencia Airport" },
  { code: "SPR", name: "San Pedro John Greif II Airport" },
  { code: "AIT", name: "Aitutaki Airport" },
  { code: "RAR", name: "Avarua Rarotonga International Airport" },
  { code: "CST", name: "Castaway Island Airport" },
  { code: "NAN", name: "Nadi International Airport" },
  { code: "PTF", name: "Malolo Lailai Airport" },
  { code: "KDV", name: "Kandavu Airport" },
  { code: "MNF", name: "Mana Island Airport" },
  { code: "SUV", name: "Nausori International Airport" },
  { code: "LBS", name: "Labasa Airport" },
  { code: "TVU", name: "Matei Taveuni Airport" },
  { code: "RTA", name: "Rotuma Airport" },
  { code: "SVU", name: "Savusavu Airport" },
  { code: "YAS", name: "Yasawa Island Airport" },
  { code: "TBU", name: "Tongatapu Fuaʻamotu International Airport" },
  { code: "VAV", name: "Vavau International Airport" },
  { code: "FUN", name: "Funafuti International Airport" },
  { code: "TRW", name: "Tarawa Bonriki International Airport" },
  { code: "IUE", name: "Niue International Airport" },
  { code: "FUT", name: "Futuna Island Pointe Vele Airport" },
  { code: "WLS", name: "Hihifo Airport" },
  { code: "FGI", name: "Apia Fagali'i Airport" },
  { code: "APW", name: "Apia Faleolo International Airport" },
  { code: "PPG", name: "Pago Pago International Airport" },
  { code: "PPT", name: "Papeete Tahiti Faa'a International Airport" },
  { code: "RMT", name: "Rimatara Airport" },
  { code: "RUR", name: "Rurutu Airport" },
  { code: "TUB", name: "Tubuai Mataura Airport" },
  { code: "TIH", name: "Tikehau Airport" },
  { code: "FAV", name: "Fakarava Airport" },
  { code: "XMH", name: "Manihi Airport" },
  { code: "GMR", name: "Totegegie Airport" },
  { code: "KKR", name: "Kaukura Airport" },
  { code: "MKP", name: "Makemo Airport" },
  { code: "NAU", name: "Napuka Airport" },
  { code: "TKV", name: "Tatakoto Airport" },
  { code: "AXR", name: "Arutua Airport" },
  { code: "MVT", name: "Mataiva Airport" },
  { code: "AHE", name: "Ahe Airport" },
  { code: "KHZ", name: "Kauehi Airport" },
  { code: "NIU", name: "Niau Airport" },
  { code: "TKX", name: "Takaroa Airport" },
  { code: "NHV", name: "Nuku Hiva Airport" },
  { code: "AUQ", name: "Atuona Airport" },
  { code: "UAP", name: "Ua Pou Airport" },
  { code: "UAH", name: "Ua Huka Airport" },
  { code: "BOB", name: "Bora Bora Airport" },
  { code: "RGI", name: "Rangiroa Airport" },
  { code: "HUH", name: "Huahine Fare Airport" },
  { code: "MOZ", name: "Moorea Airport" },
  { code: "HOI", name: "Hao Airport" },
  { code: "MAU", name: "Maupiti Airport" },
  { code: "RFP", name: "Raiatea Airport" },
  { code: "SON", name: "Luganville Santo-Pekoa International Airport" },
  { code: "FTA", name: "Futuna Airport" },
  { code: "VLI", name: "Port Vila Bauerfield International Airport" },
  { code: "TAH", name: "Tanna Whitegrass Airport" },
  { code: "ILP", name: "Ile Des Pins Airport" },
  { code: "TGJ", name: "Tiga Airport" },
  { code: "KNQ", name: "Kone Airport" },
  { code: "KOC", name: "Koumac Airport" },
  { code: "LIF", name: "Lifou Ouanaham Airport" },
  { code: "GEA", name: "Noumea Magenta Airport" },
  { code: "MEE", name: "Mare Airport" },
  { code: "TOU", name: "Touho Airport" },
  { code: "UVE", name: "Ouvea Airport" },
  { code: "NOU", name: "Noumea La Tontouta International Airport" },
  { code: "AKL", name: "Auckland Airport" },
  { code: "TUO", name: "Taupo Airport" },
  { code: "AMZ", name: "Ardmore Airport" },
  { code: "CHC", name: "Christchurch International Airport" },
  { code: "CHT", name: "Chatham Island Tuuta Airport" },
  { code: "DUD", name: "Dunedin International Airport" },
  { code: "GBZ", name: "Claris Great Barrier Aerodrome" },
  { code: "GMN", name: "Greymouth Airport" },
  { code: "GIS", name: "Gisborne" },
  { code: "HKK", name: "Hokitika Airport" },
  { code: "HLZ", name: "Hamilton International Airport" },
  { code: "WIK", name: "Waiheke Island Aerodrome" },
  { code: "KKE", name: "Kerikeri Airport" },
  { code: "KAT", name: "Kaitaia Airport" },
  { code: "NPL", name: "New Plymouth Airport" },
  { code: "NPE", name: "Napier Hawke's Bay Airport" },
  { code: "NSN", name: "Nelson Airport" },
  { code: "IVC", name: "Invercargill Airport" },
  { code: "PCN", name: "Picton Koromiko Airport" },
  { code: "PMR", name: "Palmerston North International Airport" },
  { code: "PPQ", name: "Paraparaumu Kapiti Coast Airport" },
  { code: "ZQN", name: "Queenstown Airport" },
  { code: "ROT", name: "Rotorua International Airport" },
  { code: "TRG", name: "Tauranga Airport" },
  { code: "TIU", name: "Timaru Richard Pearse Airport" },
  { code: "BHE", name: "Blenheim Woodbourne Airport" },
  { code: "WKA", name: "Wanaka Airport" },
  { code: "WHK", name: "Whakatane Airport" },
  { code: "WLG", name: "Wellington International Airport" },
  { code: "WRE", name: "Whangarei Airport" },
  { code: "WSZ", name: "Westport Airport" },
  { code: "WAG", name: "Wanganui Airport" },
  { code: "BST", name: "Bost Airport" },
  { code: "CCN", name: "Chaghcharan Airport" },
  { code: "FAH", name: "Farah Airport" },
  { code: "HEA", name: "Herat International Airport" },
  { code: "KBL", name: "Kabul International Airport" },
  { code: "KDH", name: "Kandahar Airport" },
  { code: "MZR", name: "Mazar-I-Sharif International Airport" },
  { code: "TII", name: "Tarinkot Airport" },
  { code: "ZAJ", name: "Zaranj Airport" },
  { code: "BAH", name: "Bahrain International Airport" },
  { code: "KHB", name: "Awali Sakhir Air Base" },
  { code: "AHB", name: "Abha Regional Airport" },
  { code: "HOF", name: "Hofuf Al-Ahsa International Airport" },
  { code: "ULH", name: "Al Ula  Airport" },
  { code: "ABT", name: "Al-Baha Domestic Airport" },
  { code: "BHH", name: "Bisha Domestic Airport" },
  { code: "DMM", name: "Dammam King Fahd International Airport" },
  { code: "DWD", name: "Dawadmi Domestic Airport" },
  { code: "GIZ", name: "Jizan Regional Airport" },
  { code: "ELQ", name: "Al-Qassim Regional Airport" },
  { code: "URY", name: "Gurayat Domestic Airport" },
  { code: "HAS", name: "Ha'il Regional Airport" },
  { code: "JED", name: "Jeddah King Abdulaziz International Airport" },
  { code: "MED", name: "Medina Prince Mohammad bin Abdulaziz Airport" },
  { code: "EAM", name: "Najran Domestic Airport" },
  { code: "AQI", name: "Qaisumah Hafar Al Batin Airport" },
  { code: "RAH", name: "Rafha Domestic Airport" },
  { code: "RUH", name: "Riyadh King Khalid International Airport" },
  { code: "RAE", name: "Arar Domestic Airport" },
  { code: "SHW", name: "Sharurah Domestic Airport" },
  { code: "AJF", name: "Sakaka Al-Jawf Domestic Airport" },
  { code: "TUU", name: "Tabuk Regional Airport" },
  { code: "TIF", name: "Taif Airport" },
  { code: "TUI", name: "Turaif Domestic Airport" },
  { code: "WAE", name: "Wadi Ad Dawasir Domestic Airport" },
  { code: "EJH", name: "Al Wajh Airport" },
  { code: "YNB", name: "Yanbu Airport" },
  { code: "ABD", name: "Abadan Airport" },
  { code: "DEF", name: "Dezful Airport" },
  { code: "GCH", name: "Gachsaran Airport" },
  { code: "MRX", name: "Mahshahr Airport" },
  { code: "AWZ", name: "Ahwaz International Airport" },
  { code: "AEU", name: "Abu Musa Island Airport" },
  { code: "BUZ", name: "Bushehr Airport" },
  { code: "KIH", name: "Kish International Airport" },
  { code: "BDH", name: "Bandar Lengeh Airport" },
  { code: "PGU", name: "Assaluyeh Persian Gulf Airport" },
  { code: "KHK", name: "Khark Island Airport" },
  { code: "SXI", name: "Sirri Island Airport" },
  { code: "LVP", name: "Lavan Island Airport" },
  { code: "KSH", name: "Kermanshah Airport" },
  { code: "IIL", name: "Ilam Airport" },
  { code: "KHD", name: "Khorramabad Airport" },
  { code: "SDG", name: "Sanandaj Airport" },
  { code: "KKS", name: "Kashan Airport" },
  { code: "IFN", name: "Isfahan International Airport" },
  { code: "CQD", name: "Shahrekord Airport" },
  { code: "RAS", name: "Rasht Airport" },
  { code: "HDM", name: "Hamedan International Airport" },
  { code: "AJK", name: "Arak Airport" },
  { code: "IKA", name: "Tehran Imam Khomeini International Airport" },
  { code: "THR", name: "Tehran Mehrabad International Airport" },
  { code: "BND", name: "Bandar Abbas International Airport" },
  { code: "JYR", name: "Jiroft Airport" },
  { code: "KER", name: "Kerman Airport" },
  { code: "GSM", name: "Dayrestan Qeshm Island Airport" },
  { code: "RJN", name: "Rafsanjan Airport" },
  { code: "SYJ", name: "Sirjan Airport" },
  { code: "XBJ", name: "Birjand International Airport" },
  { code: "RUD", name: "Shahroud Airport" },
  { code: "MHD", name: "Mashhad International Airport" },
  { code: "BJB", name: "Bojnord Airport" },
  { code: "AFZ", name: "Sabzevar Airport" },
  { code: "TCX", name: "Tabas Airport" },
  { code: "KLM", name: "Kalaleh Airport" },
  { code: "GBT", name: "Gorgan Airport" },
  { code: "NSH", name: "Noshahr Airport" },
  { code: "RZR", name: "Ramsar International Airport" },
  { code: "SRY", name: "Sari Airport" },
  { code: "JAR", name: "Jahrom Airport" },
  { code: "LRR", name: "Lar Airport" },
  { code: "LFM", name: "Lamerd Airport" },
  { code: "SYZ", name: "Shiraz International Airport" },
  { code: "YES", name: "Yasuj Airport" },
  { code: "KHY", name: "Khoy Airport" },
  { code: "ADU", name: "Ardabil Airport" },
  { code: "ACP", name: "Maragheh Sahand Airport" },
  { code: "PFQ", name: "Parsabad Moghan Airport" },
  { code: "OMH", name: "Urmia Airport" },
  { code: "TBZ", name: "Tabriz International Airport" },
  { code: "JWN", name: "Zanjan Airport" },
  { code: "AZD", name: "Yazd Shahid Sadooghi Airport" },
  { code: "ACZ", name: "Zabol Airport" },
  { code: "ZBR", name: "Chabahar Konarak Airport" },
  { code: "ZAH", name: "Zahedan Airport" },
  { code: "AMM", name: "Amman Queen Alia International Airport" },
  { code: "ADJ", name: "Amman Civil Airport" },
  { code: "AQJ", name: "Aqaba King Hussein International Airport" },
  { code: "KWI", name: "Kuwait International Airport" },
  { code: "BEY", name: "Beirut Rafic Hariri International Airport" },
  { code: "AUH", name: "Abu Dhabi International Airport" },
  { code: "AZI", name: "Abu Dhabi Al Bateen Executive Airport" },
  { code: "AAN", name: "Al Ain International Airport" },
  { code: "XSB", name: "Sir Bani Yas Airport" },
  { code: "DXB", name: "Dubai International Airport" },
  { code: "DWC", name: "Dubai World Central International Airport" },
  { code: "FJR", name: "Fujairah International Airport" },
  { code: "RKT", name: "Ras Al Khaimah International Airport" },
  { code: "SHJ", name: "Sharjah International Airport" },
  { code: "KHS", name: "Khasab Airport" },
  { code: "MCT", name: "Muscat International Airport" },
  { code: "SLL", name: "Salalah Airport" },
  { code: "OHS", name: "Sohar Airport" },
  { code: "BHV", name: "Bahawalpur Airport" },
  { code: "CJL", name: "Chitral Airport" },
  { code: "DEA", name: "Dera Ghazi Khan Airport" },
  { code: "LYP", name: "Faisalabad International Airport" },
  { code: "GWD", name: "Gwadar International Airport" },
  { code: "GIL", name: "Gilgit Airport" },
  { code: "ISB", name: "Islamabad International Airport" },
  { code: "KHI", name: "Karachi Jinnah International Airport" },
  { code: "KCF", name: "Kadanwari Airport" },
  { code: "LHE", name: "Lahore Allama Iqbal International Airport" },
  { code: "MJD", name: "Moenjodaro Airport" },
  { code: "MUX", name: "Multan International Airport" },
  { code: "PEW", name: "Peshawar Bacha Khan International Airport" },
  { code: "UET", name: "Quetta International Airport" },
  { code: "RYK", name: "Rahim Yar Khan Shaikh Zayed International Airport" },
  { code: "KDU", name: "Skardu Airport" },
  { code: "SKZ", name: "Sukkur Airport" },
  { code: "SKT", name: "Sialkot International Airport" },
  { code: "TUK", name: "Turbat Airport" },
  { code: "PZH", name: "Zhob Airport" },
  { code: "BGW", name: "Baghdad International Airport" },
  { code: "EBL", name: "Erbil International Airport" },
  { code: "BSR", name: "Basra International Airport" },
  { code: "NJF", name: "Al Najaf International Airport" },
  { code: "ISU", name: "Sulaimaniyah International Airport" },
  { code: "ALP", name: "Aleppo International Airport" },
  { code: "DAM", name: "Damascus International Airport" },
  { code: "KAC", name: "Al-Qamishli Airport" },
  { code: "LTK", name: "Latakia Bassel al Assad International Airport" },
  { code: "DOH", name: "Doha Hamad International Airport" },
  { code: "ADE", name: "Aden International Airport" },
  { code: "RIY", name: "Mukalla Riyan Airport" },
  { code: "SAH", name: "Sana'a International Airport" },
  { code: "BTI", name: "Barter Island LRRS Airport" },
  { code: "BET", name: "Bethel Airport" },
  { code: "BKC", name: "Buckland Airport" },
  { code: "BRW", name: "Barrow Airport" },
  { code: "BTT", name: "Bettles Airport" },
  { code: "CDB", name: "Cold Bay Airport" },
  { code: "CYF", name: "Chefornak Airport" },
  { code: "SCM", name: "Scammon Bay Airport" },
  { code: "CDV", name: "Cordova Mudhole Smith Airport" },
  { code: "RDB", name: "Red Dog Airport" },
  { code: "DLG", name: "Dillingham Airport" },
  { code: "ADQ", name: "Kodiak Airport" },
  { code: "DUT", name: "Unalaska Airport" },
  { code: "KKH", name: "Kongiganak Airport" },
  { code: "EEK", name: "Eek Airport" },
  { code: "EMK", name: "Emmonak Airport" },
  { code: "ENA", name: "Kenai Municipal Airport" },
  { code: "FAI", name: "Fairbanks International Airport" },
  { code: "GAL", name: "Galena Airport" },
  { code: "KWK", name: "Kwigillingok Airport" },
  { code: "SHG", name: "Shungnak Airport" },
  { code: "GLV", name: "Golovin Airport" },
  { code: "GAM", name: "Gambell Airport" },
  { code: "GST", name: "Gustavus Airport" },
  { code: "SGY", name: "Skagway Airport" },
  { code: "HCR", name: "Holy Cross Airport" },
  { code: "HNS", name: "Haines Airport" },
  { code: "HOM", name: "Homer Airport" },
  { code: "HPB", name: "Hooper Bay Airport" },
  { code: "IAN", name: "Kiana Bob Baker Memorial Airport" },
  { code: "ILI", name: "Iliamna Airport" },
  { code: "WAA", name: "Wales Airport" },
  { code: "JNU", name: "Juneau International Airport" },
  { code: "KPN", name: "Kipnuk Airport" },
  { code: "KKA", name: "Koyuk Alfred Adams Airport" },
  { code: "AKN", name: "King Salmon Airport" },
  { code: "AKP", name: "Anaktuvuk Pass Airport" },
  { code: "KTN", name: "Ketchikan International Airport" },
  { code: "UUK", name: "Kuparuk Ugnu Airport" },
  { code: "KLW", name: "Klawock Airport" },
  { code: "KLG", name: "Kalskag Airport" },
  { code: "MCG", name: "McGrath Airport" },
  { code: "SMK", name: "St. Michael Airport" },
  { code: "MOU", name: "Mountain Village Airport" },
  { code: "MRI", name: "Anchorage Merrill Field Airport" },
  { code: "ANC", name: "Anchorage Ted Stevens International Airport" },
  { code: "ANI", name: "Aniak Airport" },
  { code: "ANV", name: "Anvik Airport" },
  { code: "OBU", name: "Kobuk Airport" },
  { code: "HNH", name: "Hoonah Airport" },
  { code: "OME", name: "Nome Airport" },
  { code: "OOK", name: "Toksook Bay Airport" },
  { code: "OTZ", name: "Kotzebue Ralph Wien Memorial Airport" },
  { code: "PSG", name: "Petersburg James A. Johnson Airport" },
  { code: "PTU", name: "Platinum Airport" },
  { code: "PHO", name: "Point Hope Airport" },
  { code: "KWN", name: "Quinhagak Airport" },
  { code: "NUI", name: "Nuiqsut Airport" },
  { code: "RSH", name: "Russian Mission Airport" },
  { code: "SVA", name: "Savoonga Airport" },
  { code: "SCC", name: "Deadhorse Airport" },
  { code: "SDP", name: "Sand Point Airport" },
  { code: "SHH", name: "Shishmaref Airport" },
  { code: "SIT", name: "Sitka Rocky Gutierrez Airport" },
  { code: "WLK", name: "Selawik Airport" },
  { code: "KSM", name: "St. Mary's Airport" },
  { code: "SNP", name: "St. Paul Island Airport" },
  { code: "TAL", name: "Tanana Ralph M. Calhoun Memorial Airport" },
  { code: "TOG", name: "Togiak Airport" },
  { code: "ATK", name: "Atqasuk Airport" },
  { code: "AUK", name: "Alakanuk Airport" },
  { code: "UNK", name: "Unalakleet Airport" },
  { code: "VAK", name: "Chevak Airport" },
  { code: "VDZ", name: "Valdez Airport" },
  { code: "KVL", name: "Kivalina Airport" },
  { code: "WRG", name: "Wrangell Airport" },
  { code: "AIN", name: "Wainwright Airport" },
  { code: "WMO", name: "White Mountain Airport" },
  { code: "WTK", name: "Noatak Airport" },
  { code: "YAK", name: "Yakutat Airport" },
  { code: "AKI", name: "Akiak Airport" },
  { code: "ELI", name: "Elim Airport" },
  { code: "KUK", name: "Kasigluk Airport" },
  { code: "KNK", name: "Kokhanok Airport" },
  { code: "KOT", name: "Kotlik Airport" },
  { code: "KTS", name: "Brevig Mission Airport" },
  { code: "KWT", name: "Kwethluk Airport" },
  { code: "SKK", name: "Shaktoolik Airport" },
  { code: "FYU", name: "Fort Yukon Airport" },
  { code: "ROP", name: "Rota International Airport" },
  { code: "SPN", name: "Saipan International Airport" },
  { code: "GUM", name: "Guam Antonio B. Won Pat International Airport" },
  { code: "HNM", name: "Hana Airport" },
  { code: "JHM", name: "Lahaina Kapalua Airport" },
  { code: "KOA", name: "Kona International Airport" },
  { code: "LIH", name: "Lihue Airport" },
  { code: "LUP", name: "Kalaupapa Airport" },
  { code: "MKK", name: "Kaunakakai Molokai Airport" },
  { code: "MUE", name: "Waimea Kohala Airport" },
  { code: "HNL", name: "Honolulu International Airport" },
  { code: "LNY", name: "Lanai Airport" },
  { code: "OGG", name: "Kahului Airport" },
  { code: "ITO", name: "Hilo International Airport" },
  { code: "MAJ", name: "Majuro Marshall Islands International Airport" },
  { code: "CXI", name: "Kiritimati Cassidy International Airport" },
  { code: "PIZ", name: "Point Lay LRRS Airport" },
  { code: "TKK", name: "Chuuk International Airport" },
  { code: "PNI", name: "Pohnpei International Airport" },
  { code: "ROR", name: "Airai Roman Tmetuchl International Airport" },
  { code: "KSA", name: "Kosrae International Airport" },
  { code: "YAP", name: "Yap International Airport" },
  { code: "KNH", name: "Kinmen Airport" },
  { code: "LZN", name: "Matsu Nangan Airport" },
  { code: "TTT", name: "Taitung Airport" },
  { code: "KHH", name: "Kaohsiung International Airport" },
  { code: "CYI", name: "Chiayi Airport" },
  { code: "RMQ", name: "Taichung Airport" },
  { code: "MFK", name: "Matsu Beigan Airport" },
  { code: "TNN", name: "Tainan Airport" },
  { code: "MZG", name: "Penghu Airport" },
  { code: "TSA", name: "Taipei Songshan Airport" },
  { code: "TPE", name: "Taiwan Taoyuan International Airport" },
  { code: "HUN", name: "Hualien Airport" },
  { code: "NRT", name: "Tokyo Narita International Airport" },
  { code: "MMJ", name: "Matsumoto Airport" },
  { code: "IBR", name: "Omitama Ibaraki Airport" },
  { code: "KIX", name: "Osaka Kansai International Airport" },
  { code: "SHM", name: "Nanki Shirahama Airport" },
  { code: "UKB", name: "Kobe Airport" },
  { code: "TJH", name: "Tajima Airport" },
  { code: "OBO", name: "Obihiro Tokachi Airport" },
  { code: "CTS", name: "Sapporo New Chitose Airport" },
  { code: "HKD", name: "Hakodate Airport" },
  { code: "KUH", name: "Kushiro Airport" },
  { code: "MMB", name: "Memanbetsu Airport" },
  { code: "SHB", name: "Nakashibetsu Airport" },
  { code: "OKD", name: "Sapporo Okadama Airport" },
  { code: "WKJ", name: "Wakkanai Airport" },
  { code: "AXJ", name: "Amakusa Airport" },
  { code: "UBJ", name: "Ube Yamaguchi Airport" },
  { code: "TSJ", name: "Tsushima Airport" },
  { code: "MBE", name: "Monbetsu Airport" },
  { code: "AKJ", name: "Asahikawa Airport" },
  { code: "RIS", name: "Rishiri Airport" },
  { code: "KUM", name: "Yakushima Airport" },
  { code: "FUJ", name: "Fukue Airport" },
  { code: "FUK", name: "Fukuoka Airport" },
  { code: "TNE", name: "Tanegashima Airport" },
  { code: "KOJ", name: "Kagoshima Airport" },
  { code: "KMI", name: "Miyazaki Airport" },
  { code: "OIT", name: "Oita Airport" },
  { code: "KKJ", name: "Kitakyushu Airport" },
  { code: "HSG", name: "Saga Airport" },
  { code: "KMJ", name: "Kumamoto Airport" },
  { code: "NGS", name: "Nagasaki Airport" },
  { code: "NGO", name: "Nagoya Chubu Centrair International Airport" },
  { code: "ASJ", name: "Amami Airport" },
  { code: "OKE", name: "Okierabu Airport" },
  { code: "KKX", name: "Kikai Airport" },
  { code: "TKN", name: "Tokunoshima Airport" },
  { code: "NKM", name: "Nagoya Komaki Airport" },
  { code: "KMQ", name: "Komatsu Airport" },
  { code: "OKI", name: "Oki Airport" },
  { code: "FSZ", name: "Shizuoka Airport" },
  { code: "TOY", name: "Toyama Airport" },
  { code: "NTQ", name: "Wajima Noto Airport" },
  { code: "HIJ", name: "Hiroshima Airport" },
  { code: "OKJ", name: "Okayama Airport" },
  { code: "IZO", name: "Izumo Airport" },
  { code: "YGJ", name: "Yonago Miho Airport" },
  { code: "IWK", name: "Iwakuni Kintaikyo Airport" },
  { code: "KCZ", name: "Kochi Airport" },
  { code: "MYJ", name: "Matsuyama Airport" },
  { code: "ITM", name: "Osaka International Airport" },
  { code: "TTJ", name: "Tottori Airport" },
  { code: "TKS", name: "Tokushima Airport" },
  { code: "TAK", name: "Takamatsu Airport" },
  { code: "IWJ", name: "Masuda Hagi Iwami Airport" },
  { code: "AOJ", name: "Aomori Airport" },
  { code: "GAJ", name: "Yamagata Airport" },
  { code: "FKS", name: "Fukushima Airport" },
  { code: "HNA", name: "Hanamaki Airport" },
  { code: "AXT", name: "Akita Airport" },
  { code: "MSJ", name: "Misawa Airport" },
  { code: "KIJ", name: "Niigata Airport" },
  { code: "ONJ", name: "Kitaakita Odate Noshiro Airport" },
  { code: "SDJ", name: "Sendai Airport" },
  { code: "SYO", name: "Sakata Shonai Airport" },
  { code: "HAC", name: "Hachijojima Airport" },
  { code: "OIM", name: "Oshima Airport" },
  { code: "HND", name: "Tokyo Haneda International Airport" },
  { code: "MWX", name: "Muan International Airport" },
  { code: "KWJ", name: "Gwangju Airport" },
  { code: "KUV", name: "Gunsan Airport" },
  { code: "RSU", name: "Yeosu Airport" },
  { code: "WJU", name: "Wonju Airport" },
  { code: "YNY", name: "Yangyang International Airport" },
  { code: "CJU", name: "Jeju International Airport" },
  { code: "PUS", name: "Busan Gimhae International Airport" },
  { code: "HIN", name: "Jinju Sacheon Airport" },
  { code: "USN", name: "Ulsan Airport" },
  { code: "ICN", name: "Seoul Incheon International Airport" },
  { code: "GMP", name: "Seoul Gimpo International Airport" },
  { code: "KPO", name: "Pohang Airport" },
  { code: "TAE", name: "Daegu International Airport" },
  { code: "CJJ", name: "Cheongju International Airport" },
  { code: "OKA", name: "Naha Airport" },
  { code: "ISG", name: "Ishigaki New Airport" },
  { code: "UEO", name: "Kumejima Airport" },
  { code: "MMY", name: "Miyako Airport" },
  { code: "KTD", name: "Kitadaito Airport" },
  { code: "TRA", name: "Tarama Airport" },
  { code: "RNJ", name: "Yoronjima Yoron Airport" },
  { code: "OGN", name: "Yonaguni Airport" },
  { code: "ENI", name: "El Nido Airport" },
  { code: "SFS", name: "Subic Bay International Airport" },
  { code: "CRK", name: "Angeles City Clark International Airport" },
  { code: "LAO", name: "Laoag City International Airport" },
  { code: "MNL", name: "Manila Ninoy Aquino International Airport" },
  { code: "LGP", name: "Legazpi Airport" },
  { code: "DVO", name: "Davao City Francisco Bangoy International Airport" },
  { code: "DPL", name: "Dipolog Airport" },
  { code: "CGM", name: "Mambajao Camiguin Airport" },
  { code: "OZC", name: "Ozamiz Labo Airport" },
  { code: "PAG", name: "Pagadian Airport" },
  { code: "GES", name: "General Santos International Airport" },
  { code: "CGY", name: "Cagayan de Oro Laguindingan Airport" },
  { code: "ZAM", name: "Zamboanga International Airport" },
  { code: "IAO", name: "Del Carmin Sayak Airport" },
  { code: "TAG", name: "Panglao Bohol International Airport" },
  { code: "SWL", name: "San Vicente Airport" },
  { code: "MBO", name: "Mamburao Airport" },
  { code: "WNP", name: "Naga Airport" },
  { code: "BSO", name: "Basco Airport" },
  { code: "TUG", name: "Tuguegarao Airport" },
  { code: "VRC", name: "Virac Airport" },
  { code: "CYZ", name: "Cauayan Airport" },
  { code: "TAC", name: "Tacloban Daniel Z. Romualdez Airport" },
  { code: "BCD", name: "Bacolod City Silay International Airport" },
  { code: "CYP", name: "Calbayog Airport" },
  { code: "DGT", name: "Dumaguete City Sibulan Airport" },
  { code: "MPH", name: "Caticlan Boracay Airport" },
  { code: "ILO", name: "Iloilo International Airport" },
  { code: "MBT", name: "Masbate Moises R. Espinosa Airport" },
  { code: "KLO", name: "Kalibo International Airport" },
  { code: "CEB", name: "Cebu Mactan International Airport" },
  { code: "OMC", name: "Ormoc Airport" },
  { code: "PPS", name: "Puerto Princesa City International Airport" },
  { code: "RXS", name: "Roxas City Airport" },
  { code: "SJI", name: "San Jose Airport" },
  { code: "USU", name: "Busuanga Airport" },
  { code: "CBO", name: "Cotabato Awang Airport" },
  { code: "BXU", name: "Butuan Bancasi Airport" },
  { code: "SUG", name: "Surigao Airport" },
  { code: "PRA", name: "Parana Airport" },
  { code: "ROS", name: "Rosario Islas Malvinas International Airport" },
  { code: "SFN", name: "Santa Fe Sauce Viejo Airport" },
  { code: "AEP", name: "Buenos Aires Jorge Newbery Airport" },
  { code: "COR", name: "Cordoba International Airport" },
  { code: "EPA", name: "El Palomar Airport" },
  {
    code: "EZE",
    name: "Buenos Aires Ministro Pistarini International Airport"
  },
  { code: "MDZ", name: "Mendoza International Airport" },
  { code: "LGS", name: "Malargue Airport" },
  { code: "AFA", name: "San Rafael Airport" },
  { code: "CTC", name: "Catamarca International Airport" },
  { code: "SDE", name: "Santiago del Estero Airport" },
  { code: "RHD", name: "Termas de Rio Hondo Airport" },
  { code: "IRJ", name: "La Rioja Airport" },
  { code: "TUC", name: "Tucuman Airport" },
  { code: "UAQ", name: "San Juan Domingo Faustino Sarmiento" },
  { code: "RCU", name: "Rio Cuarto Las Higueras Airport" },
  { code: "LUQ", name: "San Luis Airport" },
  { code: "CNQ", name: "Corrientes International Airport" },
  { code: "RES", name: "Resistencia International Airport" },
  { code: "FMA", name: "Formosa International Airport" },
  { code: "IGR", name: "Puerto Iguazu International Airport" },
  { code: "PSS", name: "Posadas Airport" },
  { code: "SLA", name: "Salta Martin Miguel de Guemes International Airport" },
  {
    code: "JUJ",
    name: "Jujuy Gobernador Horacio Guzman International Airport"
  },
  { code: "RCQ", name: "Reconquista Airport" },
  { code: "CRD", name: "Comodoro Rivadavia International Airport" },
  { code: "EQS", name: "Esquel Airport" },
  { code: "REL", name: "Trelew Almirante Marco Andres Zar Airport" },
  { code: "VDM", name: "Viedma Gobernador Edgardo Castello Airport" },
  { code: "PMY", name: "Puerto Madryn El Tehuelche Airport" },
  { code: "FTE", name: "El Calafate International Airport" },
  { code: "RGA", name: "Rio Grande International Airport" },
  { code: "RGL", name: "Rio Gallegos International Airport" },
  { code: "USH", name: "Ushuaia Malvinas Argentinas International Airport" },
  { code: "BHI", name: "Bahia Blanca Comandante Espora Airport" },
  { code: "MDQ", name: "Mar del Plata Astor Piazzolla International Airport" },
  { code: "NQN", name: "Neuquen Presidente Peron International Airport" },
  { code: "RSA", name: "Santa Rosa Airport" },
  { code: "BRC", name: "San Carlos de Bariloche Airport" },
  { code: "VLG", name: "Villa Gesell Airport" },
  { code: "CPC", name: "San Martin de los Andes Airport" },
  { code: "ARX", name: "Aracati Airport" },
  { code: "JTC", name: "Bauru Arealva Airport" },
  { code: "AQA", name: "Araraquara Airport" },
  { code: "AJU", name: "Aracaju Santa Maria Airport" },
  { code: "AFL", name: "Alta Floresta Airport" },
  { code: "ARU", name: "Aracatuba Airport" },
  { code: "AAX", name: "Araxa Airport" },
  { code: "BEL", name: "Belem Val de Cans International Airport" },
  { code: "PLU", name: "Belo Horizonte Pampulha Airport" },
  { code: "BSB", name: "Brasilia International Airport" },
  { code: "BVB", name: "Boa Vista International Airport" },
  { code: "CAC", name: "Cascavel Adalberto Mendes da Silva Airport" },
  { code: "CFB", name: "Cabo Frio International Airport" },
  { code: "CNF", name: "Belo Horizonte Tancredo Neves International Airport" },
  { code: "CGR", name: "Campo Grande International Airport" },
  { code: "XAP", name: "Chapeco Airport" },
  { code: "CKS", name: "Carajas Airport" },
  { code: "CCM", name: "Criciuma Diomicio Freitas Airport" },
  { code: "CAW", name: "Campos dos Goytacazes Bartolomeu Lysandro Airport" },
  { code: "CMG", name: "Corumba International Airport" },
  { code: "CWB", name: "Curitiba Afonso Pena International Airport" },
  { code: "CXJ", name: "Caxias do Sul Airport" },
  { code: "CGB", name: "Cuiaba Marechal Rondon International Airport" },
  { code: "CZS", name: "Cruzeiro do Sul International Airport" },
  { code: "BYO", name: "Bonito Airport" },
  { code: "PPB", name: "Presidente Prudente Airport" },
  { code: "DOU", name: "Dourados Airport" },
  { code: "MAO", name: "Manaus Eduardo Gomes International Airport" },
  { code: "FEC", name: "Feira de Santana Airport" },
  { code: "IGU", name: "Foz do Iguacu International Airport" },
  { code: "FLN", name: "Florianopolis Hercilio Luz International Airport" },
  { code: "FEN", name: "Fernando de Noronha Airport" },
  { code: "FOR", name: "Pinto Martins International Airport" },
  { code: "GIG", name: "Rio de Janeiro Galeao International Airport" },
  { code: "GYN", name: "Goiania Santa Genoveva Airport" },
  { code: "GRU", name: "Sao Paulo Guarulhos International Airport" },
  { code: "ATM", name: "Altamira Airport" },
  { code: "ITB", name: "Itaituba Airport" },
  { code: "IOS", name: "Ilheus Jorge Amado Airport" },
  { code: "IPN", name: "Ipatinga Usiminas Airport" },
  { code: "IMP", name: "Imperatriz Airport" },
  { code: "JJG", name: "Jaguaruna Regional Airport" },
  { code: "QDV", name: "Jundiai Airport" },
  { code: "JPR", name: "Ji-Parana Airport" },
  { code: "JPA", name: "Joao Pessoa Castro Pinto International Airport" },
  { code: "JDO", name: "Juazeiro do Norte Airport" },
  { code: "JOI", name: "Joinville Lauro Carneiro de Loyola Airport" },
  { code: "CPV", name: "Campina Grande Airport" },
  { code: "VCP", name: "Campinas Viracopos International Airport" },
  { code: "LEC", name: "Lencois Airport" },
  { code: "LAJ", name: "Lages Airport" },
  { code: "LDB", name: "Londrina Airport" },
  { code: "MAB", name: "Maraba Airport" },
  { code: "MEA", name: "Macae Airport" },
  { code: "MGF", name: "Maringa Regional Airport" },
  { code: "MOC", name: "Montes Claros Airport" },
  { code: "MII", name: "Marilla Airport" },
  { code: "MCZ", name: "Maceio Zumbi dos Palmares International Airport" },
  { code: "MCP", name: "Macapa International Airport" },
  { code: "MVF", name: "Mossoro Airport" },
  { code: "NVT", name: "Navegantes Victor Konder International Airport" },
  { code: "GEL", name: "Santo Angelo Airport" },
  { code: "POA", name: "Porto Alegre Salgado Filho International Airport" },
  { code: "PHB", name: "Parnaiba International Airport" },
  { code: "PFB", name: "Passo Fundo Lauro Kurtz Airport" },
  { code: "PMW", name: "Palmas Airport" },
  { code: "PET", name: "Pelotas International Airport" },
  { code: "PNZ", name: "Petrolina Airport" },
  { code: "BPS", name: "Porto Seguro Airport" },
  { code: "PVH", name: "Porto Velho International Airport" },
  { code: "VDC", name: "Vitoria da Conquista Airport" },
  { code: "RBR", name: "Rio Branco International Airport" },
  { code: "REC", name: "Recife Airport" },
  { code: "SDU", name: "Rio de Janeiro Santos Dumont" },
  { code: "RAO", name: "Ribeirao Preto Leite Lopes Airport" },
  { code: "NAT", name: "Natal International Airport" },
  { code: "SJK", name: "Sao Jose dos Campos Airport" },
  { code: "SLZ", name: "Sao Luis Airport" },
  { code: "RIA", name: "Santa Maria Airport" },
  { code: "STM", name: "Santarem Maestro Wilson Fonseca Airport" },
  { code: "SMT", name: "Sorriso Airport" },
  { code: "CGH", name: "Sao Paulo Congonhas Airport" },
  { code: "SJP", name: "Sao Jose Do Rio Preto Airport" },
  { code: "SSA", name: "Salvador International Airport" },
  { code: "TMT", name: "Porto Trombetas Airport" },
  { code: "TOW", name: "Toledo Airport" },
  { code: "THE", name: "Teresina Airport" },
  { code: "TFF", name: "Tefe Airport" },
  { code: "TJL", name: "Tres Lagoas Airport" },
  { code: "TBT", name: "Tabatinga International Airport" },
  { code: "TUR", name: "Tucurui Airport" },
  { code: "PAV", name: "Paulo Afonso Airport" },
  { code: "URG", name: "Uruguaiana Ruben Berta International Airport" },
  { code: "UDI", name: "Uberlandia Airport" },
  { code: "UBA", name: "Uberaba Airport" },
  { code: "VAG", name: "Varginha Airport" },
  { code: "BVH", name: "Vilhena Airport" },
  { code: "VIX", name: "Vitoria Eurico de Aguiar Salles Airport" },
  { code: "IZA", name: "Juiz de Fora Airport" },
  { code: "ARI", name: "Arica Chacalluta International Airport" },
  { code: "CPO", name: "Copiapo Desierto de Atacama Airport" },
  { code: "BBA", name: "Balmaceda Airport" },
  { code: "CJC", name: "Calama El Loa Airport" },
  { code: "PUQ", name: "Punta Arenas International Airport" },
  { code: "IQQ", name: "Iquique Diego Aracena International Airport" },
  { code: "SCL", name: "Santiago International Airport" },
  { code: "ANF", name: "Antofagasta Cerro Moreno International Airport" },
  { code: "CCP", name: "Concepcion Carriel Sur International Airport" },
  { code: "IPC", name: "Mataveri International Airport" },
  { code: "ZOS", name: "Osorno Airport" },
  { code: "PNT", name: "Puerto Natales Teniente Julio Gallardo Airport" },
  { code: "MHC", name: "Castro Mocopulli Airport" },
  { code: "ZCO", name: "Temuco Airport" },
  { code: "TNM", name: "King George Island Teniente R. Marsh Airport" },
  { code: "LSC", name: "La Serena La Florida Airport" },
  { code: "PMC", name: "Puerto Montt El Tepual Airport" },
  { code: "ZAL", name: "Valdivia Pichoy Airport" },
  { code: "SOD", name: "Sorocaba Airport" },
  { code: "QSC", name: "Sao Carlos Airport" },
  { code: "OCC", name: "El Coca Francisco de Orellana Airport" },
  { code: "CUE", name: "Cuenca Mariscal Lamar International Airport" },
  {
    code: "ESM",
    name: "Esmeraldas Carlos Concha Torres International Airport"
  },
  { code: "GPS", name: "Seymour Baltra Galapagos Airport" },
  { code: "GYE", name: "Guayaquil International Airport" },
  { code: "LOH", name: "Loja Ciudad de Catamayo Airport" },
  { code: "LTX", name: "Latacunga Cotopaxi International Airport" },
  { code: "MEC", name: "Manta Eloy Alfaro International Airport" },
  { code: "UIO", name: "Quito Mariscal Sucre International Airport" },
  { code: "SNC", name: "Salinas General Ulpiano Paez Airport" },
  { code: "SCY", name: "San Cristobal Airport" },
  { code: "PSY", name: "Stanley Airport" },
  { code: "ASU", name: "Asuncion Silvio Pettirossi International Airport" },
  { code: "AGT", name: "Ciudad del Este Guarani International Airport" },
  { code: "AXM", name: "Armenia El Eden International Airport" },
  { code: "PUU", name: "Puerto Asis Tres de Mayo Airport" },
  { code: "BGA", name: "Bucaramanga Palonegro International Airport" },
  { code: "BOG", name: "Bogota El Dorado International Airport" },
  { code: "BAQ", name: "Barranquilla Ernesto Cortissoz International Airport" },
  { code: "BSC", name: "Bahia Solano Jose Celestino Mutis Airport" },
  { code: "CUC", name: "Cucuta Camilo Daza International Airport" },
  { code: "CTG", name: "Cartagena Rafael Nunez International Airport" },
  { code: "CLO", name: "Cali Alfonso Bonilla Aragon International Airport" },
  { code: "TCO", name: "Tumaco La Florida Airport" },
  { code: "CAQ", name: "Caucasia Juan H. White Airport" },
  { code: "CZU", name: "Corozal Las Brujas Airport" },
  { code: "EBG", name: "El Bagre Airport" },
  { code: "EJA", name: "Barrancabermeja Yariguies Airport" },
  { code: "FLA", name: "Florencia Gustavo Artunduaga Paredes Airport" },
  { code: "IBE", name: "Ibague Perales Airport" },
  { code: "IPI", name: "Ipiales San Luis Airport" },
  { code: "APO", name: "Apartado Airport" },
  { code: "LQM", name: "Puerto Leguizamo Caucaya Airport" },
  { code: "LET", name: "Leticia International Airport" },
  { code: "EOH", name: "Medellin Enrique Olaya Herrera Airport" },
  { code: "MTR", name: "Monteria Los Garzones Airport" },
  { code: "MZL", name: "Manizales La Nubia Airport" },
  { code: "NQU", name: "Nuqui Airport" },
  { code: "NVA", name: "Neiva Benito Salas Airport" },
  { code: "PCR", name: "Puerto Carreno German Olano Airport" },
  { code: "PEI", name: "Pereira Matecana International Airport" },
  { code: "PTX", name: "Pitalito Airport" },
  { code: "PPN", name: "Popayan Guillermo Leon Valencia Airport" },
  { code: "PSO", name: "Pasto Antonio Narino Airport" },
  { code: "MDE", name: "Medellin Jose Maria Cordova International Airport" },
  { code: "RCH", name: "Riohacha Almirante Padilla Airport" },
  { code: "RVE", name: "Saravena Los Colonizadores Airport" },
  { code: "SMR", name: "Santa Marta Simon Bolívar International Airport" },
  { code: "ADZ", name: "San Andres International Airport" },
  { code: "TME", name: "Tame Gabriel Vargas Santos Airport" },
  { code: "AUC", name: "Arauca Santiago Perez Quiroz Airport" },
  { code: "UIB", name: "Quibdo El Carano" },
  { code: "VUP", name: "Valledupar Alfonso Lopez Pumarejo Airport" },
  { code: "VVC", name: "Villavicencio La Vanguardia Airport" },
  { code: "EYP", name: "Yopal El Alcaravan Airport" },
  { code: "SRE", name: "Sucre Alcantari International Airport" },
  { code: "CBB", name: "Cochabamba Jorge Wilstermann International Airport" },
  { code: "CIJ", name: "Cobija Capitan Anibal Arab Airport" },
  { code: "SRZ", name: "Santa Cruz El Trompillo Airport" },
  { code: "GYA", name: "Guayaramerin Airport" },
  { code: "LPB", name: "La Paz El Alto International Airport" },
  { code: "RIB", name: "Riberalta Airport" },
  { code: "RBQ", name: "Rurrenabaque Airport" },
  { code: "TJA", name: "Tarija Airport" },
  { code: "TDD", name: "Trinidad Teniente Jorge Henrich Arauz Airport" },
  { code: "UYU", name: "Uyuni Joya Andina Airport" },
  { code: "VVI", name: "Santa Cruz Viru Viru International Airport" },
  { code: "PBM", name: "Paramaribo Zanderij International Airport" },
  { code: "ORG", name: "Paramaribo Zorg en Hoop Airport" },
  { code: "BRA", name: "Barreiras Airport" },
  { code: "DIQ", name: "Divinopolis Airport" },
  { code: "GVR", name: "Governador Valadares Airport" },
  { code: "POJ", name: "Patos de Minas Airport" },
  { code: "TXF", name: "Teixeira de Freitas Airport" },
  { code: "VAL", name: "Valenca Airport" },
  { code: "CAY", name: "Cayenne Felix Eboue Airport" },
  { code: "PCL", name: "Pucallpa International Airport" },
  { code: "CIX", name: "Chiclayo International Airport" },
  { code: "AYP", name: "Ayacucho Airport" },
  { code: "CJA", name: "Cajamarca Airport" },
  { code: "LIM", name: "Lima Jorge Chavez International Airport" },
  { code: "JAE", name: "Jaen Shumba Airport" },
  { code: "JAU", name: "Jauja Francisco Carle Airport" },
  { code: "JUL", name: "Juliaca Inca Manco Capac International Airport" },
  { code: "TBP", name: "Tumbes Airport" },
  { code: "IQT", name: "Iquitos International Airport" },
  { code: "AQP", name: "Arequipa Rodriguez Ballon International Airport" },
  { code: "TRU", name: "Trujillo International Airport" },
  { code: "PIO", name: "Pisco Airport" },
  { code: "TPP", name: "Tarapoto Airport" },
  { code: "TCQ", name: "Tacna International Airport" },
  { code: "PEM", name: "Puerto Maldonado International Airport" },
  { code: "PIU", name: "Piura International Airport" },
  { code: "TYL", name: "Talara International Airport" },
  { code: "NZC", name: "Nazca Maria Reiche Neuman Airport" },
  { code: "CUZ", name: "Cusco Alejandro Velasco Astete International Airport" },
  { code: "OAL", name: "Cacoal Airport" },
  { code: "JJD", name: "Jericoacoara Airport" },
  { code: "PGZ", name: "Ponta Grossa Airport" },
  { code: "PDP", name: "Punta del Este International Airport" },
  { code: "MVD", name: "Montevideo Carrasco International Airport" },
  {
    code: "BLA",
    name: "Barcelona Jose Antonio Anzoategui International Airport"
  },
  { code: "BNS", name: "Barinas Airport" },
  { code: "BRM", name: "Barquisimeto Jacinto Lara International Airport" },
  { code: "CBL", name: "Ciudad Bolivar Tomas de Heres Airport" },
  { code: "CZE", name: "Coro Jose Leonardo Chirinos Airport" },
  { code: "CUM", name: "Cumana Antonio Jose de Sucre Airport" },
  { code: "LSP", name: "Las Piedras Josefa Camejo International Airport" },
  { code: "LFR", name: "La Fria Airport" },
  { code: "MAR", name: "Maracaibo La Chinita International Airport" },
  { code: "PMV", name: "Porlamar International Airport" },
  { code: "CCS", name: "Caracas Simon Bolivar International Airport" },
  { code: "MUN", name: "Maturin International Airport" },
  { code: "PBL", name: "Puerto Cabello Bartolome Salom International Airport" },
  { code: "PZO", name: "Puerto Ordaz International Airport" },
  { code: "LRV", name: "Los Roques Airport" },
  { code: "STD", name: "Santo Domingo Buenaventura Vivas Airport" },
  { code: "TUV", name: "Tucupita San Rafael Airport" },
  { code: "VLN", name: "Valencia Arturo Michelena International Airport" },
  { code: "VIG", name: "El Vigia Juan Pablo Perez Alfonso Airport" },
  { code: "BAZ", name: "Barcelos Airport" },
  { code: "CAF", name: "Carauari Airport" },
  { code: "GRP", name: "Gurupi Airport" },
  { code: "AUX", name: "Araguaina Airport" },
  { code: "CLV", name: "Caldas Novas Airport" },
  { code: "CIZ", name: "Coari Airport" },
  { code: "RVD", name: "Rio Verde Airport" },
  { code: "PIN", name: "Parintins Airport" },
  { code: "ROO", name: "Rondonopolis Airport" },
  { code: "OPS", name: "Sinop Airport" },
  { code: "GEO", name: "Georgetown Cheddi Jagan International Airport" },
  { code: "OGL", name: "Georgetown Eugene F. Correira International Airport" },
  { code: "ANU", name: "Antigua V. C. Bird International Airport" },
  { code: "AUW", name: "Wausau Downtown Airport" },
  { code: "BGI", name: "Bridgetown Grantley Adams International Airport" },
  { code: "DCF", name: "Roseau Canefield Airport" },
  { code: "DOM", name: "Dominica Douglas Charles Airport" },
  { code: "SFC", name: "Saint-Francois Airport" },
  { code: "FDF", name: "Martinique Aime Cesaire International Airport" },
  { code: "SFG", name: "Sint Maarten Grand Case Airport" },
  { code: "SBH", name: "St. Jean Gustaf III Airport" },
  { code: "PTP", name: "Pointe-a-Pitre International Airport" },
  { code: "GND", name: "Grenada Maurice Bishop International Airport" },
  { code: "STT", name: "St. Thomas Cyril E. King Airport" },
  { code: "STX", name: "St. Croix Henry E. Rohlsen Airport" },
  { code: "BQN", name: "Aguadilla Rafael Hernandez Airport" },
  { code: "VQS", name: "Vieques Antonio Rivera Rodriguez Airport" },
  { code: "CPX", name: "Isla De Culebra Benjamin Rivera Noriega Airport" },
  { code: "SIG", name: "San Juan Fernando Luis Ribas Dominicci Airport" },
  { code: "MAZ", name: "Mayaguez Eugenio María de Hostos" },
  { code: "PSE", name: "Ponce Mercedita Airport" },
  { code: "SJU", name: "San Juan Luis Munoz Marin International Airport" },
  { code: "SKB", name: "Basseterre Robert L. Bradshaw International Airport" },
  { code: "NEV", name: "Nevis Newcastle International Airport" },
  { code: "SLU", name: "Castries George F. L. Charles Airport" },
  { code: "UVF", name: "Vieux Fort Hewanorra International Airport" },
  { code: "AUA", name: "Oranjestad Queen Beatrix International Airport" },
  { code: "BON", name: "Bonaire Flamingo International Airport" },
  { code: "CUR", name: "Willemstad Curacao Hato International Airport" },
  { code: "EUX", name: "Saint Eustatius F.D. Roosevelt Airport" },
  { code: "SXM", name: "Sint Maarten Princess Juliana International Airport" },
  { code: "SAB", name: "Saba Juancho E. Yrausquin Airport" },
  { code: "AXA", name: "The Valley Clayton J. Lloyd International Airport" },
  { code: "MNI", name: "Montserrat John A. Osborne Airport" },
  { code: "TAB", name: "Scarborough International Airport" },
  { code: "POS", name: "Port of Spain Piarco International Airport" },
  { code: "NGD", name: "Anegada Auguste George Airport" },
  { code: "EIS", name: "Beef Island Terrance B. Lettsome Airport" },
  { code: "VIJ", name: "Virgin Gorda Airport" },
  { code: "SVD", name: "Kingstown Argyle International Airport" },
  { code: "BQU", name: "Bequia J.F. Mitchell Airport" },
  { code: "CIW", name: "Canouan Airport" },
  { code: "MQS", name: "Mustique Airport" },
  { code: "UNI", name: "Union Island Airport" },
  { code: "BDA", name: "Bermuda L.F. Wade International Airport" },
  { code: "ALA", name: "Almaty International Airport" },
  { code: "TDK", name: "Taldykorgan Airport" },
  { code: "TSE", name: "Astana Nursultan Nazarbayev International Airport" },
  { code: "DMB", name: "Taraz Airport" },
  { code: "CIT", name: "Shymkent International Airport" },
  { code: "KGF", name: "Karaganda Sary-Arka Airport" },
  { code: "KZO", name: "Kyzylorda Airport" },
  { code: "URA", name: "Oral Ak Zhol Airport" },
  { code: "UKK", name: "Oskemen Airport" },
  { code: "PWQ", name: "Pavlodar Airport" },
  { code: "PLX", name: "Semipalatinsk Semey Airport" },
  { code: "SCO", name: "Aktau Airport" },
  { code: "GUW", name: "Atyrau Airport" },
  { code: "AKX", name: "Aktobe Airport" },
  { code: "KSN", name: "Kostanay Airport" },
  { code: "GYD", name: "Baku Heydar Aliyev International Airport" },
  { code: "KVD", name: "Ganja International Airport" },
  { code: "LLK", name: "Lankaran International Airport" },
  { code: "NAJ", name: "Nakhchivan International Airport" },
  { code: "GBB", name: "Qabala International Airport" },
  { code: "IKU", name: "Tamchy Issyk-Kul International Airport" },
  { code: "FRU", name: "Bishkek Manas International Airport" },
  { code: "OSS", name: "Osh Airport" },
  { code: "LWN", name: "Gyumri Shirak Airport" },
  { code: "EVN", name: "Yerevan Zvartnots International Airport" },
  { code: "BQJ", name: "Batagay Airport" },
  { code: "UKG", name: "Ust-Kuyga Airport" },
  { code: "TLK", name: "Talakan Airport" },
  { code: "ADH", name: "Aldan Airport" },
  { code: "YKS", name: "Yakutsk Airport" },
  { code: "NER", name: "Chulman Airport" },
  { code: "MQJ", name: "Khonu Moma Airport" },
  { code: "OLZ", name: "Olyokminsk Airport" },
  { code: "NYR", name: "Nyurba Airport" },
  { code: "SUY", name: "Suntar Airport" },
  { code: "ULK", name: "Lensk Airport" },
  { code: "PYJ", name: "Udachny Polyarny Airport" },
  { code: "MJZ", name: "Mirny Airport" },
  { code: "CKH", name: "Chokurdakh Airport" },
  { code: "IKS", name: "Tiksi Airport" },
  { code: "ZKP", name: "Zyryanka Airport" },
  { code: "KUT", name: "Kutaisi International Airport" },
  { code: "BUS", name: "Batumi International Airport" },
  { code: "TBS", name: "Tbilisi International Airport" },
  { code: "BQS", name: "Blagoveshchensk Ignatyevo Airport" },
  { code: "KHV", name: "Khabarovsk Novy Airport" },
  { code: "KXK", name: "Komsomolsk-on-Amur Airport" },
  { code: "DYR", name: "Anadyr Ugolny Airport" },
  { code: "KPW", name: "Keperveyem Airport" },
  { code: "GDX", name: "Magadan Sokol Airport" },
  { code: "PWE", name: "Pevek Airport" },
  { code: "PKC", name: "Petropavlovsk-Kamchatsky Airport" },
  { code: "OHH", name: "Okha Airport" },
  { code: "EKS", name: "Shakhtyorsk Airport" },
  { code: "DEE", name: "Yuzhno-Kurilsk Mendeleyevo Airport" },
  { code: "NGK", name: "Nogliki Airport" },
  { code: "UUS", name: "Yuzhno-Sakhalinsk Airport" },
  { code: "VVO", name: "Vladivostok International Airport" },
  { code: "HTA", name: "Chita Kadala Airport" },
  { code: "BTK", name: "Bratsk Airport" },
  { code: "IKT", name: "Irkutsk International Airport" },
  { code: "ODO", name: "Bodaybo Airport" },
  { code: "KCK", name: "Kirensk Airport" },
  { code: "UKX", name: "Ust-Kut Airport" },
  { code: "UUD", name: "Ulan-Ude Airport" },
  { code: "KBP", name: "Kiev Boryspil International Airport" },
  { code: "DOK", name: "Donetsk Sergey Prokofiev International Airport" },
  { code: "DNK", name: "Dnipro Dnipropetrovsk International Airport" },
  { code: "OZH", name: "Zaporizhzhia International Airport" },
  { code: "KWG", name: "Kryvyi Rih International Airport" },
  { code: "SIP", name: "Simferopol International Airport" },
  { code: "HRK", name: "Kharkiv International Airport" },
  { code: "PLV", name: "Poltava International  Airport" },
  { code: "IEV", name: "Kiev Zhuliany Airport" },
  { code: "GML", name: "Kiev Gostomel Airport" },
  { code: "IFO", name: "Ivano-Frankivsk International Airport" },
  { code: "LWO", name: "Lviv Danylo Halytskyi International Airport" },
  { code: "CWC", name: "Chernivtsi International Airport" },
  { code: "RWN", name: "Rivne International Airport" },
  { code: "UDJ", name: "Uzhhorod International Airport" },
  { code: "KHE", name: "Kherson International Airport" },
  { code: "NLV", name: "Mykolaiv International Airport" },
  { code: "ODS", name: "Odesa International Airport" },
  { code: "VIN", name: "Vinnytsia Havryshivka International Airport" },
  { code: "ARH", name: "Arkhangelsk Talagi Airport" },
  { code: "NNM", name: "Naryan-Mar Airport" },
  { code: "KSZ", name: "Kotlas Airport" },
  { code: "LED", name: "St. Petersburg Pulkovo Airport" },
  { code: "KVK", name: "Apatity Airport" },
  { code: "MMK", name: "Murmansk Airport" },
  { code: "PKV", name: "Pskov Airport" },
  { code: "PES", name: "Petrozavodsk Airport" },
  { code: "CEE", name: "Cherepovets Airport" },
  { code: "VGD", name: "Vologda Airport" },
  { code: "BQT", name: "Brest Airport" },
  { code: "GME", name: "Gomel Airport" },
  { code: "VTB", name: "Vitebsk Vostochny Airport" },
  { code: "KGD", name: "Kaliningrad Khrabrovo Airport" },
  { code: "GNA", name: "Grodno Hrodna Airport" },
  { code: "MSQ", name: "Minsk International Airport" },
  { code: "MVQ", name: "Mogilev Airport" },
  { code: "ABA", name: "Abakan International Airport" },
  { code: "BAX", name: "Barnaul Airport" },
  { code: "RGK", name: "Gorno-Altaysk Airport" },
  { code: "KEJ", name: "Kemerovo International Airport" },
  { code: "TGP", name: "Bor Podkamennaya Tunguska Airport" },
  { code: "KJA", name: "Krasnoyarsk International Airport" },
  { code: "KYZ", name: "Kyzyl Airport" },
  { code: "OVB", name: "Novosibirsk Tolmachevo Airport" },
  { code: "OMS", name: "Omsk Tsentralny Airport" },
  { code: "SWT", name: "Strezhevoy Airport" },
  { code: "TOF", name: "Tomsk Bogashevo Airport" },
  { code: "NOZ", name: "Novokuznetsk Spichenkovo Airport" },
  { code: "DKS", name: "Dikson Airport" },
  { code: "HTG", name: "Khatanga Airport" },
  { code: "IAA", name: "Igarka Airport" },
  { code: "NSK", name: "Norilsk Alykel Airport" },
  { code: "THX", name: "Turukhansk Airport" },
  { code: "AAQ", name: "Anapa Airport" },
  { code: "EIK", name: "Yeysk Airport" },
  { code: "GDZ", name: "Gelendzhik Airport" },
  { code: "KRR", name: "Krasnodar International Airport" },
  { code: "GRV", name: "Grozny Airport" },
  { code: "MCX", name: "Makhachkala Uytash Airport" },
  { code: "MRV", name: "Mineralnye Vody Airport" },
  { code: "NAL", name: "Nalchik Airport" },
  { code: "OGZ", name: "Beslan Airport" },
  { code: "IGT", name: "Magas Airport" },
  { code: "STW", name: "Stavropol Shpakovskoye Airport" },
  { code: "ROV", name: "Rostov-on-Don Platov International Airport" },
  { code: "AER", name: "Sochi International Airport" },
  { code: "ASF", name: "Astrakhan Narimanovo Airport" },
  { code: "ESL", name: "Elista Airport" },
  { code: "VOG", name: "Volgograd International Airport" },
  { code: "CEK", name: "Chelyabinsk Airport" },
  { code: "MQF", name: "Magnitogorsk International Airport" },
  { code: "SBT", name: "Sabetta International Airport" },
  { code: "SLY", name: "Salekhard Airport" },
  { code: "TQL", name: "Tarko-Sale Airport" },
  { code: "EZV", name: "Beryozovo Airport" },
  { code: "HMA", name: "Khanty-Mansiysk Airport" },
  { code: "IRM", name: "Igrim Airport" },
  { code: "NYA", name: "Nyagan Airport" },
  { code: "EYK", name: "Beloyarsk Airport" },
  { code: "OVS", name: "Sovetsky Airport" },
  { code: "URJ", name: "Uray Airport" },
  { code: "IJK", name: "Izhevsk Airport" },
  { code: "KVX", name: "Kirov Pobedilovo Airport" },
  { code: "NYM", name: "Nadym Airport" },
  { code: "NUX", name: "Novy Urengoy Airport" },
  { code: "NJC", name: "Nizhnevartovsk Airport" },
  { code: "PEE", name: "Perm Bolshoye Savino Airport" },
  { code: "KGP", name: "Kogalym International Airport" },
  { code: "NOJ", name: "Noyabrsk Airport" },
  { code: "SGC", name: "Surgut International Airport" },
  { code: "SVX", name: "Yekaterinburg Koltsovo Airport" },
  { code: "TJM", name: "Roschino International Airport" },
  { code: "KRO", name: "Kurgan Airport" },
  { code: "ASB", name: "Ashgabat International Airport" },
  { code: "KRW", name: "Turkmenbashi International Airport" },
  { code: "MYP", name: "Mary International Airport" },
  { code: "TAZ", name: "Dasoguz Airport" },
  { code: "CRZ", name: "Turkmenabat Airport" },
  { code: "DYU", name: "Dushanbe International Airport" },
  { code: "TJU", name: "Kulob Airport" },
  { code: "LBD", name: "Khudzhand Khujand Airport" },
  { code: "KQT", name: "Kurgan Tyube Qurghonteppa International Airport" },
  { code: "AZN", name: "Andizhan Airport" },
  { code: "FEG", name: "Fergana International Airport" },
  { code: "NMA", name: "Namangan Airport" },
  { code: "NCU", name: "Nukus Airport" },
  { code: "UGC", name: "Urgench International Airport" },
  { code: "NVI", name: "Navoi International Airport" },
  { code: "BHK", name: "Bukhara International Airport" },
  { code: "KSQ", name: "Karshi Airport" },
  { code: "SKD", name: "Samarkand International Airport" },
  { code: "TMJ", name: "Termez Airport" },
  { code: "TAS", name: "Tashkent International Airport" },
  { code: "KMW", name: "Kostroma Airport" },
  { code: "KLF", name: "Kaluga Grabtsevo Airport" },
  { code: "IWA", name: "Ivanovo Yuzhny Airport" },
  { code: "BZK", name: "Bryansk International Airport" },
  { code: "ZIA", name: "Moscow Zhukovsky Airport" },
  { code: "DME", name: "Moscow Domodedovo International Airport" },
  { code: "IAR", name: "Yaroslavl Tunoshna Airport" },
  { code: "SVO", name: "Moscow Sheremetyevo International Airport" },
  { code: "OSF", name: "Moscow Ostafyevo International Airport" },
  { code: "CKL", name: "Moscow Chkalovskiy Airport" },
  { code: "EGO", name: "Belgorod International Airport" },
  { code: "URS", name: "Kursk Airport" },
  { code: "LPK", name: "Lipetsk Airport" },
  { code: "VOZ", name: "Voronezh International Airport" },
  { code: "TBW", name: "Tambov Donskoye Airport" },
  { code: "VKO", name: "Moscow Vnukovo International Airport" },
  { code: "UCT", name: "Ukhta Airport" },
  { code: "INA", name: "Inta Airport" },
  { code: "PEX", name: "Pechora Airport" },
  { code: "USK", name: "Usinsk Airport" },
  { code: "VKT", name: "Vorkuta Airport" },
  { code: "UTS", name: "Ust-Tsylma Airport" },
  { code: "SCW", name: "Syktyvkar Airport" },
  { code: "GOJ", name: "Nizhny Novgorod International Airport" },
  { code: "UUA", name: "Bugulma Airport" },
  { code: "KZN", name: "Kazan International Airport" },
  { code: "NBC", name: "Naberezhnye Chelny Begishevo Airport" },
  { code: "JOK", name: "Yoshkar-Ola Airport" },
  { code: "CSY", name: "Cheboksary Airport" },
  { code: "ULV", name: "Ulyanovsk Baratayevka Airport" },
  { code: "ULY", name: "Ulyanovsk Vostochny Airport" },
  { code: "REN", name: "Orenburg Tsentralny Airport" },
  { code: "OSW", name: "Orsk Airport" },
  { code: "PEZ", name: "Penza Airport" },
  { code: "SKX", name: "Saransk Airport" },
  { code: "RTW", name: "Saratov Tsentralny Airport" },
  { code: "UFA", name: "Ufa International Airport" },
  { code: "KUF", name: "Samara Kurumoch International Airport" },
  { code: "DIU", name: "Diu Airport" },
  { code: "AMD", name: "Ahmedabad International Airport" },
  { code: "IXU", name: "Aurangabad Airport" },
  { code: "BOM", name: "Mumbai Chhatrapati Shivaji International Airport" },
  { code: "BHJ", name: "Bhuj Airport" },
  { code: "IXG", name: "Belgaum Airport" },
  { code: "BDQ", name: "Vadodara Airport" },
  { code: "BHO", name: "Bhopal Raja Bhoj Airport" },
  { code: "BHU", name: "Bhavnagar Airport" },
  { code: "IDR", name: "Indore Devi Ahilyabai Holkar Airport" },
  { code: "JLR", name: "Jabalpur Airport" },
  { code: "JLG", name: "Jalgaon Airport" },
  { code: "JGA", name: "Jamnagar Airport" },
  { code: "IXY", name: "Kandla Airport" },
  { code: "HJR", name: "Khajuraho Airport" },
  { code: "KLH", name: "Kolhapur Airport" },
  { code: "NDC", name: "Nanded Airport" },
  { code: "NAG", name: "Nagpur Dr. Babasaheb Ambedkar International Airport" },
  { code: "ISK", name: "Nashik Ozar Airport" },
  { code: "PNQ", name: "Pune Airport" },
  { code: "PBD", name: "Porbandar Airport" },
  { code: "RAJ", name: "Rajkot Airport" },
  { code: "RPR", name: "Raipur Swami Vivekananda Airport" },
  { code: "SAG", name: "Shirdi Airport" },
  { code: "STV", name: "Surat Airport" },
  { code: "UDR", name: "Udaipur Maharana Pratap Airport" },
  { code: "CMB", name: "Colombo Bandaranaike International Airport" },
  { code: "HRI", name: "Hambantota Mattala Rajapaksa International Airport" },
  { code: "PNH", name: "Phnom Penh International Airport" },
  { code: "REP", name: "Siem Reap International Airport" },
  { code: "KOS", name: "Sihanoukville International Airport" },
  { code: "IXA", name: "Agartala Airport" },
  { code: "IXB", name: "Siliguri Bagdogra Airport" },
  { code: "SHL", name: "Shillong Airport" },
  { code: "BBI", name: "Bhubaneswar Biju Patnaik International Airport" },
  { code: "CCU", name: "Kolkata International Airport" },
  { code: "RDP", name: "Andal Kazi Nazrul Islam Airport" },
  { code: "GOP", name: "Gorakhpur Airport" },
  { code: "GAU", name: "Guwahati International Airport" },
  { code: "GAY", name: "Gaya Airport" },
  { code: "IMF", name: "Imphal Airport" },
  { code: "JRG", name: "Jharsuguda Veer Surendra Sai Airport" },
  { code: "JRH", name: "Jorhat Airport" },
  { code: "IXS", name: "Silchar Airport" },
  { code: "AJL", name: "Aizawl Lengpui Airport" },
  { code: "DIB", name: "Dibrugarh Airport" },
  { code: "DMU", name: "Dimapur Airport" },
  { code: "IXT", name: "Pasighat Airport" },
  { code: "PAT", name: "Patna Jay Prakash Narayan International Airport" },
  { code: "PYG", name: "Pakyong Airport" },
  { code: "IXR", name: "Ranchi Birsa Munda Airport" },
  { code: "VTZ", name: "Visakhapatnam Airport" },
  { code: "BZL", name: "Barisal Airport" },
  { code: "CXB", name: "Cox's Bazar Airport" },
  { code: "CGP", name: "Chittagong Shah Amanat International Airport" },
  { code: "DAC", name: "Dhaka Shahjalal International Airport" },
  { code: "JSR", name: "Jessore Airport" },
  { code: "RJH", name: "Rajshahi Shah Makhdum Airport" },
  { code: "SPD", name: "Saidpur Airport" },
  { code: "ZYL", name: "Sylhet Osmani International Airport" },
  { code: "HKG", name: "Hong Kong International Airport" },
  { code: "AGR", name: "Agra Airport" },
  { code: "IXD", name: "Allahabad Airport" },
  { code: "ATQ", name: "Amritsar International Airport" },
  { code: "AIP", name: "Adampur Airport" },
  { code: "BKB", name: "Bikaner Nal Airport" },
  { code: "VNS", name: "Varanasi International Airport" },
  { code: "KUU", name: "Kullu Bhuntar Airport" },
  { code: "BUP", name: "Bathinda Airport" },
  { code: "IXC", name: "Chandigarh Airport" },
  { code: "DED", name: "Dehradun Jolly Grant Airport" },
  { code: "DEL", name: "Delhi Indira Gandhi International Airport" },
  { code: "DHM", name: "Dharamsala Gaggal Kangra Airport" },
  { code: "GWL", name: "Gwalior Airport" },
  { code: "JDH", name: "Jodhpur Airport" },
  { code: "JAI", name: "Jaipur Airport" },
  { code: "JSA", name: "Jaisalmer Airport" },
  { code: "IXJ", name: "Jammu Airport" },
  { code: "KNU", name: "Kanpur Airport" },
  { code: "KQH", name: "Kishangarh Airport" },
  { code: "LUH", name: "Ludhiana Sahnewal Airport" },
  { code: "IXL", name: "Leh Kushok Bakula Rimpochee Airport" },
  { code: "LKO", name: "Lucknow Chaudhary Charan Singh Airport" },
  { code: "IXP", name: "Pathankot Airport" },
  { code: "PGH", name: "Pantnagar Airport" },
  { code: "SLV", name: "Shimla Airport" },
  { code: "SXR", name: "Srinagar Airport" },
  { code: "HOE", name: "Ban Houeisay Airport" },
  { code: "LPQ", name: "Luang Prabang International Airport" },
  { code: "LXG", name: "Luang Namtha Airport" },
  { code: "ODY", name: "Muang Xay Oudomsay Airport" },
  { code: "PKZ", name: "Pakse International Airport" },
  { code: "ZVK", name: "Savannakhet Airport" },
  { code: "VTE", name: "Vientiane Wattay International Airport" },
  { code: "XKH", name: "Xieng Khouang Airport" },
  { code: "MFM", name: "Macau International Airport" },
  { code: "BGL", name: "Baglung Airport" },
  { code: "BHR", name: "Bharatpur Airport" },
  { code: "BWA", name: "Siddharthanagar Bhairahawa Airport" },
  { code: "BDP", name: "Bhadrapur Airport" },
  { code: "DHI", name: "Dhangadhi Airport" },
  { code: "DOP", name: "Dolpa Airport" },
  { code: "JUM", name: "Jumla Airport" },
  { code: "JKR", name: "Janakpur Airport" },
  { code: "JMO", name: "Jomsom Airport" },
  { code: "KTM", name: "Kathmandu Tribhuvan International Airport" },
  { code: "LUA", name: "Lukla Tenzing Hillary Airport" },
  { code: "KEP", name: "Nepalgunj Airport" },
  { code: "PKR", name: "Pokhara Airport" },
  { code: "PPL", name: "Phaplu Airport" },
  { code: "SIF", name: "Simara Airport" },
  { code: "SKH", name: "Surkhet Airport" },
  { code: "IMK", name: "Simikot Airport" },
  { code: "TMI", name: "Tumlingtar Airport" },
  { code: "BIR", name: "Biratnagar Airport" },
  { code: "AGX", name: "Agatti Airport" },
  { code: "BLR", name: "Bengaluru Kempegowda International Airport" },
  { code: "VGA", name: "Vijayawada Airport" },
  { code: "CJB", name: "Coimbatore International Airport" },
  { code: "COK", name: "Cochin International Airport" },
  { code: "CCJ", name: "Kozhikode Calicut International Airport" },
  { code: "CDP", name: "Cuddapah Kadapa Airport" },
  { code: "GOI", name: "Goa International Airport" },
  { code: "HBX", name: "Hubli Airport" },
  { code: "HYD", name: "Hyderabad Rajiv Gandhi International Airport" },
  { code: "CNN", name: "Kannur International Airport" },
  { code: "IXM", name: "Madurai Airport" },
  { code: "IXE", name: "Mangalore Airport" },
  { code: "MAA", name: "Chennai International Airport" },
  { code: "MYQ", name: "Mysore Airport" },
  { code: "IXZ", name: "Port Blair Veer Savarkar International Airport" },
  { code: "PNY", name: "Pondicherry Airport" },
  { code: "RJA", name: "Rajahmundry Airport" },
  { code: "TCR", name: "Tuticorin Airport" },
  { code: "TIR", name: "Tirupati Airport" },
  { code: "TRZ", name: "Tiruchirappalli International Airport" },
  { code: "TRV", name: "Trivandrum International Airport" },
  { code: "BUT", name: "Jakar Bathpalathang Airport" },
  { code: "GLU", name: "Gelephu Airport" },
  { code: "PBH", name: "Paro Airport" },
  { code: "YON", name: "Trashigang Yongphulla Airport" },
  { code: "IFU", name: "Ifuru Airport" },
  { code: "DRV", name: "Dharavandhoo Airport" },
  { code: "GAN", name: "Gan International Airport" },
  { code: "HAQ", name: "Hanimaadhoo International Airport" },
  { code: "KDO", name: "Kadhdhoo Airport" },
  { code: "MLE", name: "Male Velana International Airport" },
  { code: "GKK", name: "Kooddoo Airport" },
  { code: "FVM", name: "Fuvahmulah Airport" },
  { code: "KDM", name: "Kaadedhdhoo Airport" },
  { code: "DDD", name: "Dhaalu Kudahuvadhoo Airport" },
  { code: "VAM", name: "Maamigili Villa International Airport" },
  { code: "TMF", name: "Thimarafushi Airport" },
  { code: "DMK", name: "Bangkok Don Mueang International Airport" },
  { code: "TDX", name: "Trat Airport" },
  { code: "BKK", name: "Bangkok Suvarnabhumi Airport" },
  { code: "UTP", name: "Rayong Pattaya U-Tapao International Airport" },
  { code: "CNX", name: "Chiang Mai International Airport" },
  { code: "HGN", name: "Mae Hong Son Airport" },
  { code: "LPT", name: "Lampang Airport" },
  { code: "NNT", name: "Nan Airport" },
  { code: "PRH", name: "Phrae Airport" },
  { code: "CEI", name: "Chiang Rai International Airport" },
  { code: "HHQ", name: "Hua Hin Airport" },
  { code: "MAQ", name: "Mae Sot Airport" },
  { code: "THS", name: "Sukhothai Airport" },
  { code: "PHS", name: "Phitsanulok Airport" },
  { code: "URT", name: "Surat Thani Airport" },
  { code: "NAW", name: "Narathiwat Airport" },
  { code: "CJM", name: "Chumphon Airport" },
  { code: "NST", name: "Nakhon Si Thammarat Airport" },
  { code: "KBV", name: "Krabi Airport" },
  { code: "SGZ", name: "Songkhla Airport" },
  { code: "USM", name: "Koh Samui Airport" },
  { code: "HKT", name: "Phuket International Airport" },
  { code: "UNN", name: "Ranong Airport" },
  { code: "HDY", name: "Hat Yai International Airport" },
  { code: "TST", name: "Trang Airport" },
  { code: "UTH", name: "Udon Thani International Airport" },
  { code: "SNO", name: "Sakon Nakhon Airport" },
  { code: "KKC", name: "Khon Kaen Airport" },
  { code: "LOE", name: "Loei Airport" },
  { code: "BFV", name: "Buriram Airport" },
  { code: "KOP", name: "Nakhon Phanom Airport" },
  { code: "NAK", name: "Nakhon Ratchasima Airport" },
  { code: "UBP", name: "Ubon Ratchathani Airport" },
  { code: "ROI", name: "Roi Et Airport" },
  { code: "BMV", name: "Buon Ma Thuot Airport" },
  { code: "VCL", name: "Chu Lai International Airport" },
  { code: "HPH", name: "Haiphong Cat Bi International Airport" },
  { code: "CAH", name: "Ca Mau Airport" },
  { code: "CXR", name: "Nha Trang Cam Ranh International Airport" },
  { code: "VCS", name: "Con Dao Airport" },
  { code: "VCA", name: "Can Tho International Airport" },
  { code: "DIN", name: "Dien Bien Phu Airport" },
  { code: "VDH", name: "Dong Hoi Airport" },
  { code: "DLI", name: "Da Lat Lien Khuong Airport" },
  { code: "DAD", name: "Da Nang International Airport" },
  { code: "HAN", name: "Hanoi Noi Bai International Airport" },
  { code: "HUI", name: "Hue Phu Bai International Airport" },
  { code: "UIH", name: "Qui Nhon Phu Cat Airport" },
  { code: "PXU", name: "Pleiku Airport" },
  { code: "PQC", name: "Phu Quoc International Airport" },
  { code: "VKG", name: "Rach Gia Airport" },
  { code: "TBB", name: "Tuy Hoa Dong Tac Airport" },
  { code: "SGN", name: "Ho Chi Minh City International Airport" },
  { code: "THD", name: "Sao Vang Tho Xuan Airport" },
  { code: "VDO", name: "Quang Ninh Van Don International Airport" },
  { code: "VII", name: "Vinh Airport" },
  { code: "NYU", name: "Nyaung U Airport" },
  { code: "BMO", name: "Bhamo Banmaw Airport" },
  { code: "VBC", name: "Mandalay Chanmyathazi Airport" },
  { code: "TVY", name: "Dawei Airport" },
  { code: "HEH", name: "Heho Airport" },
  { code: "HOX", name: "Hommalinn Airport" },
  { code: "KET", name: "Kengtung Airport" },
  { code: "KHM", name: "Khamti Airport" },
  { code: "KMV", name: "Kalaymyo Airport" },
  { code: "KYP", name: "Kyaukpyu Airport" },
  { code: "KAW", name: "Kawthaung Airport" },
  { code: "LIW", name: "Loikaw Airport" },
  { code: "LSH", name: "Lashio Airport" },
  { code: "MDL", name: "Mandalay International Airport" },
  { code: "MGZ", name: "Myeik Airport" },
  { code: "MYT", name: "Myitkyina Airport" },
  { code: "MNU", name: "Mawlamyine Airport" },
  { code: "NYW", name: "Monywa Airport" },
  { code: "NYT", name: "Naypyidaw Airport" },
  { code: "PBU", name: "Putao Airport" },
  { code: "AKY", name: "Sittwe Airport" },
  { code: "SNW", name: "Thandwe Airport" },
  { code: "THL", name: "Tachilek Airport" },
  { code: "RGN", name: "Yangon International Airport" },
  { code: "UPG", name: "Makassar Sultan Hasanuddin International Airport" },
  { code: "BUW", name: "Bau-Bau Betoambari Airport" },
  { code: "PUM", name: "Pomala Airport" },
  { code: "BIK", name: "Biak Frans Kaisiepo Airport" },
  { code: "NBX", name: "Nabire Airport" },
  { code: "TIM", name: "Timika Airport" },
  { code: "BMU", name: "Bima Sultan Muhammad Salahudin Airport" },
  { code: "DPS", name: "Denpasar Ngurah Rai International Airport" },
  { code: "LOP", name: "Lombok International Airport" },
  { code: "SWQ", name: "Sumbawa Besar Airport" },
  { code: "TMC", name: "Tambolaka Airport" },
  { code: "WGP", name: "Waingapu Mau Hau Airport" },
  { code: "LLO", name: "Palopo Lagaligo Airport" },
  { code: "MJU", name: "Mamuju Tampa Padang Airport" },
  { code: "TJS", name: "Tanjung Selor Tanjung Harapan Airport" },
  { code: "PKY", name: "Palangkaraya Tjilik Riwut Airport" },
  { code: "JOG", name: "Yogyakarta Adisucipto International Airport" },
  { code: "YIA", name: "Yogyakarta International Airport" },
  { code: "SOC", name: "Surakarta Adisumarmo International Airport" },
  { code: "SRG", name: "Semarang Achmad Yani International Airport" },
  { code: "DJJ", name: "Jayapura Sentani Airport" },
  { code: "WMX", name: "Wamena Airport" },
  { code: "OKL", name: "Oksibil Airport" },
  { code: "MKQ", name: "Merauke Mopah International Airport" },
  { code: "TMH", name: "Tanahmerah Airport" },
  { code: "BPN", name: "Balikpapan Sepinggan Airport" },
  { code: "AAP", name: "Samarinda AP Tumenggung Pranoto Airport" },
  { code: "GTO", name: "Gorontalo Jalaluddin Airport" },
  { code: "NAH", name: "Tahuna Naha Airport" },
  { code: "PLW", name: "Palu Mutiara Airport" },
  { code: "MDC", name: "Manado Sam Ratulangi International Airport" },
  { code: "MNA", name: "Melangguane Airport" },
  { code: "TTE", name: "Ternate Babullah Airport" },
  { code: "LUW", name: "Luwuk Bubung Airport" },
  { code: "UOL", name: "Buol Airport" },
  { code: "BTW", name: "Batu Licin Airport" },
  { code: "PKN", name: "Pangkalan Bun Iskandar Airport" },
  { code: "KBU", name: "Kotabaru Gusti Syamsir Alam Airport" },
  { code: "BDJ", name: "Banjarmasin Syamsudin Noor Airport" },
  { code: "SMQ", name: "Sampit Airport" },
  { code: "LUV", name: "Langgur Karel Sadsuitubun Airport" },
  { code: "LAH", name: "Labuha Oesman Sadik Airport" },
  { code: "SXK", name: "Saumlaki Mathilda Batlayeri Airport" },
  { code: "AMQ", name: "Ambon Pattimura Airport" },
  { code: "NNX", name: "Nunukan Airport" },
  { code: "TRK", name: "Tarakan Juwata International Airport" },
  { code: "BEJ", name: "Kalimarau Airport" },
  { code: "MLG", name: "Malang Abdul Rachman Saleh Airport" },
  { code: "BWX", name: "Blimbingsari Airport" },
  { code: "JBB", name: "Jember Notohadinegoro Airport" },
  { code: "SUB", name: "Surabaya Juanda International Airport" },
  { code: "KEQ", name: "Kebar Airport" },
  { code: "FKQ", name: "Fakfak Torea Airport" },
  { code: "KNG", name: "Kaimana Utarom Airport" },
  { code: "BXB", name: "Babo Airport" },
  { code: "MKW", name: "Manokwari Rendani Airport" },
  { code: "SOQ", name: "Sorong Dominique Edward Osok Airport" },
  { code: "ABU", name: "Atambua Haliwen Airport" },
  { code: "BJW", name: "Bajawa Turelelo Soa Airport" },
  { code: "MOF", name: "Maumere Frans Seda Airport" },
  { code: "ENE", name: "Ende H. Hasan Aroeboesman Airport" },
  { code: "LKA", name: "Larantuka Gewayantana Airport" },
  { code: "ARD", name: "Alor Island Airport" },
  { code: "LBJ", name: "Labuan Bajo Komodo Airport" },
  { code: "RTI", name: "Roti David C. Saudale Airport" },
  { code: "KOE", name: "Kupang El Tari Airport" },
  { code: "DEX", name: "Dekai Nop Goliat Airport" },
  { code: "RAQ", name: "Raha Sugimanuru Airport" },
  { code: "KDI", name: "Kendari Haluoleo Airport" },
  { code: "BTU", name: "Bintulu Airport" },
  { code: "LGL", name: "Long Lellang Airport" },
  { code: "KCH", name: "Kuching International Airport" },
  { code: "ODN", name: "\r\nLong Seridan Airport" },
  { code: "LMN", name: "Limbang Airport" },
  { code: "MKM", name: "Mukah Airport" },
  { code: "LKH", name: "Long Akah Airport" },
  { code: "MUR", name: "Marudi Airport" },
  { code: "BKM", name: "Bakelalan Airport" },
  { code: "MYY", name: "Miri Airport" },
  { code: "SBW", name: "Sibu Airport" },
  { code: "LWY", name: "Lawas Airport" },
  { code: "BBN", name: "Bario Airport" },
  { code: "LDU", name: "Lahad Datu Airport" },
  { code: "BKI", name: "Kota Kinabalu International Airport" },
  { code: "LBU", name: "Labuan Airport" },
  { code: "SDK", name: "Sandakan Airport" },
  { code: "KUD", name: "Kudat Airport" },
  { code: "TWU", name: "Tawau Airport" },
  { code: "MZV", name: "Mulu Airport" },
  { code: "BWN", name: "Brunei International Airport" },
  { code: "TGC", name: "Tanjung Manis Airport" },
  { code: "SBG", name: "Sabang Maimun Saleh Airport" },
  { code: "PKU", name: "Pekanbaru Sultan Syarif Kasim II Airport" },
  { code: "DUM", name: "Dumai Pinang Kampai Airport" },
  { code: "RGT", name: "Rengat Japura Airport" },
  { code: "KJT", name: "Majalengka Kertajati International Airport" },
  { code: "BDO", name: "Bandung Husein Sastranegara International Airport" },
  { code: "TSY", name: "Tasikmalaya Cibeureum Airport" },
  { code: "TKG", name: "Bandar Lampung Radin Inten II Airport" },
  { code: "BTH", name: "Batam Hang Nadim Airport" },
  { code: "HLP", name: "Jakarta Halim Perdanakusuma Airport" },
  { code: "CGK", name: "Jakarta Soekarno Hatta International Airport" },
  { code: "BUU", name: "Muara Bungo Airport" },
  { code: "DJB", name: "Jambi Sultan Thaha Airport" },
  { code: "TJQ", name: "Tanjung Pandan Buluh Tumbang Airport" },
  { code: "TNJ", name: "Tanjung Pinang Raja Haji Fisabilillah Airport" },
  { code: "GNS", name: "Gunung Sitoli Binaka Airport" },
  { code: "PDG", name: "Padang Minangkabau International Airport" },
  { code: "KNO", name: "Medan Kuala Namu International Airport" },
  { code: "DTB", name: "Siborong-Borong Silangit Airport" },
  { code: "FLZ", name: "Sibolga Ferdinand Lumban Tobing Airport" },
  { code: "NPO", name: "Nanga Pinoh Airport" },
  { code: "KTG", name: "Ketapang Airport" },
  { code: "MWK", name: "Matak Tarempa Airport" },
  { code: "NTX", name: "Ranai Airport" },
  { code: "PNK", name: "Pontianak Supadio Airport" },
  { code: "PSU", name: "Putussibau Pangsuma Airport" },
  { code: "SQG", name: "Sintang Airport" },
  { code: "PGK", name: "Pangkal Pinang Airport" },
  { code: "BKS", name: "Bengkulu Fatmawati Soekarno Airport" },
  { code: "PLM", name: "Palembang International Airport" },
  { code: "LSW", name: "Lhokseumawe Malikus Saleh Airport" },
  { code: "BTJ", name: "Banda Aceh International Airport" },
  { code: "AOR", name: "Alor Setar Sultan Abdul Halim Airport" },
  { code: "KBR", name: "Kota Bharu Sultan Ismail Petra Airport" },
  { code: "KUA", name: "Kuantan Sultan Haji Ahmad Shah Airport" },
  { code: "KTE", name: "Kerteh Airport" },
  { code: "IPH", name: "Ipoh Sultan Azlan Shah Airport" },
  { code: "JHB", name: "Johor Bahru Senai International Airport" },
  { code: "KUL", name: "Kuala Lumpur International Airport" },
  { code: "LGK", name: "Langkawi International Airport" },
  { code: "MKZ", name: "Malacca International Airport" },
  { code: "TGG", name: "Kuala Terengganu Sultan Mahmud Airport" },
  { code: "PEN", name: "Penang International Airport" },
  { code: "SZB", name: "Kuala Lumpur Subang Airport" },
  { code: "DIL", name: "Dili Presidente Nicolau Lobato International Airport" },
  { code: "RTG", name: "Ruteng Frans Sales Lega Airport" },
  { code: "XSP", name: "Seletar Airport" },
  { code: "SIN", name: "Singapore Changi Airport" },
  { code: "ALH", name: "Albany Airport" },
  { code: "WLP", name: "West Angelas Airport" },
  { code: "GYL", name: "Argyle Airport" },
  { code: "ARM", name: "Armidale Airport" },
  { code: "AUU", name: "Aurukun Airport" },
  { code: "AYQ", name: "Ayers Rock Airport" },
  { code: "BCI", name: "Barcaldine Airport" },
  { code: "ASP", name: "Alice Springs Airport" },
  { code: "BDD", name: "Badu Island Airport" },
  { code: "BNE", name: "Brisbane Airport" },
  { code: "OOL", name: "Gold Coast Airport" },
  { code: "BKQ", name: "Blackall Airport" },
  { code: "CNS", name: "Cairns Airport" },
  { code: "CTL", name: "Charleville Airport" },
  { code: "BXG", name: "Bendigo Airport" },
  { code: "BVI", name: "Birdsville Airport" },
  { code: "OCM", name: "Boolgeeda Airport" },
  { code: "BHQ", name: "Broken Hill Airport" },
  { code: "HTI", name: "Hamilton Island Great Barrier Reef Airport" },
  { code: "BEU", name: "Bedourie Airport" },
  { code: "BLN", name: "Benalla Airport" },
  { code: "BQB", name: "Busselton Regional Airport" },
  { code: "ISA", name: "Mount Isa Airport" },
  { code: "MKY", name: "Mackay Airport" },
  { code: "BNK", name: "Ballina Byron Gateway Airport" },
  { code: "BSJ", name: "Bairnsdale Airport" },
  { code: "BQL", name: "Boulia Airport" },
  { code: "PPP", name: "Proserpine Whitsunday Coast Airport" },
  { code: "ROK", name: "Rockhampton Airport" },
  { code: "BME", name: "Broome International Airport" },
  { code: "BYP", name: "Barimunya Airport" },
  { code: "MCY", name: "Sunshine Coast Airport" },
  { code: "BHS", name: "Bathurst Airport" },
  { code: "BRT", name: "Wurrumiyanga Bathurst Island Airport" },
  { code: "TSV", name: "Townsville Airport" },
  { code: "BDB", name: "Bundaberg Airport" },
  { code: "BUY", name: "Bunbury Airport" },
  { code: "WEI", name: "Weipa Airport" },
  { code: "WTB", name: "Toowoomba Brisbane West Wellcamp Airport" },
  { code: "BWB", name: "Barrow Island Airport" },
  { code: "CVQ", name: "Carnarvon Airport" },
  { code: "CPD", name: "Coober Pedy Airport" },
  { code: "CRB", name: "Collarenebri Airport" },
  { code: "CCL", name: "Chinchilla Airport" },
  { code: "CNC", name: "Coconut Island Airport" },
  { code: "CNJ", name: "Cloncurry Airport" },
  { code: "CUD", name: "Caloundra Airport" },
  { code: "CED", name: "Ceduna Airport" },
  { code: "CKW", name: "Christmas Creek Mine Airport" },
  { code: "DCN", name: "Curtin Airport" },
  { code: "CTN", name: "Cooktown Airport" },
  { code: "CMA", name: "Cunnamulla Airport" },
  { code: "NIF", name: "Nifty Airport" },
  { code: "CES", name: "Cessnock Airport" },
  { code: "OOM", name: "Cooma Snowy Mountains Airport" },
  { code: "CWW", name: "Corowa Airport" },
  { code: "CJF", name: "Coondewanna Airport" },
  { code: "DBY", name: "Dalby Airport" },
  { code: "DRB", name: "Derby Airport" },
  { code: "GTE", name: "Groote Eylandt Airport" },
  { code: "GOV", name: "Gove Airport" },
  { code: "DMD", name: "Doomadgee Airport" },
  { code: "DPO", name: "Devonport Airport" },
  { code: "ECH", name: "Echuca Airport" },
  { code: "ELC", name: "Elcho Island Airport" },
  { code: "EMD", name: "Emerald Airport" },
  { code: "EPR", name: "Esperance Airport" },
  { code: "KFE", name: "Cloudbreak Fortescue Dave Forrest Airport" },
  { code: "FLS", name: "Flinders Island Airport" },
  { code: "FIZ", name: "Fitzroy Crossing Airport" },
  { code: "GET", name: "Geraldton Airport" },
  { code: "GFN", name: "Grafton Clarence Valley Regional Airport" },
  { code: "GLT", name: "Gladstone Airport" },
  { code: "GFF", name: "Griffith Airport" },
  { code: "HVB", name: "Hervey Bay Airport" },
  { code: "HID", name: "Horn Island Airport" },
  { code: "HSM", name: "Horsham Airport" },
  { code: "IFL", name: "Innisfail Airport" },
  { code: "IVR", name: "Inverell Airport" },
  { code: "JCK", name: "Julia Creek Airport" },
  { code: "UBU", name: "Kalumburu Airport" },
  { code: "KAX", name: "Kalbarri Airport" },
  { code: "KNS", name: "King Island Airport" },
  { code: "KFG", name: "Kalkgurung Daguragu Airport" },
  { code: "KRB", name: "Karumba Airport" },
  { code: "KGC", name: "Kingscote Airport" },
  { code: "KUG", name: "Kubin Airport" },
  { code: "LNO", name: "Leonora Airport" },
  { code: "LDH", name: "Lord Howe Island Airport" },
  { code: "IRG", name: "Lockhart River Airport" },
  { code: "LSY", name: "Lismore Airport" },
  { code: "BBL", name: "Ballera Airport" },
  { code: "LRE", name: "Longreach Airport" },
  { code: "LER", name: "Leinster Airport" },
  { code: "LVO", name: "Laverton Airport" },
  { code: "TGN", name: "Morwell Latrobe Regional Airport" },
  { code: "AVV", name: "Melbourne Avalon Airport" },
  { code: "ABX", name: "Albury Airport" },
  { code: "MRG", name: "Mareeba Airport" },
  { code: "DGE", name: "Mudgee Airport" },
  { code: "MKR", name: "Meekathara Airport" },
  { code: "MEB", name: "Essendon Airport" },
  { code: "MIM", name: "Merimbula Airport" },
  { code: "MNG", name: "Maningrida Airport" },
  { code: "HBA", name: "Hobart International Airport" },
  { code: "MCV", name: "McArthur River Mine Airport" },
  { code: "MQL", name: "Mildura Airport" },
  { code: "LST", name: "Launceston Airport" },
  { code: "MBW", name: "Melbourne Moorabbin Airport" },
  { code: "MEL", name: "Melbourne Airport" },
  { code: "MMM", name: "Middlemount Airport" },
  { code: "WME", name: "Mount Keith Airport" },
  { code: "MMG", name: "Mount Magnet Airport" },
  { code: "MRZ", name: "Moree Airport" },
  { code: "MOV", name: "Moranbah Airport" },
  { code: "MYA", name: "Moruya Airport" },
  { code: "MGB", name: "Mount Gambier Airport" },
  { code: "ONG", name: "Mornington Island Airport" },
  { code: "MBH", name: "Maryborough Airport" },
  { code: "NRA", name: "Narrandera Airport" },
  { code: "NAA", name: "Narrabri Airport" },
  { code: "ABM", name: "Bamaga Northern Peninsula Airport" },
  { code: "RVT", name: "Ravensthorpe Airport" },
  { code: "NTN", name: "Normanton Airport" },
  { code: "ZNE", name: "Newman Airport" },
  { code: "OLP", name: "Olympic Dam Airport" },
  { code: "ONS", name: "Onslow Airport" },
  { code: "MOO", name: "Moomba Airport" },
  { code: "OAG", name: "Orange Airport" },
  { code: "ADL", name: "Adelaide Airport" },
  { code: "PUG", name: "Port Augusta Airport" },
  { code: "PMK", name: "Palm Island Airport" },
  { code: "PBO", name: "Paraburdoo Airport" },
  { code: "CCK", name: "Cocos Islands Airport" },
  { code: "DRW", name: "Darwin International Airport" },
  { code: "BEO", name: "Pelican Lake Macquarie Airport" },
  { code: "PPI", name: "Port Pirie Airport" },
  { code: "JAD", name: "Perth Jandakot Airport" },
  { code: "KTA", name: "Karratha Airport" },
  { code: "KGI", name: "Kalgoorlie Boulder Airport" },
  { code: "PKE", name: "Parkes Airport" },
  { code: "KNX", name: "Kununurra East Kimberley Regional Airport" },
  { code: "PLO", name: "Port Lincoln Airport" },
  { code: "LEA", name: "Learmonth Airport" },
  { code: "PXH", name: "Prominent Hill Airport" },
  { code: "PQQ", name: "Port Macquarie Airport" },
  { code: "PTJ", name: "Portland Airport" },
  { code: "PHE", name: "Port Hedland International Airport" },
  { code: "PER", name: "Perth Airport" },
  { code: "KTR", name: "Katherine Tindal Airport" },
  { code: "XCH", name: "Christmas Island Airport" },
  { code: "RMA", name: "Roma Airport" },
  { code: "RTS", name: "Rottnest Island Airport" },
  { code: "BWU", name: "Bankstown Airport" },
  { code: "CBR", name: "Canberra International Airport" },
  { code: "CFS", name: "Coffs Harbour Airport" },
  { code: "CDU", name: "Camden Airport" },
  { code: "DBO", name: "Dubbo City Airport" },
  { code: "SGO", name: "St George Airport" },
  { code: "MJK", name: "Monkey Mia Shark Bay Airport" },
  { code: "SNB", name: "Milikapiti Snake Bay Airport" },
  { code: "NLK", name: "Norfolk Island Airport" },
  { code: "SLJ", name: "Solomon Airport" },
  { code: "SHQ", name: "Southport Airport" },
  { code: "RCM", name: "Richmond Airport" },
  { code: "SYD", name: "Sydney Kingsford Smith Airport" },
  { code: "TMW", name: "Tamworth Airport" },
  { code: "WGA", name: "Wagga Wagga Airport" },
  { code: "SWH", name: "Swan Hill Airport" },
  { code: "XTO", name: "Taroom Airport" },
  { code: "TQP", name: "Trepell Airport" },
  { code: "TEF", name: "Telfer Airport" },
  { code: "GTS", name: "The Granites Airport" },
  { code: "PHQ", name: "Phosphate Hill The Monument Airport" },
  { code: "TCA", name: "Tennant Creek Airport" },
  { code: "TRO", name: "Taree Airport" },
  { code: "TTX", name: "Mungalalu Truscott Airport" },
  { code: "TWB", name: "Toowoomba Airport" },
  { code: "WMB", name: "Warrnambool Airport" },
  { code: "WRN", name: "Windarling Airport" },
  { code: "WNR", name: "Windorah Airport" },
  { code: "WGT", name: "Wangaratta Airport" },
  { code: "WYA", name: "Whyalla Airport" },
  { code: "NTL", name: "Newcastle Airport" },
  { code: "WUN", name: "Wiluna Airport" },
  { code: "WOL", name: "Wollongong Illawarra Regional Airport" },
  { code: "SXE", name: "West Sale Airport" },
  { code: "WIN", name: "Winton Airport" },
  { code: "WWI", name: "Woodie Woodie Airport" },
  { code: "WYN", name: "Wyndham Airport" },
  { code: "BWT", name: "Burnie Airport" },
  { code: "XMY", name: "Yam Island Airport" },
  { code: "PEK", name: "Beijing Capital International Airport" },
  { code: "CDE", name: "Chengde Puning Airport" },
  { code: "CIF", name: "Chifeng Yulong Airport" },
  { code: "CIH", name: "Changzhi Wangcun Airport" },
  { code: "BPE", name: "Qinhuangdao Beidaihe Airport" },
  { code: "DSN", name: "Dongsheng Ordos Ejin Horo Airport" },
  { code: "DAT", name: "Datong Yungang Airport" },
  { code: "ERL", name: "Erenhot Saiwusu International Airport" },
  { code: "YIE", name: "Arxan Yiershi Airport" },
  { code: "HDG", name: "Handan Airport" },
  { code: "HET", name: "Hohhot Baita International Airport" },
  { code: "HUO", name: "Holingol Huolinhe Airport" },
  { code: "HLD", name: "Hailar Hulunbuir Airport" },
  { code: "LFQ", name: "Linfen Qiaoli Airport" },
  { code: "LLV", name: "Lyuliang Airport" },
  { code: "NZH", name: "Manzhouli Xijiao Airport" },
  { code: "NAY", name: "Beijing Nanyuan Airport" },
  { code: "BAV", name: "Baotou Erliban Airport" },
  { code: "SHP", name: "Qinhuangdao Shanhaiguan Airport" },
  { code: "SJW", name: "Shijiazhuang Zhengding International Airport" },
  { code: "TSN", name: "Tianjin Binhai International Airport" },
  { code: "TGO", name: "Tongliao Airport" },
  { code: "TVS", name: "Tangshan Sannuhe Airport" },
  { code: "UCB", name: "Ulanqab Jining Airport" },
  { code: "WUA", name: "Wuhai Airport" },
  { code: "HLH", name: "Ulanhot Airport" },
  { code: "XIL", name: "Xilinhot Airport" },
  { code: "WUT", name: "Xinzhou Wutaishan Airport" },
  { code: "YCU", name: "Yuncheng Guangong Airport" },
  { code: "TYN", name: "Taiyuan Wusu International Airport" },
  { code: "RLK", name: "Bayannur Tianjitai Airport" },
  { code: "ZQZ", name: "Zhangjiakou Ningyuan Airport" },
  { code: "NZL", name: "Zhalantun Chengjisihan Airport" },
  { code: "BHY", name: "Beihai Fucheng Airport" },
  { code: "AEB", name: "Baise Bama Airport" },
  { code: "CGD", name: "Changde Taohuayuan Airport" },
  { code: "HJJ", name: "Huaihua Zhijiang Airport" },
  { code: "DYG", name: "Dayong Zhangjiajie Hehua Airport" },
  { code: "FUO", name: "Foshan Shadi Airport" },
  { code: "CAN", name: "Guangzhou Baiyun International Airport" },
  { code: "CSX", name: "Changsha Huanghua International Airport" },
  { code: "HNY", name: "Hengyang Nanyue Airport" },
  { code: "HUZ", name: "Huizhou Pingtan Airport" },
  { code: "KWL", name: "Guilin Liangjiang International Airport" },
  { code: "LLF", name: "Yongzhou Lingling Airport" },
  { code: "MXZ", name: "Meixian Airport" },
  { code: "NNG", name: "Nanning Wuxu International Airport" },
  { code: "SWA", name: "Jieyang Chaoshan International Airport" },
  { code: "ZUH", name: "Zhuhai Jinwan Airport" },
  { code: "WGN", name: "Shaoyang Wugang Airport" },
  { code: "SZX", name: "Shenzhen Bao'an International Airport" },
  { code: "WUZ", name: "Wuzhou Changzhoudao Airport" },
  { code: "LZH", name: "Liuzhou Bailian Airport" },
  { code: "ZHA", name: "Zhanjiang Airport" },
  { code: "CGO", name: "Zhengzhou Xinzheng International Airport" },
  { code: "ENH", name: "Enshi Xujiaping Airport" },
  { code: "WUH", name: "Wuhan Tianhe International Airport" },
  { code: "LYA", name: "Luoyang Beijiao Airport" },
  { code: "NNY", name: "Nanyang Jiangying Airport" },
  { code: "HPG", name: "Shennongjia Hongping Airport" },
  { code: "WDS", name: "Shiyan Wudangshan Airport" },
  { code: "XFN", name: "Xiangyang Liuji Airport" },
  { code: "XAI", name: "Xinyang Minggang Airport" },
  { code: "YIH", name: "Yichang Sanxia Airport" },
  { code: "HAK", name: "Haikou Meilan International Airport" },
  { code: "BAR", name: "Qionghai Bo'ao Airport" },
  { code: "SYX", name: "Sanya Phoenix International Airport" },
  { code: "FNJ", name: "Pyongyang Sunan International Airport" },
  { code: "DNH", name: "Dunhuang Airport" },
  { code: "HXD", name: "Delingha Airport" },
  { code: "GMQ", name: "Golog Maqin Airport" },
  { code: "GYU", name: "Guyuan Liupanshan Airport" },
  { code: "HZG", name: "Hanzhong Chenggu Airport" },
  { code: "INC", name: "Yinchuan Hedong International Airport" },
  { code: "JIC", name: "Jinchang Jinchuan Airport" },
  { code: "JNG", name: "Jining Qufu Airport" },
  { code: "JGN", name: "Jiayuguan Airport" },
  { code: "LHW", name: "Lanzhou Zhongchuan International Airport" },
  { code: "LNL", name: "Longnan Cheng Xian Airport" },
  { code: "IQN", name: "Qingyang Airport" },
  { code: "THQ", name: "Tianshui Maijishan Airport" },
  { code: "GXH", name: "Xiahe Gannan Airport" },
  { code: "XNN", name: "Xining Caojiabao Airport" },
  { code: "XIY", name: "Xi'an Xianyang International Airport" },
  { code: "ENY", name: "Yan'an Ershilipu Airport" },
  { code: "UYN", name: "Yulin Yuyang Airport" },
  { code: "YUS", name: "Yushu Batang Airport" },
  { code: "ZHY", name: "Zhongwei Airport" },
  { code: "YZY", name: "Zhangye Ganzhou Airport" },
  { code: "ULN", name: "Ulan Bator Chinggis Khaan International Airport" },
  { code: "BSD", name: "Baoshan Yunduan Airport" },
  { code: "CWJ", name: "Cangyuan Washan Airport" },
  { code: "DLU", name: "Dali Airport" },
  { code: "DIG", name: "Shangri-La Diqing Airport" },
  { code: "JHG", name: "Jinghong Xishuangbanna Gasa Airport" },
  { code: "JMJ", name: "Lancang Jingmai Airport" },
  { code: "LNJ", name: "Lincang Airport" },
  { code: "LJG", name: "Lijiang Sanyi Airport" },
  { code: "LUM", name: "Mangshi Airport" },
  { code: "KMG", name: "Kunming Changshui International Airport" },
  { code: "SYM", name: "Pu'er Simao Airport" },
  { code: "TCZ", name: "Tengchong Tuofeng Airport" },
  { code: "WNH", name: "Wenshan Puzhehei Airport" },
  { code: "ZAT", name: "Zhaotong Airport" },
  { code: "XMN", name: "Xiamen Gaoqi International Airport" },
  { code: "AQG", name: "Anqing Tianzhushan Airport" },
  { code: "CZX", name: "Changzhou Benniu Airport" },
  { code: "KHN", name: "Nanchang Changbei International Airport" },
  { code: "DOY", name: "Dongying Shengli Airport" },
  { code: "FUG", name: "Fuyang Xiguan Airport" },
  { code: "FOC", name: "Fuzhou Changle International Airport" },
  { code: "KOW", name: "Ganzhou Huangjin Airport" },
  { code: "HGH", name: "Hangzhou Xiaoshan International Airport" },
  { code: "JGS", name: "Ji'an Airport" },
  { code: "JDZ", name: "Jingdezhen Luojia Airport" },
  { code: "JUH", name: "Chizhou Jiuhuashan Airport" },
  { code: "JIU", name: "Jiujiang Lushan Airport" },
  { code: "TNA", name: "Jinan Yaoqiang International Airport" },
  { code: "JUZ", name: "Quzhou Airport" },
  { code: "LCX", name: "Longyan Guanzhishan Airport" },
  { code: "LYG", name: "Lianyungang Baitabu Airport" },
  { code: "HYN", name: "Huangyan Luqiao Airport" },
  { code: "LYI", name: "Linyi Shubuling Airport" },
  { code: "NGB", name: "Ningbo Lishe International Airport" },
  { code: "NKG", name: "Nanjing Lukou International Airport" },
  { code: "NTG", name: "Nantong Xingdong Airport" },
  { code: "HFE", name: "Hefei Xinqiao International Airport" },
  { code: "PVG", name: "Shanghai Pudong International Airport" },
  { code: "TAO", name: "Qingdao Liuting International Airport" },
  { code: "JJN", name: "Quanzhou Jinjiang Airport" },
  { code: "RIZ", name: "Rizhao Shanzihe Airport" },
  { code: "HIA", name: "Huai'an Lianshui Airport" },
  { code: "SQJ", name: "Sanming Shaxian Airport" },
  { code: "SQD", name: "Shangrao Sanqingshan Airport" },
  { code: "SHA", name: "Shanghai Hongqiao International Airport" },
  { code: "TXN", name: "Huangshan Tunxi International Airport" },
  { code: "WEF", name: "Weifang Airport" },
  { code: "WEH", name: "Weihai Dashuibo Airport" },
  { code: "WUX", name: "Wuxi Sunan Shuofang International Airport" },
  { code: "WUS", name: "Wuyishan Airport" },
  { code: "WNZ", name: "Wenzhou Longwan International Airport" },
  { code: "XUZ", name: "Xuzhou Guanyin Airport" },
  { code: "YTY", name: "Yangzhou Taizhou Airport" },
  { code: "YIC", name: "Yichun Mingyueshan Airport" },
  { code: "YNZ", name: "Yancheng Nanyang Airport" },
  { code: "YNT", name: "Yantai Penglai International Airport" },
  { code: "YIW", name: "Yiwu Airport" },
  { code: "HSN", name: "Zhoushan Putuoshan Airport" },
  { code: "AVA", name: "Anshun Huangguoshu Airport" },
  { code: "BPX", name: "Qamdo Bamda Airport" },
  { code: "BFJ", name: "Bijie Feixiong Airport" },
  { code: "CKG", name: "Chongqing Jiangbei International Airport" },
  { code: "DCY", name: "Daocheng Yading Airport" },
  { code: "DAX", name: "Dazhou Heshi Airport" },
  { code: "GYS", name: "Guangyuan Panlong Airport" },
  { code: "KWE", name: "Guiyang Longdongbao International Airport" },
  { code: "JZH", name: "Songpan Jiuzhai Huanglong Airport" },
  { code: "KGT", name: "Kangding Airport" },
  { code: "KJH", name: "Kaili Huangping Airport" },
  { code: "LLB", name: "Libo Airport" },
  { code: "LXA", name: "Lhasa Gonggar Airport" },
  { code: "LZO", name: "Luzhou Yunlong Airport" },
  { code: "WMT", name: "Zunyi Maotai Airport" },
  { code: "MIG", name: "Mianyang Nanjiao Airport" },
  { code: "NAO", name: "Nanchong Gaoping Airport" },
  { code: "HZH", name: "Liping Airport" },
  { code: "LZY", name: "Nyingchi Mainling Airport" },
  { code: "LPF", name: "Liupanshui Yuezhao Airport" },
  { code: "JIQ", name: "Qianjiang Wulingshan Airport" },
  { code: "RKZ", name: "Shigatse Peace Airport" },
  { code: "TEN", name: "Tongren Fenghuang Airport" },
  { code: "CTU", name: "Chengdu Shuangliu International Airport" },
  { code: "WXN", name: "Wanzhou Wuqiao Airport" },
  { code: "XIC", name: "Xichang Qingshan Airport" },
  { code: "YBP", name: "Yibin Caiba Airport" },
  { code: "ACX", name: "Xingyi Wanfenglin Airport" },
  { code: "PZI", name: "Panzhihua Bao'angong Airport" },
  { code: "ZYI", name: "Zunyi Xinzhou Airport" },
  { code: "AKU", name: "Aksu Airport" },
  { code: "AAT", name: "Altay Airport" },
  { code: "BPL", name: "Bole Alashankou Airport" },
  { code: "HMI", name: "Hami Airport" },
  { code: "SHF", name: "Shihezi Huayuan Airport" },
  { code: "KCA", name: "Kuqa Qiuci Airport" },
  { code: "KRL", name: "Korla Airport" },
  { code: "KRY", name: "Karamay Airport" },
  { code: "KJI", name: "Burqin Kanas Airport" },
  { code: "NLT", name: "Xinyuan Nalati Airport" },
  { code: "QSZ", name: "Shache Yeerqiang Airport" },
  { code: "KHG", name: "Kashgar Airport" },
  { code: "TCG", name: "Tacheng Airport" },
  { code: "HTN", name: "Hotan Airport" },
  { code: "TLQ", name: "Turpan Jiaohe Airport" },
  { code: "URC", name: "Urumqi Diwopu International Airport" },
  { code: "YIN", name: "Yining Airport" },
  { code: "AOG", name: "Anshan Teng'ao Airport" },
  { code: "NBS", name: "Baishan Changbaishan Airport" },
  { code: "CGQ", name: "Changchun Longjia International Airport" },
  { code: "CHG", name: "Chaoyang Airport" },
  { code: "DDG", name: "Dandong Langtou Airport" },
  { code: "DQA", name: "Daqing Sartu Airport" },
  { code: "FYJ", name: "Fuyuan Dongji Airport" },
  { code: "HRB", name: "Harbin Taiping International Airport" },
  { code: "HEK", name: "Heihe Airport" },
  { code: "JGD", name: "Jiagedaqi Airport" },
  { code: "JMU", name: "Jiamusi Dongjiao Airport" },
  { code: "JXA", name: "Jixi Airport" },
  { code: "JNZ", name: "Jinzhou Bay Airport" },
  { code: "LDS", name: "Yichun Lindu Airport" },
  { code: "MDG", name: "Mudanjiang Hailang Airport" },
  { code: "OHE", name: "Mohe Gulian Airport" },
  { code: "NDG", name: "Qiqihar Sanjiazi Airport" },
  { code: "DLC", name: "Dalian Zhoushuizi International Airport" },
  { code: "TNH", name: "Tonghua Sanyuanpu Airport" },
  { code: "SHE", name: "Shenyang Taoxian International Airport" },
  { code: "YNJ", name: "Yanji Chaoyangchuan Airport" },
  { code: "YKH", name: "Yingkou Lanqi Airport" }
];

export default airports;
